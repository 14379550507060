<template>
  <div class="fill-height">
    <v-toolbar
      :extension-height="70"
      :height="120"
      :style="{ zIndex: 1, left: 'var(--v-layout-left)', right: 0, width: 'auto' }"
      class="bc-outlined-gray bb-1"
      absolute
      flat
    >
      <v-row
        class="d-flex align-center"
        dense
      >
        <v-col
          class="d-flex align-center"
          cols="12"
          dense
        >
          <TitleBar
            :name="pageTitle || 'Providers'"
            class="mt-1 px-4"
            dense
          >
            <template #actions>
              <v-btn
                @click="$refs.download.open()"
                variant="outlined"
              >
                {{ t('Download') }}
              </v-btn>

              <Download
                ref="download"
                :lists="lists"
              />

              <template v-if="route.query.subsidy_id">
                <v-btn
                  :to="{ name: 'SubsidyShow', params: { id: route.query.subsidy_id } }"
                  class="ms-3"
                  color="primary"
                >
                  <v-icon class="me-2"> close </v-icon>
                  <span>Done</span>
                </v-btn>
              </template>

              <template v-else-if="route.query.group_id">
                <v-btn
                  :to="{ name: 'GroupShow', params: { groupId: route.query.group_id } }"
                  class="ms-3"
                  color="primary"
                >
                  <v-icon class="me-2"> close </v-icon>
                  <span>Done</span>
                </v-btn>
              </template>
            </template>
          </TitleBar>
        </v-col>

        <v-col
          class="mxw-1200 px-4"
          cols="12"
        >
          <v-row dense>
            <v-col
              v-if="schemas.length > 1"
              cols="3"
            >
              <v-select
                v-model="search.schema_id"
                @update:model-value="setSchema()"
                :aria-label="t(ariaLabel)"
                :items="schemas"
                density="compact"
                item-title="name"
                item-value="id"
                variant="filled"
                hide-details
                single-line
                tile
              >
                <template #prepend-inner>
                  <v-icon color="primary"> storefront </v-icon>
                </template>
              </v-select>
            </v-col>

            <v-col cols="5">
              <div class="d-flex">
                <v-text-field
                  :model-value="search.formatted_origin"
                  append-inner-icon="expand_more"
                  aria-label="Search by location"
                  class="grey-placeholder"
                  density="compact"
                  placeholder="Search by location"
                  variant="filled"
                  hide-details
                  readonly
                  single-line
                  tile
                >
                  <template #prepend-inner>
                    <v-icon color="primary"> location_on </v-icon>
                  </template>
                  <v-menu
                    v-model="locationMenu"
                    :close-on-content-click="false"
                    :offset="5"
                    activator="parent"
                  >
                    <LocationEditor
                      @update="handleAddressLocationChange"
                      :disable-mode="true"
                      :search="search"
                    />
                  </v-menu>
                </v-text-field>
              </div>
            </v-col>

            <v-col
              v-if="search"
              cols="2"
            >
              <v-text-field
                v-if="search.origin"
                :model-value="travelMode"
                append-inner-icon="expand_more"
                density="compact"
                variant="filled"
                hide-details
                readonly
                single-line
              >
                <template #prepend-inner>
                  <v-icon
                    class="me-1 pt-2px"
                    color="primary"
                  >
                    directions_car
                  </v-icon>
                </template>
                <v-menu
                  :offset="5"
                  activator="parent"
                >
                  <v-list
                    v-model:selected="search.travel_mode"
                    @update:selected="updateOrCreateSearch()"
                  >
                    <v-list-item
                      v-for="type in $a.assets.transit_options"
                      :key="type.value"
                      :title="t(type.text)"
                      :value="type.value"
                    />
                  </v-list>
                </v-menu>
              </v-text-field>
            </v-col>

            <v-col
              v-if="search.children && search.children.length > 0"
              cols="2"
            >
              <v-text-field
                :model-value="childrenValue"
                append-inner-icon="expand_more"
                density="compact"
                variant="filled"
                hide-details
                readonly
              >
                <template #prepend-inner>
                  <v-icon
                    class="me-1 pt-2px"
                    color="primary"
                  >
                    person_outline
                  </v-icon>
                </template>
                <v-menu
                  v-model="childrenMenu"
                  :close-on-content-click="false"
                  :offset="5"
                  activator="parent"
                  max-width="600"
                >
                  <v-card
                    border
                    tile
                  >
                    <v-card-text class="pt-2 pb-4">
                      <v-checkbox
                        v-model="search.include_all_children"
                        :label="t('Only show me results that serve all ages selected')"
                        hide-details
                      />
                      <v-divider class="my-4" />
                      <SearchChildren :search="search" />
                      <v-btn
                        @click="childrenMenu = false"
                        color="primary"
                      >
                        Apply
                      </v-btn>
                    </v-card-text>
                  </v-card>
                </v-menu>
              </v-text-field>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <template #extension>
        <v-row
          class="c-black px-4"
          dense
          justify-space-between
        >
          <v-col class="d-flex align-center">
            <FilterMenu
              v-if="!route.query.subsidy_id"
              :title="groupName"
              tid="specialist_search_group_filter"
            >
              <template #card>
                <GroupFilter
                  v-model="group"
                  @change="changeGroup"
                  :disable-mode="true"
                />
              </template>
            </FilterMenu>

            <FilterBar
              v-model="search"
              @change="updateOrCreateSearch()"
              :group="group"
            />

            <FilterMenu
              v-if="lists.length > 0"
              tid="specialist_search_favorite_filter"
              title="Referrals"
            >
              <template #icon-prefix>
                <v-icon class="fs-22 me-2 c-secondary">
                  {{ $store.state.pages.Search['Favorite icon'] || 'check_circle' }}
                </v-icon>
              </template>
              <template #card>
                <div
                  v-for="list in lists"
                  :key="list.id"
                  class="pa-3 hover-bg-secondary-lite"
                >
                  <v-checkbox
                    v-model="listFilter"
                    @update:model-value="updateOrCreateSearch()"
                    :label="list.name"
                    :value="list"
                    class="mt-0"
                    color="secondary"
                    hide-details
                  />
                </div>
              </template>
            </FilterMenu>
          </v-col>
        </v-row>
      </template>
    </v-toolbar>

    <SearchResults
      @change:page="page = $event"
      @favorite:add="createFavorite($event[0], $event[1])"
      @favorite:list="createListAndFavorite($event[0], $event[1])"
      @favorite:remove="removeFavorite($event)"
      @redo="updateOrCreateSearch"
      ref="results"
      :default-center="default_center"
      :favorites="favorites"
      :lists="lists"
      :matches="matches"
      :page="page"
      :pages="pages"
      :processing="processing"
      :search="search"
      :top="130"
    />
  </div>
</template>
<script setup>
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import Api from '@/specialist/services/bright_finder';
import Download from '@/shared/components/search/download.vue';
import FilterBar from '@/shared/components/search/FilterBar.vue';
import FilterMenu from '@/shared/components/form/FilterMenu.vue';
import GroupFilter from '@/specialist/components/GroupFilter.vue';
import SearchChildren from '@/specialist/components/search/children.vue';
import LocationEditor from '@/shared/components/search/LocationEditor.vue';
import SearchResults from '@/shared/components/search/SearchResults.vue';
import { Coord } from '@/shared/services/coord';
import { SearchParams } from '@/shared/services/search_params';
import SearchFromGroupParams from '@/specialist/services/search_from_group_params';
import SearchFromSubsidyParams from '@/specialist/services/search_from_subsidy_params';
import useTerms from '@/shared/composables/useTerms';
import useEventBus from '@/shared/composables/useEventBus';
import { useI18n } from 'vue-i18n';

const eventBus = useEventBus();
const store = useStore();
const router = useRouter();
const route = useRoute();
const terms = useTerms();
const { t } = useI18n();

const defaultSearch = () => {
  return {
    categories: [],
    county: [],
    exclude_closed: store.state.pages.Search.features.enable_closed_filter,
    custom: {},
    highlights: {},
    license_type: [],
    location_type: 'home',
    minimum_quality_rating: null,
    origin: null,
    payment_subsidies_accepted: [],
    payment_other_programs: [],
    program_season: null,
    program_types: [],
    schema_id: Object.values(store.state.schemas).find((schema) => schema.data_type === 'Provider')
      .id,
    travel_mode: 'DRIVING',
    week_days: [],
    vacancy: null,
  };
};

const results = ref(null);
const backRoute = ref(null);
const childrenMenu = ref(false);
const defaultCareNeededDate = ref(new Date().toISOString().split('T')[0]);
const default_center = ref(
  new Coord(store.state.profile.default_search_location || { lat: 37.0902, lng: -95.7129 }),
);
const favorites = ref([]);
const group = ref(null);
const lists = ref([]);
const listFilter = ref(null);
const loaded = ref(false);
const locationMenu = ref(false);
const matches = ref([]);
const moreFiltersDialogIsVisible = ref(false);
const page = ref(1);
const pages = ref(null);
const pageTitle = ref(null);
const processing = ref(false);
const query = ref(null);
const schemas = ref(
  Object.values(store.state.schemas).filter((schema) => schema.data_type === 'Provider'),
);
const search = ref(defaultSearch());
const selectedProvider = ref(null);
const searchedProviders = ref([]);
const subsidy = ref(null);

const ariaLabel = computed(() => {
  const selectedSchema = schemas.value.find((schema) => schema.id === search.value.schema_id);
  if (selectedSchema) return `Select ${selectedSchema.name}`;
  return 'Please create a schema';
});

const childrenValue = computed(() => {
  const sum = search.value.children.length;
  if (sum === 1) {
    return ['1', 'child'].join(' ');
  }
  return [sum, 'children'].join(' ');
});

const groupName = computed(() => {
  if (group.value?.name) {
    return group.value.name;
  }
  return terms.group;
});

const travelMode = computed(() => {
  if (search.value && search.value.travel_mode) {
    return (
      search.value.travel_mode.charAt(0).toUpperCase() +
      search.value.travel_mode.slice(1).toLowerCase()
    );
  }
  return 'Driving';
});

const validSearch = computed(() => {
  return !(search.value.origin == null);
});

watch(childrenMenu, (newVal) => {
  if (!newVal) {
    updateOrCreateSearch();
  }
});

watch(locationMenu, (newVal) => {
  if (!newVal) {
    updateOrCreateSearch();
  }
});

watch(page, () => {
  refreshSearchResults();
});

watch(
  () => route.query,
  () => {
    load();
  },
);

watch(selectedProvider, (val) => {
  if (val) {
    router.push({ name: 'ProviderShow', params: { providerId: val.id } });
    query.value = null;
    searchedProviders.value = [];
    selectedProvider.value = null;
  }
});

watch(query, (val) => {
  if (val) searchProviders();
});

const applyCareNeededDateToChildren = (children) => {
  children.forEach((child) => (child.care_needed_date = defaultCareNeededDate.value));
};

const createSearch = () => {
  processing.value = true;
  matches.value = [];
  pages.value = null;

  const params = new SearchParams(search.value).asJson();
  Api.organization.search.create(params, (resp) => {
    refreshSearchResults(resp.data);
  });
};

const createGroupSearch = (groupId) => {
  processing.value = true;
  matches.value = [];
  pages.value = null;
  loadGroup(groupId, () => {
    const params = new SearchFromGroupParams(group.value, search.value.schema_id).asJson();
    applyCareNeededDateToChildren(params.children);

    Api.organization.search.create(
      {
        ...params,
        subsidy_program_id: subsidy.value?.id,
      },
      (searchResp) => {
        refreshSearchResults(searchResp.data);
      },
      (err) => {
        eventBus.chime(err.response.data.errors[0]);
      },
    );
  });
};

const createSubsidySearch = async (subsidyId) => {
  processing.value = true;
  matches.value = [];
  pages.value = null;
  await loadSubsidy(subsidyId);

  pageTitle.value = [subsidy.value.meta.child.first_name, subsidy.value.meta.child.last_name].join(
    ' ',
  );
  backRoute.value = { name: 'SubsidyShow', params: { id: subsidy.value.id } };
  await loadSubsidyProgram(subsidy.value.subsidy_program_id);

  const params = new SearchFromSubsidyParams(
    subsidy.value,
    subsidy.value.meta.child,
    subsidy.value.meta.group,
    search.value.schema_id,
  ).asJson();
  applyCareNeededDateToChildren(params.children);

  Api.organization.search.create(
    params,
    (searchResp) => {
      refreshSearchResults(searchResp.data);
    },
    (err) => {
      eventBus.chime(err.response.data.errors[0]);
    },
  );
};

const createFavorite = (listId, providerId) => {
  Api.organization.favorite.create(
    { group_id: route.query.group_id, list_id: listId, provider_id: providerId },
    () => {
      loadFavorites();
    },
  );
};

const createListAndFavorite = (listName, providerId) => {
  Api.organization.list.create({ group_id: route.query.group_id, name: listName }, (resp) => {
    createFavorite(resp.data.id, providerId);
  });
};

const handleAddressLocationChange = (newVal) => {
  search.value.origin = newVal.origin;
  search.value.formatted_origin = newVal.formatted_origin;
  search.value.location_type = newVal.location_type;
  search.value.zip = newVal.zip;
  search.value.destination = newVal.destination;
  search.value.formatted_destination = newVal.formatted_destination;
  locationMenu.value = false;
  updateOrCreateSearch();
};

const removeFavorite = (favoriteId) => {
  Api.organization.favorite.destroy(favoriteId, () => {
    loadFavorites();
  });
};

const changeGroup = (newVal) => {
  group.value = newVal;
  if (group.value && group.value.id) {
    router.push({ name: 'ProviderSearch', query: { group_id: group.value.id } });
  } else {
    router.push({ name: 'ProviderSearch' });
  }
};

const load = () => {
  pageTitle.value = null;
  backRoute.value = null;
  if (route.query.subsidy_id) {
    createSubsidySearch(route.query.subsidy_id);
  } else if (route.query.group_id) {
    createGroupSearch(route.query.group_id);
  } else {
    loadDefault();
  }

  if (route.query.group_id) {
    loadFavorites();
  }
};

const loadDefault = () => {
  favorites.value = [];
  group.value = null;
  lists.value = [];
  search.value = defaultSearch();
  matches.value = [];
  pages.value = null;
  loaded.value = true;
};

const loadFavorites = () => {
  Api.organization.favorite.index({ group_id: route.query.group_id }, (resp) => {
    favorites.value = resp.data;
  });

  Api.organization.list.index({ group_id: route.query.group_id }, (resp) => {
    lists.value = resp.data;
  });
};

const loadGroup = async (id, callback) => {
  Api.organization.group.get(id, async (groupResp) => {
    group.value = groupResp.data;

    const childResp = await Api.organization.group.child.index(group.value.id);
    group.value.children = childResp.data;
    loadFavorites();
    callback();
  });
};

const loadSubsidy = async (id) => {
  const response = await Api.organization.subsidy.get(id);
  subsidy.value = response.data;
  group.value = subsidy.value.meta.group;
};

const loadSubsidyProgram = async (id) => {
  const response = await Api.organization.subsidy_program.get(id);
  const enrollmentStartDate = response.data?.search_for_care_as_of_date;
  if (!enrollmentStartDate) return;

  defaultCareNeededDate.value = enrollmentStartDate;
};

const searchProviders = () => {
  Api.public_api.provider.index(
    {
      organization_id: window.organization_id,
      query: query.value,
      schema_id: search.value.schema_id,
      subsidy_program_id: subsidy.value?.id,
    },
    (resp) => {
      searchedProviders.value = resp.data;
    },
  );
};

const setSchema = () => {
  search.value.categories = [];
  updateOrCreateSearch();
};

const refreshSearchResults = (searchData) => {
  if (searchData) search.value = searchData;

  matches.value = [];
  pages.value = null;
  processing.value = true;
  const filters = { page: page.value, subsidy_program_id: subsidy.value?.subsidy_program_id };

  if (listFilter.value) {
    filters.list_id = listFilter.value.id;
  }
  Api.organization.search.match.index(search.value.id, filters, (resp) => {
    pages.value = parseInt(resp.headers['x-page-count'] || 0, 10);
    loaded.value = true;
    matches.value = resp.data;
    processing.value = false;
    results.value.changeMode('list');
  });
};

const updateOrCreateSearch = (bounds) => {
  page.value = 1;
  if (bounds) {
    search.value.sw_lat = bounds[0].lat();
    search.value.sw_lng = bounds[0].lng();
    search.value.ne_lat = bounds[1].lat();
    search.value.ne_lng = bounds[1].lng();
  } else {
    search.value.sw_lat = null;
    search.value.sw_lng = null;
    search.value.ne_lat = null;
    search.value.ne_lng = null;
  }
  if (validSearch.value) {
    moreFiltersDialogIsVisible.value = false;
    if (search.value.id) {
      const params = new SearchParams(search.value).asJson();
      Api.organization.search.update(
        search.value.id,
        params,
        (resp) => {
          refreshSearchResults(resp.data);
        },
        (err) => {
          eventBus.chime(err.response.data.errors[0]);
        },
      );
    } else if (group.value?.id) {
      createGroupSearch(group.value.id);
    } else {
      createSearch();
    }
  }
};

onMounted(() => {
  load();
});
</script>

<style>
.b-0 fieldset,
.b-0.v-text-field > .v-input__control > .v-input__slot:before {
  border: none !important;
}

.mxw-500 {
  max-width: 500px;
}
</style>

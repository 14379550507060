<template>
  <v-container
    v-if="collection"
    class="px-0 py-0 bg-super-light-blue"
    fluid
  >
    <template v-if="collection.length > 0">
      <div class="mt-3 mx-3">
        <v-card
          class="bt-1 br-1 bl-1 bc-outlined-gray"
          flat
          tile
        >
          <v-card-text class="fs-15 py-3">
            <v-row dense>
              <v-col class="d-flex align-center">
                <p class="mb-0 py-1">
                  {{ $t('Displaying') }} <strong>{{ collection.length }}</strong>
                  {{ $t('results') }}, {{ $t('out of') }} <strong>{{ totalItemCount }}</strong>
                  {{ $t('total results') }}.
                </p>
              </v-col>

              <v-btn
                @click="$refs.newCourseDialog.open()"
                color="primary"
                prepend-icon="add"
              >
                {{ $t('New course') }}
              </v-btn>
            </v-row>
          </v-card-text>
        </v-card>
      </div>

      <v-data-table-server
        @click:row="view"
        @update:page="handlePageChange"
        :headers="tableHeaders"
        :hide-default-footer="!totalItemCount || pages === 0"
        :items="collection"
        :items-length="totalItemCount"
        :items-per-page="pageSize"
        :page="filters.page"
        class="mx-3 b-radius-0 b-1 bc-outlined-gray fixed-height"
        item-key="id"
        disable-sort
        fixed-header
        hover
      >
        <template #item.published="{ item }">
          <v-chip
            :color="item.published ? 'light-green-lighten-3' : 'light-grey-lighten-3'"
            size="small"
          >
            {{ $t(item.published ? 'Published' : 'Draft') }}
          </v-chip>
        </template>

        <template #item.access="{ item }">
          <v-chip
            :color="item.restricted ? 'amber-lighten-3' : 'primary-lighten-3'"
            size="small"
          >
            {{ $t(item.restricted ? 'Cohorts' : 'Public') }}
          </v-chip>
        </template>

        <template #item.actions="{ item }">
          <div class="d-flex justify-end align-center">
            <ActionMenu
              @click:action:delete="destroy(item)"
              @click:action:view="view(null, { item })"
              :items="courseActionItems"
              button-icon="more_vert"
            />
          </div>
        </template>
      </v-data-table-server>
    </template>

    <NullState
      v-if="collection.length === 0"
      @new="$refs.newCourseDialog.open({})"
      new-button-title="New course"
    />

    <ResourceDialog
      @save="createNewCourse"
      ref="newCourseDialog"
      :fields="[{ text: 'Name', value: 'name' }]"
      :processing="processing"
      save-button-text="Create"
      title="Create new course"
    />
  </v-container>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import NullState from '@/shared/components/NullState.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import useRouterHelper from '@/shared/composables/useRouterHelper';
import { onMounted } from 'vue';
import useEventBus from '@/shared/composables/useEventBus';
import { useRouter } from 'vue-router';

const DEFAULT_PAGE_SIZE = 25;
const eventBus = useEventBus();
const router = useRouter();
const { getFiltersFromQuery, updateQuery } = useRouterHelper();

const collection = ref(null);

const courseActionItems = ref([
  { title: 'View', event: 'view' },
  { title: 'Delete', event: 'delete' },
]);

const filters = ref(getFiltersFromQuery({ paged: true }));
const pages = ref(1);
const pageSize = ref(DEFAULT_PAGE_SIZE);
const processing = ref(false);
const tableHeaders = ref([
  {
    title: 'Name',
    value: 'name',
  },
  {
    title: 'Status',
    value: 'published',
    align: 'center',
  },
  {
    title: 'Access',
    value: 'access',
    align: 'center',
  },
  {
    title: 'Author',
    value: 'creator_name',
  },
  {
    title: 'Credits',
    value: 'credits',
    align: 'center',
  },
  {
    title: null,
    value: 'actions',
  },
]);

const totalItemCount = ref(null);

onMounted(() => load());

async function createNewCourse(newCourse) {
  processing.value = true;
  const params = {
    name: newCourse.name,
  };
  const response = await Api.organization.course.create(params).catch((error) => {
    processing.value = false;
    eventBus.chime(error.response.data.errors[0]);
  });

  router.push({
    name: 'CourseShow',
    params: {
      courseId: response.data.id,
    },
  });
}

async function handlePageChange(newValue) {
  if (newValue) filters.value.page = newValue;
  await updateQuery({ ...filters.value });
  void load();
}

function view(event, { item: course }) {
  router.push({
    name: 'CourseShow',
    params: {
      courseId: course.id,
    },
  });
}

async function destroy(course) {
  // eslint-disable-next-line no-alert
  if (confirm('Are you sure you want to delete this course? This cannot be undone.')) {
    await Api.organization.course
      .destroy(course.id)
      .catch((error) => eventBus.error(error.response.data.errors[0]));

    eventBus.chime('Saved');
    void load();
  }
}

async function load() {
  processing.value = true;

  const response = await Api.organization.course.index(filters.value);

  pages.value = parseInt(response.headers['x-page-count'] || 0, 10);
  totalItemCount.value = parseInt(response.headers['x-total-count'] || 0, 10);
  pageSize.value = parseInt(response.headers['x-page-size'] || 0, 10);
  collection.value = response.data;
  processing.value = false;
}
</script>

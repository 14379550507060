<template>
  <v-card
    class="mt-4"
    border
    flat
    tile
  >
    <v-card-title class="fs-22 fw-600">
      <v-row
        class="d-flex align-center"
        dense
      >
        <v-col
          cols="6"
          role="heading"
        >
          {{ $t('Related records') }}
        </v-col>

        <v-col class="d-flex justify-end">
          <v-btn
            @click="handleLinkRecord"
            color="primary"
            prepend-icon="link"
            variant="outlined"
          >
            {{ $t('Link record') }}
          </v-btn>

          <v-btn
            @click="handleNewRecord"
            class="ms-3"
            color="primary"
            prepend-icon="add"
          >
            {{ $t('New record') }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>

    <v-divider />

    <v-data-table-server
      @update:page="handlePageChange"
      :headers="tableHeaders"
      :hide-default-footer="!totalItemCount"
      :items="linkedRecords"
      :items-length="totalItemCount"
      :items-per-page="pageSize"
      :loading="processing"
      :no-data-text="$t('No related records found')"
      fixed-header
      hover
    >
      <template #item.id="{ item }">
        <router-link
          :to="getLinkForRecord(item)"
          class="text-decoration-none"
          target="_blank"
        >
          {{ item.id.substring(0, 8) }}
        </router-link>
      </template>

      <template #item.type="{ item }">
        {{ $t(toSentenceCase(item.type)) }}
      </template>

      <template #item.created_at="{ item }">
        <LongDateTime :date="item.created_at" />
      </template>
    </v-data-table-server>

    <LinkExistingRecordDialog
      @change="loadLinkedRecords"
      ref="linkExistingRecordDialog"
      :case-id="props.caseId"
      :case-type="props.caseType"
      :linked-records="linkedRecords"
      :schema="props.schema"
    />

    <LinkNewRecordDialog
      @change="loadLinkedRecords"
      ref="linkNewRecordDialog"
      :case-id="props.caseId"
      :case-type="props.caseType"
      :linked-records="linkedRecords"
      :schema="props.schema"
    />
  </v-card>
</template>

<script setup>
import api from '@/specialist/services/bright_finder';
import LongDateTime from '@/shared/components/LongDateTime.vue';
import LinkExistingRecordDialog from '@/specialist/components/LinkExistingRecordDialog.vue';
import LinkNewRecordDialog from '@/specialist/components/LinkNewRecordDialog.vue';
import { toSentenceCase } from '@/shared/services/string-utils';

const props = defineProps({
  caseId: {
    type: String,
    required: true,
  },
  caseType: {
    type: String,
    required: true,
  },
  schema: {
    type: Object,
    required: true,
  },
});

const tableHeaders = ref([
  {
    title: 'ID',
    value: 'id',
  },
  {
    title: 'Type',
    value: 'type',
  },
  {
    title: 'Name',
    value: 'name',
  },
  {
    title: 'Created',
    value: 'created_at',
  },
  {
    title: 'Status',
    value: 'status',
  },
]);

const linkedRecords = ref([]);
const linkExistingRecordDialog = ref(null);
const linkNewRecordDialog = ref(null);
const totalItemCount = ref(null);
const pageSize = ref(10);
const processing = ref(false);

async function loadLinkedRecords() {
  processing.value = true;
  const {
    data: { children = [], parents = [] },
  } = await api.organization.linkedRecord.index(props.caseId, props.caseType);
  linkedRecords.value = [...children, ...parents].sort(
    (a, b) => new Date(b.created_at) - new Date(a.created_at),
  );
  processing.value = false;
}

function handleNewRecord() {
  linkNewRecordDialog.value.open();
}

function handleLinkRecord() {
  linkExistingRecordDialog.value.open();
}

function getLinkForRecord(record) {
  if (record.type === 'Assessment') {
    return { name: 'AssessmentShow', params: { assessmentId: record.id } };
  }
  if (record.type === 'BackgroundCheck') {
    return { name: 'BackgroundCheckShow', params: { id: record.id } };
  }
  if (record.type === 'Complaint') {
    return { name: 'ComplaintShow', params: { id: record.id } };
  }
  if (record.type === 'License') {
    return { name: 'LicenseShow', params: { id: record.id } };
  }
  if (record.type === 'Form') {
    return { name: 'FormReview', params: { formId: record.id } };
  }
  if (record.type === 'Provider') {
    return { name: 'ProviderShow', params: { providerId: record.id } };
  }
  return null;
}

function handlePageChange() {}

onMounted(async () => {
  await loadLinkedRecords();
});
</script>

<template>
  <v-dialog
    v-model="visible"
    max-width="500"
    scrim="transparent"
    scrollable
  >
    <v-card
      v-if="owner"
      border
      flat
      tile
    >
      <v-card-title class="d-flex">
        <span>{{ $t('Add/remove labels') }}</span>
        <v-spacer />
        <v-btn
          @click="close()"
          :aria-label="$t('Close')"
          icon="close"
          variant="text"
        />
      </v-card-title>
      <v-divider class="mb-2" />
      <v-card-text>
        <v-text-field
          v-if="displaySearch"
          v-model="labelSearchVal"
          id="label_search"
          :aria-label="$t('Filter labels by name')"
          :placeholder="$t('Filter labels by name')"
          color="primary"
          density="compact"
          prepend-inner-icon="search"
          variant="filled"
          hide-details
          tracked
        />

        <v-list
          v-model:selected="owner.relationships.labels.data"
          density="compact"
          select-strategy="independent"
        >
          <v-list-item
            v-for="label in filteredAppliedLabels"
            @click="toggleLabel(label)"
            :key="['applied', label.id].join('-')"
            :title="$t(label.name)"
            :value="label"
          >
            <template #prepend="{ isSelected }">
              <v-checkbox-btn
                :model-value="isSelected"
                :ripple="false"
                density="compact"
                tabindex="-1"
              />
            </template>
            <template #append>
              <v-avatar
                :color="label.color || 'grey'"
                size="20"
                dense
              />
            </template>
          </v-list-item>

          <v-divider
            v-if="filteredAppliedLabels?.length > 0 && filteredAvailableProgramLabels?.length > 0"
          />

          <v-list-item
            v-for="label in filteredAvailableProgramLabels"
            @click="toggleLabel(label)"
            :key="['avail', label.id].join('-')"
            :title="$t(label.name)"
            :value="label"
          >
            <template #prepend="{ isSelected }">
              <v-checkbox-btn
                :model-value="isSelected"
                :ripple="false"
                density="compact"
                tabindex="-1"
              />
            </template>
            <template #append>
              <v-avatar
                :color="label.color || 'grey'"
                size="20"
                dense
              />
            </template>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-divider class="my-2" />
      <v-card-actions class="bg-white">
        <v-spacer />
        <v-btn
          @click="close()"
          :disabled="processing"
          color="primary"
        >
          Close
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup>
import Api from '@/shared/services/all_bright_finder';

const LABEL_SEARCH_DISPLAY_COUNT_MIN = 10;

const props = defineProps({
  labelOwnerType: {
    type: String,
    default: null,
  },
  programLabels: {
    type: Array,
    default: null,
  },
});

const emit = defineEmits(['change', 'close']);

const labelSearchVal = ref(null);
const processing = ref(false);
const owner = ref(null);
const ownerInfo = ref(null);
const visible = ref(false);

const displaySearch = computed(() => {
  return props.programLabels.length > LABEL_SEARCH_DISPLAY_COUNT_MIN;
});

const filteredAppliedLabels = computed(() => {
  const appliedLabels = owner.value.relationships.labels.data;

  if (!displaySearch.value || !labelSearchVal.value) return appliedLabels;
  return appliedLabels.filter((label) =>
    label.name.toLowerCase().includes(labelSearchVal.value.toLowerCase()),
  );
});

const filteredAvailableProgramLabels = computed(() => {
  const appliedLabelIds = filteredAppliedLabels.value.map((label) => label.id);
  if (!displaySearch.value || !labelSearchVal.value) {
    return props.programLabels.filter((label) => !appliedLabelIds.includes(label.id));
  }

  return props.programLabels.filter(
    (label) =>
      !appliedLabelIds.includes(label.id) &&
      label.name.toLowerCase().includes(labelSearchVal.value.toLowerCase()),
  );
});

async function open(providedOwner) {
  setOwnerInfo();
  const resp = await ownerInfo.value.repository.get(providedOwner.id);
  emit('change', resp.data);
  owner.value = resp.data;
  visible.value = true;
}

function close() {
  emit('close');
  labelSearchVal.value = null;
  owner.value = null;
  visible.value = false;
}

function setOwnerInfo() {
  // eslint-disable-next-line default-case
  switch (props.labelOwnerType) {
    case 'Course':
      ownerInfo.value = {
        assignmentField: 'course_id',
        repository: Api.organization.course,
      };
      break;
    case 'Form':
      ownerInfo.value = {
        assignmentField: 'form_id',
        repository: Api.organization.form,
      };
      break;
    case 'Grant':
      ownerInfo.value = {
        assignmentField: 'grant_id',
        repository: Api.organization.grant,
      };
      break;
    case 'Subsidy':
      ownerInfo.value = {
        assignmentField: 'subsidy_id',
        repository: Api.organization.subsidy,
      };
      break;
    case 'FamilySubsidy':
      ownerInfo.value = {
        assignmentField: 'family_subsidy_id',
        repository: Api.organization.family_subsidy,
      };
      break;
  }
}

async function toggleLabel(label) {
  processing.value = true;
  await nextTick();
  const adding = owner.value.relationships.labels.data.includes(label);

  if (adding) {
    await Api.organization.label_assignment.create({
      label_id: label.id,
      [ownerInfo.value.assignmentField]: owner.value.id,
    });
  } else {
    // TO-DO : Determine if race condition of deleting an assignment that has already been deleted is
    //         acceptable (currently reloads view for user - somewhat disruptive)
    await Api.organization.label_assignment.destroy({
      [ownerInfo.value.assignmentField]: owner.value.id,
      label_id: label.id,
    });
  }

  const resp = await ownerInfo.value.repository.get(owner.value.id);

  owner.value.relationships.labels.data = resp.data.relationships.labels.data;
  emit('change', resp.data);
  processing.value = false;
}

defineExpose({
  open,
});
</script>

<template>
  <v-container
    class="px-0 py-0 bg-super-light-blue"
    fluid
  >
    <PageTitle
      @create="$refs.newTaskTypeDialog.open({})"
      create-text="New task type"
      title="Task types"
      createable
    />

    <v-toolbar class="mb-3 px-3 bb-1">
      <v-row>
        <v-col class="mxw-340">
          <v-text-field
            v-model="filters.query"
            @update:model-value="load()"
            :aria-label="$t('Filter by name')"
            :placeholder="$t('Filter by name')"
            color="secondary"
            data-testid="member.search"
            density="compact"
            prepend-inner-icon="search"
            variant="filled"
            hide-details
            tracked
          />
        </v-col>
      </v-row>
    </v-toolbar>

    <v-data-table-server
      @click:row="handleRowClick"
      :headers="tableHeaders"
      :items="filteredItems"
      :loading="processing"
      class="mt-3 mx-3 b-radius-0 b-1 bc-outlined-gray"
      no-data-text="No task types found"
      fixed-header
      hide-default-footer
      hover
    >
      <template #item.created="{ item }">
        <LongDateTime :date="item.created_at" />
      </template>

      <template #item.actions="{ item }">
        <div class="d-flex justify-end align-center">
          <ActionMenu
            @click:action:delete="destroy(item)"
            :items="[{ title: 'Delete', event: 'delete' }]"
            button-icon="more_vert"
          />
        </div>
      </template>
    </v-data-table-server>

    <VerticalSpacer :min-height="14" />

    <ResourceDialog
      @save="create"
      ref="newTaskTypeDialog"
      :fields="[{ text: 'Name', value: 'name', required: true }]"
      :processing="processing"
      title="Create new task type"
      close-on-save
    />

    <ResourceDialog
      @save="update"
      ref="updateTaskTypeDialog"
      :fields="[{ text: 'Name', value: 'name', required: true }]"
      :processing="processing"
      title="Update task type"
      close-on-save
    />
  </v-container>
</template>

<script setup>
import _ from 'lodash';
import Api from '@/shared/services/all_bright_finder';
import LongDateTime from '@/shared/components/LongDateTime.vue';
import PageTitle from '@/shared/components/PageTitle.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import useEventBus from '@/shared/composables/useEventBus';
import VerticalSpacer from '@/shared/components/VerticalSpacer.vue';

const eventBus = useEventBus();

const filters = ref({
  query: null,
});
const processing = ref(false);
const items = ref([]);
const tableHeaders = ref([
  {
    title: 'Name',
    value: 'name',
  },
  {
    title: 'Created',
    value: 'created',
  },
  {
    title: '',
    value: 'actions',
  },
]);
const updateTaskTypeDialog = ref(null);

// Basic search functionality, FE only
const filteredItems = computed(() => {
  if (!filters.value.query) return items.value;

  return items.value.filter((item) => {
    return item.name.toLowerCase().includes(filters.value.query.toLowerCase());
  });
});

function handleRowClick(event, { item }) {
  updateTaskTypeDialog.value.open(item);
}

async function load() {
  processing.value = true;

  const response = await Api.organization.taskType.index();
  items.value = _.orderBy(response?.data, 'created_at', 'desc');

  processing.value = false;
}

async function create(taskType) {
  processing.value = true;

  const response = await Api.organization.taskType.create(taskType);
  if (response?.status === 201) {
    eventBus.chime('Created');
    await load();
  }

  processing.value = false;
}

async function destroy(taskType) {
  // eslint-disable-next-line no-alert
  if (!confirm('Are you sure you want to delete this task type?')) return;

  processing.value = true;

  const response = await Api.organization.taskType.destroy(taskType.id);
  if (response?.status === 200) {
    eventBus.chime('Deleted');
    await load();
  }

  processing.value = false;
}

async function update(taskType) {
  processing.value = true;

  const response = await Api.organization.taskType.update(taskType.id, { name: taskType.name });
  if (response?.status === 200) {
    eventBus.chime('Updated');
    await load();
  }

  processing.value = false;
}

onMounted(load);
</script>

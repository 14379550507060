<template>
  <div>
    <v-card
      v-if="items"
      class="bt-1 br-1 bl-1 bc-outlined-gray"
      tile
    >
      <v-card-text class="fs-15 py-0">
        <v-row dense>
          <v-col class="d-flex align-center">
            <p class="mb-0 py-4">
              {{ $t('Displaying') }}
              <strong>{{ items?.length }}</strong> {{ $t('out of') }}
              <strong>{{ totalItemCount }}</strong> {{ $t('total results') }}.
            </p>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-data-table-server
      v-if="items"
      :headers="tableHeaders"
      :hide-default-footer="!totalItemCount"
      :items="items"
      :items-length="totalItemCount"
      :items-per-page="100"
      :loading="processing"
      class="b-radius-0 b-1 bc-outlined-gray"
      item-key="id"
      no-data-text="No payments found"
      fixed-header
      return-object
      show-expand
    >
      <template #item.amount="{ item }">
        <span>
          {{ currency(item.amount) }}
        </span>
      </template>

      <template #expanded-row="{ item }">
        <template
          v-for="adjustment in item.adjustments"
          :key="adjustment.amount.toString() + adjustment.reason.toString()"
        >
          <tr class="bg-white-smoke">
            <td colspan="2" />

            <td
              class="d-flex justify-end align-center"
              colspan="1"
            >
              {{ currency(adjustment.amount) }}
            </td>

            <td
              class="justify-end"
              colspan="1"
            >
              {{ adjustment.reason }}
            </td>

            <td colspan="1" />
          </tr>
        </template>
      </template>
    </v-data-table-server>

    <VerticalSpacer :min-height="14" />
  </div>
</template>

<script setup>
import VerticalSpacer from '@/shared/components/VerticalSpacer.vue';
import { currency } from '@/plugins/filters';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const items = ref(null);
const processing = ref(null);
const totalItemCount = ref(null);

const props = defineProps({
  defaultProviders: {
    type: Array,
    default: () => null,
  },
  statement: {
    type: Object,
    default: () => {},
  },
});

const tableHeaders = computed(() => {
  return [
    {
      title: t('Payment ID'),
      value: 'id',
    },
    {
      title: t('Payment Date'),
      value: 'date',
    },
    {
      title: t('Amount'),
      value: 'amount',
      align: 'end',
    },
    {
      title: t('Description'),
      value: 'description',
    },
  ];
});

async function load() {
  items.value = props.statement.payments;
  totalItemCount.value = items.value.length;
}

onMounted(load);
</script>

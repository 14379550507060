<template>
  <v-container class="ms-2">
    <v-row class="mb-2">
      <v-col cols="12">
        <v-btn-toggle
          v-model="workflowStep.action_name"
          @update:model-value="changed = true"
          :disabled="disabled"
          color="primary"
          variant="outlined"
          divided
          group
          mandatory
          tile
        >
          <v-btn
            v-for="action of actionOptions"
            :key="action.action_name"
            :value="action.action_name"
          >
            {{ $t(action.description) }}
          </v-btn>
        </v-btn-toggle>
      </v-col>
    </v-row>

    <v-row
      v-if="workflowStep.action_name === WORKFLOW_ACTION_NAMES.CREATE"
      class="mt-0"
    >
      <v-col cols="6">
        <v-autocomplete
          v-model="workflowStep.action_arguments.data_type"
          @update:model-value="changed = true"
          :disabled="disabled"
          :items="schemaTypes"
          :label="$t('Record type (required)')"
          density="comfortable"
          item-title="name"
          item-value="value"
          hide-details
        />
      </v-col>

      <v-col cols="6">
        <v-autocomplete
          v-model="workflowStep.action_arguments.schema_id"
          @update:model-value="changed = true"
          :disabled="disabled || !workflowStep.action_arguments.data_type"
          :items="getSchemaOptions(workflowStep.action_arguments.data_type)"
          :label="$t('Record schema (required)')"
          density="comfortable"
          item-title="name"
          item-value="id"
          hide-details
        />
      </v-col>
    </v-row>

    <template v-if="workflowStep.action_name === WORKFLOW_ACTION_NAMES.NOTIFY">
      <v-row class="mt-0">
        <v-col cols="4">
          <v-select
            v-if="notifiableRelationshipOptions.length > 0"
            v-model="workflowStep.action_arguments.association_field"
            @update:model-value="changed = true"
            :disabled="disabled || undefined"
            :items="notifiableRelationshipOptions"
            :label="$t('Relationship')"
            density="comfortable"
            item-title="name"
            item-value="value"
            clearable
            hide-details
            persistent-clear
          />
        </v-col>

        <v-col cols="4">
          <v-autocomplete
            v-model="workflowStep.action_arguments.reviewer_id"
            @update:model-value="changed = true"
            :disabled="disabled || undefined"
            :items="reviewerOptions"
            :label="$t('Reviewer')"
            density="comfortable"
            item-title="name"
            item-value="id"
            clearable
            hide-details
            persistent-clear
          />
        </v-col>

        <v-col cols="4">
          <v-autocomplete
            v-model="workflowStep.action_arguments.team_id"
            @update:model-value="changed = true"
            :disabled="disabled || undefined"
            :items="teamOptions"
            :label="$t('Team')"
            density="comfortable"
            item-title="name"
            item-value="id"
            clearable
            hide-details
            persistent-clear
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-text-field
            v-model="workflowStep.action_arguments.subject"
            @update:model-value="changed = true"
            :label="$t('Subject (required)')"
            :placeholder="$t('Subject (required)')"
            density="comfortable"
            hide-details
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12">
          <v-textarea
            v-model="workflowStep.action_arguments.body"
            @update:model-value="changed = true"
            :label="$t('Body (required)')"
            :placeholder="$t('Body (required)')"
            density="comfortable"
            hide-details
          />
        </v-col>
      </v-row>
    </template>

    <v-row
      v-if="workflowStep.action_name === WORKFLOW_ACTION_NAMES.ASSIGN"
      class="mt-0"
    >
      <v-col cols="6">
        <v-autocomplete
          v-if="schemaHasReviewerAssociation"
          v-model="workflowStep.action_arguments.reviewer_id"
          @update:model-value="changed = true"
          :items="reviewerOptions"
          :label="$t('Reviewer')"
          density="comfortable"
          item-title="name"
          item-value="id"
          clearable
          hide-details
          persistent-clear
        />
      </v-col>

      <v-col cols="6">
        <v-autocomplete
          v-if="schemaHasTeamAssociation"
          v-model="workflowStep.action_arguments.team_id"
          @update:model-value="changed = true"
          :items="teamOptions"
          :label="$t('Team')"
          density="comfortable"
          item-title="name"
          item-value="id"
          clearable
          hide-details
          persistent-clear
        />
      </v-col>
    </v-row>

    <v-row
      v-if="!disabled"
      class="mt-2"
    >
      <v-col cols="12">
        <div class="d-flex justify-space-between">
          <span>
            <v-btn
              @click="saveWorkflowStep()"
              :aria-label="$t('Save workflow action')"
              :disabled="disabled"
              color="primary"
              size="small"
            >
              {{ $t('Save') }}
            </v-btn>
            <v-btn
              v-if="!workflowStep.enabled"
              @click="toggleEnabled()"
              :aria-label="$t('Enable workflow step')"
              :disabled="disabled || changed || !workflowStepComplete"
              class="ms-2"
              color="green"
              size="small"
              variant="outlined"
            >
              {{ $t('Enable') }}
            </v-btn>
            <v-btn
              v-else
              @click="toggleEnabled()"
              :aria-label="$t('Inactivate workflow step')"
              :disabled="disabled"
              class="ms-2"
              color="red"
              size="small"
              variant="outlined"
            >
              {{ $t('Disable') }}
            </v-btn>
          </span>

          <v-btn
            @click="deleteWorkflowStep()"
            :aria-label="$t('Delete workflow action')"
            :disabled="disabled"
            color="red"
            size="small"
          >
            {{ $t('Delete') }}
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <ConfirmDialog ref="confirmDialog" />
  </v-container>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import ConfirmDialog from '@/shared/components/ConfirmDialog.vue';
import Schema from '@/shared/services/schema';
import useEventBus from '@/shared/composables/useEventBus';
import { WORKFLOW_ACTION_NAMES } from '@/shared/assets/constants';

const NOTIFY_RELATIONSHIPS = {
  member_id: { value: 'member_id', name: 'Member' },
  reviewer_id: { value: 'reviewer_id', name: 'Reviewer' },
  team_id: { value: 'team_id', name: 'Team' },
  group_id: { value: 'group_id', name: 'Group' },
  provider_id: { value: 'provider_id', name: 'Provider' },
  business_id: { value: 'business_id', name: 'Business' },
};

// TODO: Update to not model original but a copy so that we can
// compare orginal to saved.
const workflowStep = defineModel({ type: Object });

const emit = defineEmits(['deleted']);

const props = defineProps({
  contextualRecordSchemaId: {
    type: String,
    required: true,
  },
  contextualStep: {
    type: Object,
    default: null,
  },
  disabled: {
    type: Boolean,
    default: undefined,
  },
  reviewerOptions: {
    type: Array,
    default: () => [],
  },
  schemas: {
    type: Array,
    default: () => [],
  },
  schemaTypes: {
    type: Array,
    default: () => [],
  },
  teamOptions: {
    type: Array,
    default: () => [],
  },
});

const eventBus = useEventBus();
const changed = ref(false);
const confirmDialog = ref(null);
const contextualSchemaFields = ref([]);

const actionOptions = computed(() => {
  const options = [
    { action_name: WORKFLOW_ACTION_NAMES.CREATE, description: 'Create linked record' },
    { action_name: WORKFLOW_ACTION_NAMES.NOTIFY, description: 'Notify user' },
  ];

  if (schemaHasReviewerAssociation.value || schemaHasTeamAssociation.value) {
    options.push({ action_name: WORKFLOW_ACTION_NAMES.ASSIGN, description: 'Assign record' });
  }

  // TODO: Implement update action
  // options.push({ action_name: WORKFLOW_ACTION_NAMES.UPDATE, description: 'Update record' });

  return options;
});

const notifiableRelationshipOptions = computed(() => {
  if (!contextualSchemaFields.value) return [];

  const notifiableFields = Object.keys(NOTIFY_RELATIONSHIPS);

  const options = [];
  for (const field of notifiableFields) {
    if (contextualSchemaFields.value.includes(field)) {
      options.push(NOTIFY_RELATIONSHIPS[field]);
    }
  }
  return options;
});

const schemaHasReviewerAssociation = computed(() => {
  return contextualSchemaFields.value.includes('reviewer_id');
});

const schemaHasTeamAssociation = computed(() => {
  return contextualSchemaFields.value.includes('team_id');
});

const workflowStepComplete = computed(() => {
  if (!workflowStep.value.action_name) return false;

  const actionArguments = workflowStep.value.action_arguments;

  // Create : Complete if provided a record data_type and schema_id
  if (workflowStep.value.action_name === WORKFLOW_ACTION_NAMES.CREATE) {
    return actionArguments.data_type && actionArguments.schema_id;
  }

  // Notify : Complete if it has a recipient, message subject, and message body
  if (workflowStep.value.action_name === WORKFLOW_ACTION_NAMES.NOTIFY) {
    const hasRecipient =
      actionArguments.reviewer_id || actionArguments.team_id || actionArguments.association_field;
    const hasMessageData = actionArguments.subject && actionArguments.body;
    return hasRecipient && hasMessageData;
  }

  // Notify : Complete if it has a reviewer_id or team_id set for assignment
  if (workflowStep.value.action_name === WORKFLOW_ACTION_NAMES.ASSIGN) {
    return actionArguments.reviewer_id || actionArguments.team_id;
  }

  // Update : TODO
  if (workflowStep.value.action_name === WORKFLOW_ACTION_NAMES.UPDATE) {
    console.log('TODO: Determine UPDATE action completion requirements');
    return false;
  }

  return false;
});

watch(
  () => workflowStep.value.action_name,
  (_newVal, oldVal) => {
    if (oldVal) {
      workflowStep.value.action_arguments = {};
    }
  },
  { immediate: true },
);

watch(
  () => workflowStep.value.action_arguments.data_type,
  (_newVal, oldVal) => {
    if (oldVal) {
      workflowStep.value.action_arguments.schema_id = null;
    }
  },
  { immediate: true },
);

watch(() => props.contextualRecordSchemaId, setContextualSchema, { immediate: true });

async function setContextualSchema() {
  const { data } = await Api.organization.schema.get(props.contextualRecordSchemaId);

  contextualSchemaFields.value = Schema.fields(data.definition);
}

function getSchemaOptions(modelType) {
  return props.schemas.filter((schema) => schema.data_type === modelType);
}

async function toggleEnabled() {
  const action = workflowStep.value.enabled ? 'disable' : 'enable';

  let message = `Are you sure you want to ${action} this workflow action?`;
  if (workflowStep.value.action_name === WORKFLOW_ACTION_NAMES.CREATE) {
    if (action === 'disable')
      message += ' Any following actions will no longer be performed on this created record.';
    else
      message +=
        ' Any following actions will now be performed in the context of this created record.';
  }
  const confirm = await confirmDialog.value.confirmWithText(message);
  if (!confirm) return;

  const params = {
    enabled: !workflowStep.value.enabled,
  };
  const resp = await Api.organization.workflow.step.update(workflowStep.value.id, params);

  eventBus.chime(`Action ${action}d`);
  workflowStep.value.enabled = resp.data.enabled;
}

async function saveWorkflowStep() {
  if (workflowStep.value.enabled && !workflowStepComplete.value) {
    eventBus.longChime(
      "You cannot save an incomplete, enabled action. If you'd like to save, either fill out missing data, or disable the action.",
    );
    return;
  }

  const resp = await Api.organization.workflow.step.update(
    workflowStep.value.id,
    workflowStep.value,
  );
  if (resp.status !== 200) return;

  changed.value = false;
  workflowStep.value = resp.data;
  eventBus.chime('Action saved');
}

async function deleteWorkflowStep() {
  let message = 'Are you sure you want to delete this action?';
  if (workflowStep.value.action_name === WORKFLOW_ACTION_NAMES.CREATE) {
    if (props.contextualStep)
      message +=
        ' Any following actions will now be performed in the context of the previously created record';
    else
      message +=
        ' Any following actions will now be performed in the context of the triggering record.';
  }

  const confirm = await confirmDialog.value.confirmWithText(message);
  if (!confirm) return;

  const resp = await Api.organization.workflow.step.destroy(workflowStep.value.id);
  if (resp.status !== 202) return;

  eventBus.chime('Action deleted');
  emit('deleted');
}
</script>

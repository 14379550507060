<template>
  <v-container
    v-if="summaryConfigurations && schemas"
    class="mxw-1200 pa-6"
  >
    <TitleBar
      @create="$refs.createDialog.open()"
      create-text="New summary"
      name="Summaries"
      createable
    />

    <NullState
      v-if="summaryConfigurations.length == 0"
      @new="$refs.createDialog.open()"
      title="You do not have any summaries"
    />

    <v-row
      v-for="(summaryConfiguration, index) in summaryConfigurations"
      :key="index"
    >
      <v-col>
        <v-card
          border
          flat
          tile
        >
          <v-card-text>
            <v-row class="d-flex align-center">
              <v-col>
                <router-link
                  :to="{
                    name: 'SummaryConfigurationShow',
                    params: { id: summaryConfiguration.id },
                  }"
                >
                  <span v-text="summaryConfiguration.name" />
                </router-link>
              </v-col>
              <v-col class="d-flex justify-end">
                <v-btn
                  @click="destroy(summaryConfiguration)"
                  :aria-label="$t('Remove summary configuration')"
                  variant="text"
                  icon
                >
                  <v-icon icon="close" />
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <ResourceDialog
      @save="create"
      ref="createDialog"
      :fields="[
        { text: 'Name', value: 'name', required: true },
        {
          text: 'Schema',
          value: 'summary_schema_id',
          items: schemas,
          itemText: 'name',
          itemValue: 'id',
          required: true,
        },
      ]"
      title="New summary"
    />

    <ConfirmDialog ref="confirmDialog" />
  </v-container>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import ConfirmDialog from '@/shared/components/ConfirmDialog.vue';
import NullState from '@/shared/components/NullState.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import TitleBar from '@/shared/components/TitleBar.vue';

const confirmDialog = ref(null);
const createDialog = ref(null);
const summaryConfigurations = ref(undefined);
const schemas = ref(null);

onMounted(() => {
  void load();
  void loadSchemas();
});

async function create(newVal) {
  await Api.organization.summary_configuration.create(newVal);
  createDialog.value?.close();
  void load();
}

async function destroy(summaryConfiguration) {
  const confirm = await confirmDialog.value.confirmWithText(
    `Are you sure you want to destroy "${summaryConfiguration.name}"?`,
  );
  if (!confirm) return;

  await Api.organization.summary_configuration.destroy(summaryConfiguration.id);
  void load();
}

async function load() {
  const resp = await Api.organization.summary_configuration.index({});
  summaryConfigurations.value = resp?.data || [];
}

async function loadSchemas() {
  const resp = await Api.organization.schema.index({ data_type: 'Summary' });
  schemas.value = resp?.data || [];
}
</script>

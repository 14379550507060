<template>
  <div class="fill-height">
    <v-card>
      <v-card-text>
        <v-progress-linear
          v-if="processing"
          indeterminate
        />
        <v-stepper-vertical
          v-else
          flat
          hide-actions
          multiple
        >
          <!-- RECORD SELECTION -->
          <v-stepper-vertical-item
            :complete="recordSelectionComplete"
            color="primary"
            icon="assignment"
            value="1"
            editable
          >
            <template #title>
              <div class="ms-2">
                {{ $t('On which record?') }}
              </div>
            </template>
            <template #subtitle>
              <div class="ms-2 pt-2">
                {{ $t('The record that this trigger is taking place on.') }}
              </div>
            </template>

            <v-container class="ms-2">
              <v-row>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="workflowEvent.eventable_type"
                    :items="schemaTypeOptions"
                    :label="$t('Record type (required)')"
                    density="comfortable"
                    item-title="name"
                    item-value="value"
                    hide-details
                  />
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="6">
                  <v-autocomplete
                    v-model="workflowEvent.schema_id"
                    :disabled="!workflowEvent.eventable_type"
                    :items="schemaOptions"
                    :label="$t('Record schema (required)')"
                    density="comfortable"
                    item-title="name"
                    item-value="id"
                    hide-details
                  />
                </v-col>

                <v-col cols="6">
                  <v-autocomplete
                    v-model="workflowEvent.report_id"
                    @update:model-value="workflowEventUpdated()"
                    @update:search="searchReports"
                    :aria-label="$t('Search by report name...')"
                    :disabled="!workflowEvent.eventable_type"
                    :items="reports"
                    :label="$t('Scoped report (optional)')"
                    :placeholder="$t('Search by report name...')"
                    density="comfortable"
                    item-title="name"
                    item-value="id"
                    clearable
                    flat
                    hide-details
                    persistent-clear
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-stepper-vertical-item>

          <!-- RUN TYPE -->
          <v-stepper-vertical-item
            :complete="runTypeSelectionComplete"
            :disabled="!recordSelectionComplete"
            :editable="recordSelectionComplete"
            color="primary"
            icon="alarm"
            value="2"
          >
            <template #title>
              <div class="ms-2">
                {{ $t('When should this workflow run?') }}
              </div>
            </template>
            <template #subtitle>
              <div class="ms-2 pt-2">
                <ul class="compact-list">
                  <li>
                    {{ $t('Triggered: Runs instantly based upon specified trigger') }}
                  </li>
                  <li class="mt-1">
                    {{ $t('Nightly: Runs once a day for all qualifying records') }}
                  </li>
                </ul>
              </div>
            </template>

            <v-container class="ms-2">
              <v-row>
                <v-col cols="12">
                  <v-btn-toggle
                    v-model="workflowEvent.triggered"
                    :disabled="!recordSelectionComplete"
                    color="primary"
                    variant="outlined"
                    divided
                    group
                    mandatory
                    tile
                  >
                    <v-btn :value="true">
                      {{ $t('Triggered') }}
                    </v-btn>
                    <v-btn :value="false">
                      {{ $t('Nightly') }}
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>
            </v-container>
          </v-stepper-vertical-item>

          <!-- TRIGGER SELECTION -->
          <v-stepper-vertical-item
            v-if="workflowEvent.triggered"
            :complete="triggerSelectionComplete"
            :disabled="!runTypeSelectionComplete"
            :editable="runTypeSelectionComplete"
            color="primary"
            icon="pending_actions"
            value="3"
            eager
          >
            <template #title>
              <div class="ms-2">
                {{ $t('Select a trigger') }}
              </div>
            </template>
            <template #subtitle>
              <div class="ms-2 pt-2">
                {{ $t('This is the starting event that triggers an action to execute.') }}
              </div>
            </template>

            <v-container class="ms-2">
              <v-row>
                <v-col cols="12">
                  <v-btn-toggle
                    v-model="selectedTriggerType"
                    :disabled="!recordSelectionComplete"
                    color="primary"
                    variant="outlined"
                    divided
                    group
                    mandatory
                    tile
                  >
                    <v-btn
                      v-for="trigger of triggerOptions"
                      :key="trigger.type"
                      :value="trigger.type"
                    >
                      {{ $t(trigger.name) }}
                    </v-btn>
                  </v-btn-toggle>
                </v-col>
              </v-row>

              <!-- FIELD CHANGE -->
              <v-row v-if="selectedTriggerType === 'FIELD'">
                <v-col cols="6">
                  <v-autocomplete
                    v-model="workflowEvent.eventable_attribute_name"
                    @update:model-value="workflowEventUpdated()"
                    :disabled="!recordSelectionComplete"
                    :items="schemaFieldOptions"
                    :label="$t('Select field (required)')"
                    :placeholder="$t('Select field (required)')"
                    density="comfortable"
                    hide-details
                  />
                </v-col>

                <v-col cols="6">
                  <v-autocomplete
                    v-model="workflowEvent.eventable_attribute_value"
                    @update:model-value="workflowEventUpdated()"
                    :disabled="!recordSelectionComplete"
                    :items="EVENTABLE_BEHAVIORS"
                    :label="$t('Select field value (required)')"
                    :placeholder="$t('Select field value (required)')"
                    density="comfortable"
                    item-title="description"
                    item-value="value"
                    hide-details
                  />
                </v-col>
              </v-row>

              <!-- STATUS CHANGE -->
              <v-row v-if="selectedTriggerType === 'STATUS'">
                <!-- CHANGED FROM -->
                <v-col cols="6">
                  <v-autocomplete
                    v-model="workflowEvent.eventable_attribute_previous_value"
                    @update:model-value="workflowEventUpdated()"
                    :disabled="!recordSelectionComplete"
                    :items="statusOptions"
                    :label="$t('Changed from (optional)')"
                    :placeholder="$t('Select a status')"
                    density="comfortable"
                    item-title="name"
                    item-value="value"
                    clearable
                    hide-details
                    persistent-clear
                  />
                </v-col>
                <!-- CHANGED TO -->
                <v-col cols="6">
                  <v-autocomplete
                    v-model="workflowEvent.eventable_attribute_value"
                    @update:model-value="workflowEventUpdated()"
                    :disabled="!recordSelectionComplete"
                    :items="statusOptions"
                    :label="$t('Changed to (optional)')"
                    :placeholder="$t('Select a status')"
                    density="comfortable"
                    item-title="name"
                    item-value="value"
                    clearable
                    hide-details
                    persistent-clear
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-stepper-vertical-item>

          <v-stepper-vertical-item
            :disabled="!actionSelectionEditable"
            :editable="actionSelectionEditable"
            color="primary"
            icon="arrow_circle_up"
            value="4"
            eager
          >
            <template #title>
              <div class="ms-2">
                {{ $t('Configure actions') }}
              </div>
            </template>
            <template #subtitle>
              <div class="ms-2 pt-2">
                {{ $t('What should happen when the event triggers?') }}
              </div>
            </template>

            <WorkflowEventSteps
              :disabled="!actionSelectionEditable"
              :schema-types="schemaTypeOptions"
              :schemas="allSchemas"
              :workflow-event="workflowEvent"
            />
          </v-stepper-vertical-item>
        </v-stepper-vertical>
      </v-card-text>
    </v-card>
  </div>
</template>

<script setup>
import _ from 'lodash';
import Api from '@/specialist/services/bright_finder';
import Schema from '@/shared/services/schema';
import { STATUSES_BY_MODEL } from '@/shared/assets/constants';
import { toSentenceCase } from '@/shared/services/string-utils';
import WorkflowEventSteps from '@/admin/views/workflow-events/WorkflowEventSteps.vue';
import { VStepperVertical, VStepperVerticalItem } from 'vuetify/labs/VStepperVertical';

const emit = defineEmits(['updated']);

const EVENTABLE_BEHAVIORS = [
  { value: 'changed', description: 'Value changed' },
  { value: 'blank', description: 'No value (nil)' },
  { value: 'any', description: 'Any value (not nil)' },
  { value: 'initialized', description: 'Initialized (nil -> not nil)' },
  { value: 'reset', description: 'Reset (not nil -> nil)' },
  { value: 'false', description: 'Boolean - false' },
  { value: 'true', description: 'Boolean - true' },
];

const STATUSES = [
  { value: 'awaiting_documents', name: 'Awaiting documents' },
  { value: 'in_progress', name: 'In progress' },
  { value: 'in_review', name: 'In review' },
  { value: 'reviewed', name: 'Reviewed' },
  { value: 'submitted', name: 'Submitted' },
  { value: 'waitlisted', name: 'Waitlisted' },
  { value: 'withdrawn', name: 'Withdrawn' },
];

const workflowEvent = defineModel({ type: Object });

const allSchemas = ref([]);
const processing = ref(true);
const reports = ref([]);
const eventSchema = ref(null);
const schemaTypeOptions = ref([]);
const selectedTriggerType = ref(undefined);

onMounted(() => {
  void load();
});

const schemaOptions = computed(() => {
  return allSchemas.value.filter(
    (schema) => schema.data_type === workflowEvent.value.eventable_type,
  );
});

const schemaFieldOptions = computed(() => {
  if (!eventSchema.value) return [];

  return Schema.fields(eventSchema.value.definition);
});

const statusOptions = computed(() => {
  if (!workflowEvent.value.eventable_type) return [];

  if (STATUSES_BY_MODEL[workflowEvent.value.eventable_type]) {
    return STATUSES_BY_MODEL[workflowEvent.value.eventable_type];
  }

  return STATUSES;
});

const triggerOptions = computed(() => {
  const options = [
    { type: 'CREATE', name: 'Created' },
    { type: 'DELETE', name: 'Deleted' },
  ];

  if (schemaFieldOptions.value?.includes('status')) {
    options.push({ type: 'STATUS', name: 'Status change' });
  }

  options.push({ type: 'FIELD', name: 'Field change' });
  return options;
});

// STEP ONE - RECORD SELECTION
const recordSelectionComplete = computed(() => {
  return !!(workflowEvent.value.eventable_type && workflowEvent.value.schema_id);
});

// STEP TWO - RUN TYPE
const runTypeSelectionComplete = computed(() => {
  return workflowEvent.value.triggered !== null;
});

// STEP THREE - TRIGGER SELECTION
const triggerSelectionComplete = computed(() => {
  if (!workflowEvent.value.triggered) return true;
  if (!selectedTriggerType.value) return false;

  if (selectedTriggerType.value === 'STATUS') {
    return (
      workflowEvent.value.eventable_attribute_name &&
      (workflowEvent.value.eventable_attribute_previous_value ||
        workflowEvent.value.eventable_attribute_value)
    );
  }

  if (selectedTriggerType.value === 'FIELD') {
    return (
      workflowEvent.value.eventable_attribute_name && workflowEvent.value.eventable_attribute_value
    );
  }

  return ['CREATE', 'DELETE'].includes(selectedTriggerType.value);
});

// STEP FOUR - ACTIONS
const actionSelectionEditable = computed(() => {
  // Null or false
  if (!workflowEvent.value.triggered) {
    return !!runTypeSelectionComplete.value;
  } else {
    return !!(runTypeSelectionComplete.value && triggerSelectionComplete.value);
  }
});

watch(
  () => workflowEvent.value,
  (newVal, oldVal) => {
    if (!oldVal && newVal) {
      // Based on the configured attributes, set selected trigger
      const attributeName = workflowEvent.value.eventable_attribute_name;
      const attributeValue = workflowEvent.value.eventable_attribute_value;
      let triggerType = null;

      if (!attributeName) return;

      if (attributeName === 'created_at' && attributeValue === 'initialized') {
        triggerType = 'CREATE';
      } else if (attributeName === 'deleted_at' && attributeValue === 'initialized') {
        triggerType = 'DELETE';
      } else if (attributeName === 'status') {
        triggerType = 'STATUS';
      }

      if (!triggerType && attributeName) {
        triggerType = 'FIELD';
      }

      selectedTriggerType.value = triggerType;
    }
  },
  { immediate: true },
);

watch(
  () => workflowEvent.value.eventable_type,
  (_newVal, oldVal) => {
    // If undefined, initial trigger set, don't update other fields
    if (oldVal === undefined) return;

    if (oldVal) {
      workflowEvent.value.schema_id = null;
      workflowEvent.value.eventable_attribute_name = null;
      workflowEvent.value.eventable_attribute_value = null;
      workflowEvent.value.triggered = null;
      selectedTriggerType.value = null;
    }
    workflowEventUpdated();
  },
  { immediate: true },
);

watch(
  () => workflowEvent.value.schema_id,
  async (_newVal, oldVal) => {
    // If undefined, initial trigger set, don't update other fields
    if (oldVal === undefined) return;

    if (oldVal) {
      workflowEvent.value.eventable_attribute_name = null;
      workflowEvent.value.eventable_attribute_value = null;
      workflowEvent.value.eventable_attribute_previous_value = null;

      // TODO: For now if a schema changes, we don't know whether or not a
      // trigger type of status or field (if it existed) is valid w/ the new
      // schema fields, clearing out for now (CREATE and DELETE are always valid)
      if (['STATUS', 'FIELD'].includes(selectedTriggerType.value)) {
        selectedTriggerType.value = null;
      }
    }
    workflowEventUpdated();
    void getEventSchema();
  },
  { immediate: true },
);

watch(
  () => workflowEvent.value.triggered,
  (_newVal, oldVal) => {
    // If undefined, initial trigger set, don't update other fields
    if (oldVal === undefined) return;

    if (oldVal === false || oldVal === true) {
      workflowEvent.value.eventable_attribute_name = null;
      workflowEvent.value.eventable_attribute_value = null;
      workflowEvent.value.eventable_attribute_previous_value = null;
      selectedTriggerType.value = null;
    }
    workflowEventUpdated();
  },
  { immediate: true },
);

watch(
  () => selectedTriggerType.value,
  (newVal, oldVal) => {
    // If undefined, initial trigger set, don't update other fields
    if (oldVal === undefined) return;

    if (oldVal) {
      workflowEvent.value.eventable_attribute_name = null;
      workflowEvent.value.eventable_attribute_value = null;
      workflowEvent.value.eventable_attribute_previous_value = null;
    }

    if (newVal === 'CREATE') {
      workflowEvent.value.eventable_attribute_name = 'created_at';
      workflowEvent.value.eventable_attribute_value = 'initialized';
    }

    if (newVal === 'DELETE') {
      workflowEvent.value.eventable_attribute_name = 'deleted_at';
      workflowEvent.value.eventable_attribute_value = 'initialized';
    }

    if (newVal === 'STATUS') {
      workflowEvent.value.eventable_attribute_name = 'status';
    }

    if (newVal === 'FIELD') {
      workflowEvent.value.eventable_attribute_value = 'changed';
    }

    workflowEventUpdated();
  },
  { immediate: true },
);

async function getEventSchema() {
  if (workflowEvent.value.schema_id && workflowEvent.value.schema_id !== eventSchema.value?.id) {
    const { data } = await Api.organization.schema.get(workflowEvent.value.schema_id);
    eventSchema.value = data;
  } else {
    eventSchema.value = null;
  }
}

async function load() {
  void loadSchemas();
  void getEventSchema();
  await populateReportOptions();
}

async function loadSchemas() {
  processing.value = true;
  const { data } = await Api.organization.schema.index({});

  allSchemas.value = data;

  // Set data type options based on available schemas
  let options = allSchemas.value.map((schema) => {
    return { value: schema.data_type, name: toSentenceCase(schema.data_type) };
  });
  options = _.uniqBy(options, 'value');
  schemaTypeOptions.value = _.orderBy(options, 'value', 'asc');

  processing.value = false;
}

async function populateReportOptions() {
  if (!workflowEvent.value.report_id) return;

  const { data } = await Api.organization.report.get(workflowEvent.value.report_id);

  reports.value = [data];
}

async function searchReports(query) {
  const { data } = await Api.organization.report.index({ query });
  reports.value = data;
}

function workflowEventUpdated() {
  emit('updated');
}
</script>

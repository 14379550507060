<template>
  <div v-if="provider && meta">
    <v-toolbar class="fullwidth bb-1 bc-very-light-grey">
      <v-row class="d-flex align-center">
        <v-col class="d-flex align-center">
          <TitleBar
            :name="provider.name"
            data-cy="page-title"
            indented
          >
            <template #append>
              <v-avatar
                @click="
                  $refs.closedStatusDialog.open({
                    closed_status: provider.closed_status,
                    closure_reason: provider.closure_reason,
                    temporary_closure_reason: provider.temporary_closure_reason,
                    open_date: provider.open_date,
                  })
                "
                :color="provider.closed_status === 'Open' ? 'green' : 'red'"
                class="mx-3 pointer"
                size="20"
                dark
              />

              <ResourceDialog
                @save="update"
                ref="closedStatusDialog"
                title="Open/close status"
                close-on-save
              >
                <template #form="{ localValue }">
                  <v-row>
                    <LabeledSelect
                      v-model="localValue.closed_status"
                      :bg-color="localValue.closed_status === 'Open' ? 'green' : 'red'"
                      :schema-id="provider.schema_id"
                      aria-label="Closed status"
                      field="closed_status"
                      prepend-inner-icon="check_circle"
                      rounded
                    />
                    <LabeledSelect
                      v-show="localValue.closed_status === 'Closed'"
                      v-model="localValue.closure_reason"
                      :schema-id="provider.schema_id"
                      aria-label="Closure reason"
                      field="closure_reason"
                      message="Closure reason"
                    />
                    <LabeledSelect
                      v-show="localValue.closed_status === 'Temporarily closed'"
                      v-model="localValue.temporary_closure_reason"
                      :schema-id="provider.schema_id"
                      aria-label="Temporary closed reason"
                      field="temporary_closure_reason"
                      message="Closure reason"
                    />
                    <LabeledDatePicker
                      v-if="localValue.closed_status === 'Temporarily closed'"
                      v-model="localValue.open_date"
                      :schema-id="provider.schema_id"
                      aria-label="Choose date you will open"
                      field="open_date"
                    />
                  </v-row>
                </template>
              </ResourceDialog>
            </template>

            <template #inline-actions>
              <ActionMenu
                @click:action:delete="deleteProvider"
                :items="[{ event: 'delete', title: 'Delete provider' }]"
                left
              />
            </template>

            <template #actions>
              <TitleBarToggleButton
                @toggle="togglePublished"
                :enabled="provider.published"
                :processing="processing"
                data-cy="publish-button"
                disabled-text="Unpublished"
                enabled-text="Published"
              />
            </template>
          </TitleBar>
        </v-col>
      </v-row>

      <template #extension>
        <template v-if="$vuetify.display.lgAndUp">
          <v-tabs :mandatory="false">
            <v-tab
              v-for="(tabItem, index) in tabs"
              :key="index"
              :data-cy="['tab', tabItem[0]].join('-')"
              :to="{ query: { tab: tabItem[0] } }"
              class="fs-16 ls-normal tt-none"
              exact
            >
              {{ $t(tabItem[1]) }}
            </v-tab>

            <ActionMenu
              @click:action:go="$router.replace({ query: { tab: $event.tab } })"
              :button-icon-size="18"
              :items="moreTabActionItems"
              :label="$t('Tab Menu')"
              button-class="px-4 fw-500"
              button-data-cy="more-tab-button"
              button-icon-side="right"
              button-title="More"
            />
          </v-tabs>
        </template>

        <template v-else>
          <ActionMenu
            @click:action:go="$router.replace({ query: { tab: $event.tab } })"
            :button-title="currentTab"
            :items="tabActionItems"
            :label="$t('Tab Menu')"
            button-icon-side="right"
            large
          />
        </template>
      </template>
    </v-toolbar>

    <v-container
      class="bg-super-light-blue"
      fluid
    >
      <v-row v-if="provider.private_notice">
        <v-col cols="12">
          <v-textarea
            v-model="provider.private_notice"
            label="Private Notice"
            rows="2"
            variant="outlined"
            readonly
          />
        </v-col>
      </v-row>

      <template
        v-if="forms.filter((form) => !form.reviewed).length > 0 && route.query.tab !== 'forms'"
      >
        <v-alert
          class="mb-4"
          color="primary"
          data-cy="form-alert"
          type="info"
        >
          <v-row
            class="d-flex align-center"
            dense
          >
            <v-col>
              <span class="me-1">
                {{ $t('Forms are ready for review.') }}
              </span>

              <router-link
                :to="{
                  name: 'ProviderShow',
                  params: { providerId: route.params.providerId },
                  query: { tab: 'forms' },
                }"
                class="c-white"
                data-cy="form-review-link"
              >
                {{ $t('Review forms') }}
              </router-link>
            </v-col>
          </v-row>
        </v-alert>
      </template>

      <v-window v-model="tab">
        <v-window-item
          class="bg-transparent"
          value="profile"
        >
          <v-row>
            <v-col
              cols="12"
              lg="8"
            >
              <template v-if="meta.photos.enabled">
                <Photos :provider-id="provider.id" />
              </template>

              <template v-if="meta.profile.enable_description">
                <ProviderDescription :provider="provider" />
              </template>

              <template v-if="meta.profile.enable_emergency_response">
                <EmergencyResponse
                  :provider="provider"
                  class="mb-4"
                />
              </template>

              <template v-if="meta.hours.enabled">
                <ProviderHours v-model="provider" />
              </template>

              <template v-if="meta.profile.enable_payment_details">
                <PaymentDetails :provider="provider" />
              </template>

              <template v-if="meta.profile.enable_highlights">
                <ProviderHighlights :provider="provider" />
              </template>
            </v-col>

            <v-col
              cols="12"
              lg="4"
            >
              <ContactInformation
                :business="business"
                :provider="provider"
                class="mb-4"
              />
              <template v-if="meta.staff.enabled">
                <StaffMembers
                  :provider-id="provider.id"
                  class="mb-4"
                />
              </template>
            </v-col>
          </v-row>
        </v-window-item>

        <v-window-item
          v-if="meta.programs.enabled"
          value="programs"
        >
          <Programs
            @refresh="load"
            :age-groups="
              $store.state.ageGroups.filter((ag) => provider.age_group_ids.includes(ag.id))
            "
            :description="meta.programs.description"
            :provider-id="provider.id"
            :regular-hours="provider.hours"
          />
        </v-window-item>

        <v-window-item
          v-if="meta.openings.enabled"
          value="openings"
        >
          <ProgramOpenings
            @change:openings-confirmed="confirmAvailability($event)"
            id="openings"
            ref="openings"
            :openings-confirmed-at="provider.openings_confirmed_at"
            :provider-id="provider.id"
          />
        </v-window-item>

        <v-window-item
          v-if="meta.enrollment.enabled"
          value="enrollment"
        >
          <EnrollmentCount
            @refresh="load"
            ref="enrollment"
            :description="meta.enrollment.description"
            :provider-id="provider.id"
          />
        </v-window-item>

        <v-window-item
          v-if="meta.interested.enabled"
          value="interested"
        >
          <EnrollmentRequestTable
            ref="enrollment"
            :provider-id="provider.id"
            :title="meta.interested.title"
            :waitlist="meta.interested.enable_waitlist"
          />
        </v-window-item>

        <v-window-item
          v-if="meta.students.enabled"
          value="students"
        >
          <EnrollmentTable
            ref="enrollment"
            :provider-id="provider.id"
            :title="meta.students.title"
          />
        </v-window-item>

        <v-window-item
          v-if="meta.attendance.enabled"
          value="attendance"
        >
          <AttendanceIndex
            ref="attendance"
            :title="terms.attendance"
          />
        </v-window-item>

        <v-window-item value="licensing">
          <ProviderLicensing
            @change="save"
            :provider="provider"
          />
        </v-window-item>

        <v-window-item
          v-if="meta.forms.enabled || meta.agreements.enabled"
          value="forms"
        >
          <template v-if="meta.forms.enabled">
            <FormTable
              :provider="provider"
              class="mb-4"
            />
          </template>

          <template v-if="meta.agreements.enabled">
            <AgreementTable
              :members="members"
              :provider-id="provider.id"
              class="mb-4"
            />
          </template>
          <VerticalSpacer :min-height="100" />
        </v-window-item>

        <v-window-item value="settings">
          <Settings
            @change="save"
            :provider="provider"
            :providers="siblingProviders"
          />
        </v-window-item>

        <v-window-item
          v-if="$store.state.pages.Provider.features.enable_history"
          value="history"
        >
          <RevisionTable
            :owner-id="provider.id"
            owner-type="Provider"
          />
        </v-window-item>
      </v-window>
    </v-container>
  </div>
</template>

<script setup>
import ActionMenu from '@/shared/components/ActionMenu.vue';
import AgreementTable from '@/specialist/components/provider/AgreementTable.vue';
import Api from '@/specialist/services/bright_finder';
import AttendanceIndex from '@/specialist/views/AttendanceIndex.vue';
import ContactInformation from '@/shared/components/provider/ContactInformation.vue';
import EmergencyResponse from '@/shared/components/provider/EmergencyResponse.vue';
import EnrollmentCount from '@/shared/components/provider/EnrollmentCount.vue';
import EnrollmentRequestTable from '@/specialist/components/provider/EnrollmentRequestTable.vue';
import EnrollmentTable from '@/specialist/components/provider/EnrollmentTable.vue';
import FormTable from '@/specialist/components/provider/FormTable.vue';
import LabeledDatePicker from '@/shared/components/form/LabeledDatePicker.vue';
import LabeledSelect from '@/shared/components/form/LabeledSelect.vue';
import PaymentDetails from '@/shared/components/provider/PaymentDetails.vue';
import Photos from '@/specialist/components/provider/Photos.vue';
import ProgramOpenings from '@/shared/components/provider/ProgramOpenings.vue';
import Programs from '@/specialist/components/provider/programs.vue';
import ProviderDescription from '@/shared/components/provider/ProviderDescription.vue';
import ProviderHighlights from '@/shared/components/provider/ProviderHighlights.vue';
import ProviderHours from '@/shared/components/provider/ProviderHours.vue';
import ProviderLicensing from '@/shared/components/provider/ProviderLicensing.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import RevisionTable from '@/specialist/components/RevisionTable.vue';
import Settings from '@/specialist/components/provider/Settings.vue';
import StaffMembers from '@/shared/components/provider/StaffMembers.vue';
import TitleBar from '@/shared/components/TitleBar.vue';
import TitleBarToggleButton from '@/specialist/components/TitleBarToggleButton.vue';
import useEventBus from '@/shared/composables/useEventBus';
import useTerms from '@/shared/composables/useTerms';
import VerticalSpacer from '@/shared/components/VerticalSpacer.vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

const eventBus = useEventBus();
const route = useRoute();
const router = useRouter();
const store = useStore();
const { terms } = useTerms();

const business = ref();
const forms = ref([]);
const members = ref([]);
const meta = ref();
const processing = ref(false);
const provider = ref();
const siblingProviders = ref([]);
const tab = ref('profile');

const allTabs = computed(() => {
  return tabs.value.concat(moreTabs.value);
});

const currentTab = computed(() => {
  return allTabs.value.find((thisTab) => thisTab[0] === tab.value)[1];
});

const moreTabs = computed(() => {
  const tabArray = [];
  tabArray.push(['settings', 'Settings']);

  if (store.state.pages.Provider.features.enable_history) tabArray.push(['history', 'History']);

  return tabArray;
});

const moreTabActionItems = computed(() => {
  return moreTabs.value.map((tab) => ({
    event: 'go',
    title: tab[1],
    tab: tab[0],
  }));
});

const tabs = computed(() => {
  let tabArray = [];
  tabArray = tabArray.concat([
    ['profile', 'Profile'],
    ['programs', terms.value.programs],
  ]);

  if (!meta.value) {
    return tabArray;
  }

  if (meta.value.openings.enabled) {
    tabArray.push(['openings', 'Openings']);
  }

  if (meta.value.enrollment.enabled) {
    tabArray.push(['enrollment', 'Enrollment']);
  }

  if (meta.value.interested.enabled) {
    tabArray.push(['interested', meta.value.interested.title]);
  }

  if (meta.value.students.enabled) {
    tabArray.push(['students', meta.value.students.title]);
  }

  if (meta.value.attendance.enabled) {
    tabArray.push(['attendance', terms.value.attendance]);
  }

  tabArray.push(['licensing', 'Licensing']);

  if (meta.value.forms.enabled || meta.value.agreements.enabled) {
    tabArray.push(['forms', 'Forms']);
  }

  return tabArray;
});

const tabActionItems = computed(() => {
  return allTabs.value.map((tab) => ({
    event: 'go',
    title: tab[1],
    tab: tab[0],
  }));
});

async function confirmAvailability(message) {
  const resp = await Api.provider.update(provider.value.id, { openings_confirmed: true });

  provider.value = resp.data;
  eventBus.chime(message);
}

function deleteProvider() {
  // TODO: Replace with ConfirmDialog
  if (confirm('Are you sure you want to delete this provider?')) {
    Api.provider.destroy(provider.value.id, () => {
      router.replace({ name: 'ProviderSearch' });
    });
  }
}

async function load() {
  eventBus.off('blur');
  eventBus.on('blur', save);
  const providerResponse = await Api.organization.provider.get(route.params.providerId);
  provider.value = providerResponse.data;
  const providerSchema = store.state.schemas[provider.value.schema_id];

  meta.value = null;
  if (providerSchema) meta.value = providerSchema.meta;

  if (provider.value.business_id) {
    Api.organization.business.get(provider.value.business_id, (response) => {
      business.value = response.data;
    });
    Api.organization.member.index({ provider_id: route.params.providerId }, (response) => {
      members.value = response.data;
    });
    Api.organization.provider.index(
      { business_id: provider.value.business_id, page_size: 200 },
      (response) => {
        siblingProviders.value = response.data;
      },
    );
    loadForms();
  }
}

function loadForms() {
  forms.value = [];
  if (meta.value.forms.enabled) {
    Api.organization.form.index({ provider_id: route.params.providerId }, (response) => {
      forms.value = response.data;
    });
  }
}

function save() {
  Api.organization.provider.update(
    provider.value.id,
    provider.value,
    (response) => {
      provider.value = response.data;
      eventBus.chime('Changes saved');
    },
    (error) => {
      eventBus.chime(error.response.data.errors.join('. '));
    },
  );
}

function togglePublished() {
  update({ published: !provider.value.published });
}

function update(newValue) {
  processing.value = true;
  Api.organization.provider.update(
    provider.value.id,
    newValue,
    (response) => {
      processing.value = false;
      provider.value = response.data;
      eventBus.chime('Saved');
    },
    (error) => {
      processing.value = false;
      eventBus.chime(error.response.data.errors.join('. '));
    },
  );
}

onMounted(load);

watch(() => route.params.providerId, load);

watch(
  () => route.query.tab,
  (newValue) => {
    if (newValue) tab.value = newValue;
  },
  { immediate: true },
);
</script>

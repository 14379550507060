<template>
  <v-row>
    <v-col class="d-flex align-center">
      <v-icon
        :color="resultColor"
        class="me-2"
        data-testid="result-icon"
        size="25"
      >
        {{ resultIcon }}
      </v-icon>
      <div>{{ props.message }}</div>
    </v-col>
  </v-row>
</template>

<script setup>
const props = defineProps({
  message: {
    type: String,
    required: true,
  },
  passing: {
    type: Boolean,
    required: true,
  },
  schemaFunctionId: {
    type: String,
    required: true,
  },
});

const resultIcon = computed(() => {
  return props.passing ? 'check_circle' : 'cancel';
});

const resultColor = computed(() => {
  return props.passing ? 'green' : 'red';
});
</script>

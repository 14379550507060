<template>
  <v-container
    class="px-0 py-0"
    fluid
  >
    <PageTitle
      @create="$refs.newDialog.open()"
      create-text="New automation"
      title="Workflow automations"
      createable
      flat
      outlined
    />

    <v-container
      class="mt-3 px-3 py-0"
      fluid
    >
      <v-data-table
        @click:row="viewWorkflow"
        :headers="tableHeaders"
        :hide-default-footer="workflowEvents.length === 0"
        :hide-default-header="workflowEvents.length === 0"
        :hover="workflowEvents.length > 0"
        :items="workflowEvents"
        :loading="processing"
        fixed-header
      >
        <template #item.enabled="{ item }">
          <v-chip
            :color="item.enabled ? 'light-green-lighten-3' : 'grey-lighten-3'"
            size="small"
          >
            {{ item.enabled ? $t('Enabled') : $t('Disabled') }}
          </v-chip>
        </template>

        <template #item.created_at="{ item }">
          <LongDateTime :date="item.created_at" />
        </template>

        <template #item.actions="{ item }">
          <div class="d-flex justify-end align-center">
            <ActionMenu
              @click:action:delete="deleteAutomation(item.id)"
              :items="[{ title: 'Delete', event: 'delete' }]"
              button-icon="more_vert"
            />
          </div>
        </template>

        <template #no-data>
          <NullState
            @new="$refs.newDialog.open()"
            class="my-4"
            new-button-title="New automation"
          />
        </template>
      </v-data-table>
    </v-container>

    <ConfirmDialog ref="confirmDialog" />

    <ResourceDialog
      @save="createNewWorkflow"
      ref="newDialog"
      :fields="[{ text: 'Name', value: 'name', required: true }]"
      :processing="processing"
      title="New workflow automation"
    />
  </v-container>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import ActionMenu from '@/shared/components/ActionMenu.vue';
import ConfirmDialog from '@/shared/components/ConfirmDialog.vue';
import PageTitle from '@/shared/components/PageTitle.vue';
import LongDateTime from '@/shared/components/LongDateTime.vue';
import NullState from '@/shared/components/NullState.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import useEventBus from '@/shared/composables/useEventBus';
import { useRouter } from 'vue-router';

const eventBus = useEventBus();
const router = useRouter();

const tableHeaders = [
  { title: '', value: 'enabled', width: '5%' },
  { title: 'Name', value: 'name' },
  // { title: 'Owner', value: 'author_name' },
  { title: 'Created', value: 'created_at', width: '25%' },
  { title: 'Runs', value: 'runs', align: 'center', width: '10%' },
  { title: '', value: 'actions', align: 'right', width: '5%' },
];

const confirmDialog = ref(null);
const processing = ref(false);
const workflowEvents = ref([]);

onMounted(loadWorkflowEvents);

async function createNewWorkflow(workflowEvent) {
  processing.value = true;

  const resp = await Api.organization.workflow.event.create(workflowEvent);
  processing.value = false;
  if (resp.status !== 201) return;

  router.push({ name: 'WorkflowEventShow', params: { id: resp.data.id } });
}

async function loadWorkflowEvents() {
  processing.value = true;

  const resp = await Api.organization.workflow.event.index({});
  processing.value = false;
  if (resp.status !== 200) return;

  workflowEvents.value = resp.data;
}

async function deleteAutomation(id) {
  const confirm = await confirmDialog.value.confirmWithText(
    'Are you sure you want to delete this workflow?',
  );
  if (!confirm) return;

  const resp = await Api.organization.workflow.event.destroy(id);
  if (resp.status !== 202) return;

  eventBus.chime('Workflow deleted');
  void loadWorkflowEvents();
}

function viewWorkflow(_event, { item: workflow }) {
  router.push({ name: 'WorkflowEventShow', params: { id: workflow.id } });
}
</script>

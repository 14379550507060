<template>
  <v-container
    class="px-0 py-0 bg-super-light-blue"
    fluid
  >
    <PageTitle :title="terms.groups" />

    <v-toolbar
      class="mb-3 bb-1"
      flat
    >
      <v-row class="d-flex align-center px-4">
        <v-col class="d-flex align-center justify-start">
          <v-text-field
            v-model="query"
            @keyup.enter="load"
            id="search_link"
            :aria-label="$t('Filter results by name')"
            :placeholder="$t('Filter results by name')"
            class="mxw-340"
            color="primary"
            density="compact"
            prepend-inner-icon="search"
            variant="filled"
            hide-details
            tracked
          />
          <v-btn
            @click="load"
            :loading="processing"
            color="primary"
            prepend-icon="search"
            variant="flat"
            tile
          >
            {{ $t('Search') }}
          </v-btn>
          <template v-if="query">
            <v-btn
              @click="resetQuery"
              color="primary"
              variant="text"
            >
              {{ $t('Clear') }}
            </v-btn>
          </template>
        </v-col>

        <v-col class="d-flex align-center justify-end">
          <v-btn
            v-if="$store.state.profile.org_groups_create"
            @click="$refs.newGroupDialog.open()"
            color="primary"
            data-cy="new-family"
            elevation="0"
            prepend-icon="add"
            variant="flat"
          >
            {{ $t(`New ${terms.group.toLowerCase()}`) }}
          </v-btn>
        </v-col>
      </v-row>
    </v-toolbar>

    <v-container
      class="px-3 py-0"
      fluid
    >
      <v-card
        class="bb-1 pa-0"
        border
        flat
        tile
      >
        <v-card-text>
          <v-row>
            <TableHeader
              cols="3"
              title="Name"
            />
            <TableHeader
              cols="3"
              title="Email"
            />
            <TableHeader
              cols="2"
              title="Phone"
            />
            <TableHeader
              cols="3"
              title="Location"
            />
            <TableHeader
              cols="1"
              title="Created"
            />
          </v-row>
        </v-card-text>
      </v-card>

      <GroupRow
        v-for="group in groups"
        :key="group.id"
        :group="group"
      />

      <NullState v-if="groups.length == 0" />

      <v-pagination
        v-show="pages > 1"
        v-model="page"
        :length="pages"
        :total-visible="8"
        class="mt-4"
      />

      <VerticalSpacer />
    </v-container>

    <ResourceDialog
      @save="create"
      ref="newGroupDialog"
      :fields="newGroupFields"
      :processing="processing"
      title="New family"
      close-on-save
    />
  </v-container>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import GroupRow from '@/specialist/components/group/GroupRow.vue';
import NullState from '@/shared/components/NullState.vue';
import PageTitle from '@/shared/components/PageTitle.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import VerticalSpacer from '@/shared/components/VerticalSpacer.vue';

import useEventBus from '@/shared/composables/useEventBus';
import { useRoute, useRouter } from 'vue-router';
import useRouterHelper from '@/shared/composables/useRouterHelper';
import useTerms from '@/shared/composables/useTerms';

const eventBus = useEventBus();
const route = useRoute();
const router = useRouter();
const { terms } = useTerms();
const { updateQuery } = useRouterHelper();

const newGroupFields = [
  { text: 'Parent/guardian first name', value: 'primary_first_name' },
  { text: 'Parent/guardian last name', value: 'primary_last_name' },
];
const groups = ref([]);
const page = ref(1);
const pages = ref(1);
const processing = ref(false);
const query = ref(route.query.query);

watch(() => page.value, load);

onMounted(load);

function create(newVal) {
  processing.value = true;
  const name = [newVal.primary_first_name, newVal.primary_last_name].join(' ');
  Api.organization.group.create(
    { ...newVal, name },
    (resp) => {
      router.push({ name: 'GroupShow', params: { groupId: resp.data.id } });
      processing.value = false;
    },
    (err) => {
      eventBus.error(err);
      processing.value = false;
    },
  );
}

async function load() {
  await updateQuery({ query: query.value });
  processing.value = true;
  const resp = await Api.organization.group.index({
    query: query.value,
    order: 'created',
    page: page.value,
  });
  pages.value = parseInt(resp.headers['x-page-count'] || 0, 10);
  groups.value = resp.data;
  processing.value = false;
}

function resetQuery() {
  query.value = null;
  void load();
}
</script>

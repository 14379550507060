<template>
  <v-card
    class="mb-4"
    border
    flat
    tile
  >
    <v-expansion-panels
      v-model="attestationExpanded"
      flat
      tile
    >
      <v-expansion-panel>
        <v-expansion-panel-title>
          <v-card-title class="fs-22 fw-600 py-2">
            {{ $t(title) }}
          </v-card-title>
        </v-expansion-panel-title>

        <v-expansion-panel-text>
          <v-divider />
          <v-card-text>
            <AttestationRecord :revision="attestationRevision" />
            <v-row class="fs-14">
              <v-col>
                <p class="mb-0">
                  {{
                    [attestationRevision.author_name, attestationRevision.author_email].join(', ')
                  }}
                </p>
                <p class="mb-0">
                  <LongDateTime
                    :date="attestationRevision.created_at"
                    prefix="Signed "
                  />
                </p>
                <p class="mb-0">
                  {{ `${$t('ID')} #${attestationRevision.id}` }}
                </p>
              </v-col>
            </v-row>
          </v-card-text>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script setup>
import AttestationRecord from '@/shared/components/form/AttestationRecord.vue';
import LongDateTime from '@/shared/components/LongDateTime.vue';

defineProps({
  attestationRevision: {
    type: Object,
    required: true,
  },
  title: {
    type: String,
    default: 'Attestation',
  },
});

const attestationExpanded = ref(false);
</script>

import Api from '@/specialist/services/bright_finder';
import _ from 'lodash';
import { useRoute } from 'vue-router';
import useRouterHelper from '@/shared/composables/useRouterHelper';

export default function useCustomViews(
  loadOwners,
  customViewOwnerFilterField,
  customViewOwnerIdField,
  resourceApi,
  customViewApi,
  customViewFiltersEditor,
  customViewManageEditor,
  selectedItemReviewerEditor,
  selectedItemTeamEditor,
) {
  const { updateQuery } = useRouterHelper();
  const route = useRoute();
  const allowedProperties = ref([]);
  const customViews = ref([]);
  const headers = ref([]);
  const items = ref([]);
  const labels = ref([]);
  const members = ref([]);
  const page = ref(route.query.page || 1);
  const processing = ref(false);
  const selectedCustomView = ref(null);
  const selectedItems = ref([]);
  const selectedLabels = ref([]);
  const selectedReviewers = ref([]);
  const selectedStages = ref([]);
  const selectedStatuses = ref([]);
  const selectedTeams = ref([]);
  const customViewOwners = ref([]);
  const selectedCustomViewOwner = ref(null);
  const specialists = ref([]);
  const teams = ref([]);
  const totalItemCount = ref(0);
  const workflowColumns = ref([]);

  async function loadCustomViews() {
    const filterField = `filter[${customViewOwnerFilterField}]`;
    const params = {
      [filterField]: selectedCustomViewOwner.value.id,
    };
    const { data } = await customViewApi.index(params);
    customViews.value = data.data;
    allowedProperties.value = data.meta.allowed_values.columns;
    workflowColumns.value = data.meta.allowed_values.workflow_columns;
    selectedCustomView.value = data.data[0];
  }

  async function resetPage() {
    page.value = 1;
    await addPageParams(1);
  }

  async function addPageParams(page) {
    const params = {
      page,
      customViewOwnerId: selectedCustomViewOwner.value.id,
    };

    if (currentPage.value !== page) await updateQuery(params);
  }

  function getLabels(record) {
    const labelIds = record?.label_ids || [];
    if (labelIds.length === 0) return [];

    return labels.value?.filter((label) => labelIds.includes(label.id)) || [];
  }

  // Reviewer column function
  function getReviewerName(record) {
    const reviewerId = record?.reviewer_id || null;
    if (!reviewerId) return '';

    const reviewer = specialists.value.find((reviewer) => reviewer.id === reviewerId);

    return reviewer?.name || '';
  }

  // Stage column function
  function getStageName(record) {
    const stageId = record?.stage_id || null;
    if (!stageId) return '';

    const stage = stages.value.find((stage) => stage.id === stageId);

    return stage?.title || '';
  }

  // Team column function
  function getTeamName(record) {
    const teamId = record?.team_id || null;
    if (!teamId) return '';

    const team = teams.value.find((team) => team.id === teamId);

    return team?.name || '';
  }

  function handleCustomViewFiltersEdit() {
    customViewFiltersEditor.value.open(selectedCustomView.value.attributes.filters);
  }

  async function handleCustomViewFiltersUpdate(newValue) {
    await handleCustomViewUpdate({
      id: selectedCustomView.value.id,
      attributes: {
        filters: newValue,
      },
    });
    customViewFiltersEditor.value.close();
  }

  async function handleCustomViewChange(newValue) {
    selectedLabels.value = newValue.attributes.label_ids;
    selectedReviewers.value = newValue.attributes.reviewer_ids;
    selectedStages.value = newValue.attributes.stage_ids;
    selectedStatuses.value = newValue.attributes.statuses;
    selectedTeams.value = newValue.attributes.team_ids;

    selectedCustomView.value = newValue;
    await loadLabels();
    await loadResults();
  }

  async function handleCustomViewUpdate(newValue) {
    processing.value = true;

    const customViewToUpdate = customViews.value.find(
      (customView) => customView.id === newValue.id,
    );

    const updatedAttributes = {
      ...customViewToUpdate.attributes,
      ...newValue.attributes,
    };
    const response = await customViewApi.update(
      customViewToUpdate.id,
      updatedAttributes,
      customViewToUpdate?.relationships,
    );

    if (response?.status !== 200) {
      processing.value = false;
      return false;
    }

    customViewToUpdate.attributes = response.data.data.attributes;

    await loadResults();

    return true;
  }

  async function handleFilterChange() {
    await resetPage(1);
    await handleCustomViewUpdate({
      id: selectedCustomView.value.id,
      attributes: {
        label_ids: selectedLabels.value,
        reviewer_ids: selectedReviewers.value,
        stage_ids: selectedStages.value,
        statuses: selectedStatuses.value,
        team_ids: selectedTeams.value,
      },
    });
  }

  async function handlePageChange(newValue) {
    page.value = newValue;
    await addPageParams(newValue);
    await loadResults();
  }

  async function handleOwnerChange() {
    processing.value = true;

    customViews.value = [];
    allowedProperties.value = [];
    await updateQuery({ customViewOwnerId: selectedCustomViewOwner.value.id });

    await loadCustomViews();
    await loadLabels();
    await handlePageChange(1);
  }

  async function handleModelValueUpdate(event) {
    selectedItems.value = event;
  }

  async function handleCustomViewSortsUpdate(newValue) {
    const processedSorts = processSorts(newValue);

    await handleCustomViewUpdate({
      id: selectedCustomView.value.id,
      attributes: {
        ...processedSorts,
      },
    });
  }

  function handleSelectedCustomViewUpdate(event) {
    selectedLabels.value = selectedCustomView.value.attributes.label_ids || [];
    selectedReviewers.value = selectedCustomView.value.attributes.reviewer_ids || [];
    selectedStages.value = selectedCustomView.value.attributes.stage_ids || [];
    selectedStatuses.value = selectedCustomView.value.attributes.statuses || [];
    selectedTeams.value = selectedCustomView.value.attributes.team_ids || [];

    if (event) void loadResults();
  }

  function handleSelectedItemReviewerEdit() {
    selectedItemReviewerEditor.value.open();
  }

  async function handleSelectedItemReviewerUpdate(newValue) {
    processing.value = true;

    await Promise.all(
      selectedItems.value.map(async (selectedItem) => {
        const response = await resourceApi.update(selectedItem.id, {
          reviewer_id: newValue.value,
        });

        const index = items.value.findIndex((item) => item.id === selectedItem.id);
        items.value[index] = response.data;
      }),
    );

    selectedItems.value = [];
    selectedItemReviewerEditor.value.close();

    await loadResults();
  }

  function handleSelectedItemTeamEdit() {
    selectedItemTeamEditor.value.open();
  }

  async function handleSelectedItemTeamUpdate(newValue) {
    processing.value = true;

    await Promise.all(
      selectedItems.value.map(async (selectedItem) => {
        const response = await resourceApi.update(selectedItem.id, {
          team_id: newValue.value,
        });

        const index = items.value.findIndex((item) => item.id === selectedItem.id);
        items.value[index] = response.data;
      }),
    );

    selectedItems.value = [];
    selectedItemTeamEditor.value.close();

    await loadResults();
  }

  async function load() {
    items.value.splice(0);
    processing.value = true;

    let unorderedOwners = await loadOwners();

    await loadSpecialists();

    customViewOwners.value = _.orderBy(
      unorderedOwners,
      [(schema) => schema.name.toLowerCase()],
      ['asc'],
    );

    selectedCustomViewOwner.value =
      customViewOwners.value.find((schema) => schema.id === selectedCustomViewOwnerId.value) ||
      customViewOwners.value[0];

    await loadCustomViews();
    await loadLabels();
    await loadTeams();

    processing.value = false;
  }

  function loadMembers(ids) {
    Api.organization.member.index(
      { ids },
      (response) => (members.value = members.value.concat(response?.data || [])),
    );
  }

  async function loadLabels() {
    const response = await Api.organization.label.index({
      [customViewOwnerIdField]: selectedCustomViewOwner.value?.id,
    });

    labels.value = response?.data || [];
  }

  async function loadResults() {
    processing.value = true;

    const { data } = await Api.organization.customViewResult.index({
      customViewId: selectedCustomView.value.id,
      page: currentPage.value,
    });

    totalItemCount.value = data.meta.total_count;

    headers.value = _.map(data.meta.all_columns, (column) => ({
      title: column.alias_name,
      key: column.key,
      sortable: column.sortable === undefined ? true : column.sortable,
    }));

    items.value = _.map(data.data, (item) => ({
      ...item.attributes,
      ...item.relationships,
      id: item.id,
    }));

    // Load submitters and reviewers
    const memberIds = items.value
      .map((form) => [form.member_id, form.reviewer_id])
      .flat()
      .filter(Boolean)
      .filter((id) => !members.value.find((member) => member.id === id));

    if (memberIds.length > 0) loadMembers(memberIds);

    processing.value = false;
  }

  async function loadSpecialists() {
    const response = await Api.organization.member.index({ is_specialist: true });
    specialists.value = response?.data || [];
    members.value = members.value.concat(response?.data || []);
  }

  async function loadTeams() {
    const response = await Api.organization.team.index();
    teams.value = response?.data || [];
  }

  function processSorts(newValue) {
    // when newValue returns an empty array, it means the user has removed all sorts
    if (newValue.length < 1) return { sorts: [] };

    const incomingSort = newValue[0];
    let existingColumns = selectedCustomView.value.attributes.columns;
    let existingSorts = selectedCustomView.value.attributes.sorts;
    let incomingDirection = `${incomingSort.order}ending`;

    const columnValue =
      existingColumns.find((column) => column.key === incomingSort.key) ||
      workflowColumns.value.find((column) => column.key === incomingSort.key);

    const sortValue = existingSorts.find(
      (sort) =>
        sort.data_type === columnValue.data_type &&
        sort.property_name === columnValue.property_name,
    );

    if (sortValue) {
      sortValue.direction = incomingDirection;
      existingSorts = [sortValue];
    } else {
      const newSort = {
        key: columnValue.key,
        direction: incomingDirection,
        data_type: columnValue.data_type,
        property_name: columnValue.property_name,
      };
      existingSorts = [newSort];
    }

    return { sorts: existingSorts };
  }

  async function removeReviewer(form, isTeam) {
    processing.value = true;
    const params = {
      team_id: isTeam ? null : form.team_id,
      reviewer_id: isTeam ? form.reviewer_id : null,
    };

    await resourceApi.update(form.id, params);
    await loadResults();
  }

  function updateEditedLabels(newValue) {
    const index = items.value.findIndex((item) => item.id === newValue.id);
    items.value[index].label_ids = newValue.relationships.labels.data.map((label) => label.id);
  }

  const currentPage = computed(() => (route.query.page ? Number(route.query.page) : 1));
  const displayLabels = computed(() => labels.value.length > 0);
  const displayStages = computed(() => {
    return (
      selectedCustomViewOwner.value?.enable_stages &&
      selectedCustomViewOwner.value.stages.length > 0
    );
  });
  const sortBy = computed(() => {
    return selectedCustomView.value?.attributes.sorts.map((sort) => {
      return {
        key: sort.key || sort.property_name,
        order: sort.direction === 'ascending' ? 'asc' : 'desc',
      };
    });
  });
  const stages = computed(() => {
    return selectedCustomViewOwner?.value.stages || [];
  });
  const selectedCustomViewOwnerId = computed(() => route.query.customViewOwnerId || null);

  return {
    addPageParams,
    allowedProperties,
    customViewFiltersEditor,
    customViewManageEditor,
    currentPage,
    customViewOwners,
    customViews,
    displayLabels,
    displayStages,
    getLabels,
    getReviewerName,
    getStageName,
    getTeamName,
    handleCustomViewFiltersEdit,
    handleCustomViewFiltersUpdate,
    handleCustomViewChange,
    handleCustomViewUpdate,
    handleFilterChange,
    handlePageChange,
    handleOwnerChange,
    handleModelValueUpdate,
    handleCustomViewSortsUpdate,
    handleSelectedCustomViewUpdate,
    handleSelectedItemReviewerEdit,
    handleSelectedItemReviewerUpdate,
    handleSelectedItemTeamEdit,
    handleSelectedItemTeamUpdate,
    headers,
    items,
    load,
    labels,
    loadCustomViews,
    loadLabels,
    loadMembers,
    loadResults,
    loadSpecialists,
    loadTeams,
    members,
    page,
    processing,
    removeReviewer,
    resetPage,
    selectedCustomView,
    selectedItemReviewerEditor,
    selectedItemTeamEditor,
    selectedItems,
    selectedLabels,
    selectedReviewers,
    selectedStages,
    selectedStatuses,
    selectedTeams,
    selectedCustomViewOwner,
    selectedCustomViewOwnerId,
    sortBy,
    specialists,
    stages,
    teams,
    totalItemCount,
    workflowColumns,
    updateEditedLabels,
  };
}

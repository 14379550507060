<template>
  <div v-if="loaded">
    <BreadcrumbTitle
      :back-name="subsidyProgram.name"
      name="Funding"
    />

    <v-tabs
      v-model="tab"
      bg-color="transparent"
      class="mb-2"
      grow
    >
      <v-tab value="funding-sources"> Subsidy Funding Sources </v-tab>
      <v-tab value="provider-program-requirements"> Provider program requirements </v-tab>
      <v-tab value="awards"> Awards </v-tab>
    </v-tabs>

    <v-window v-model="tab">
      <div class="my-4" />
      <v-window-item value="funding-sources">
        <div
          v-for="source in fundingSources"
          :key="source.id"
          class="mb-4"
        >
          <FundingSourceCard
            @destroy="loadFundingSources"
            @move="reorder(source, $event)"
            :display-index="getDisplayPositionIndex(source)"
            :schemas="schemas"
            :source="source"
            :total-count="fundingSources.length"
            ordered
          />
        </div>

        <v-btn
          @click="draftFundingSource"
          class="mt-4"
          color="primary"
          prepend-icon="add"
        >
          {{ $t('New funding source') }}
        </v-btn>
      </v-window-item>

      <v-window-item value="provider-program-requirements">
        <h1>New content coming soon!</h1>
      </v-window-item>

      <v-window-item value="awards">
        <v-card
          class="mb-8"
          border
          flat
          tile
        >
          <v-card-text>
            <v-row>
              <LabeledSwitch
                v-model="subsidyProgram.enable_awards"
                @input="changed = true"
                subtitle="Automatically calculate family-level and child-level recommended award amounts."
                title="Award calculation"
              />
            </v-row>

            <template v-if="subsidyProgram.enable_awards">
              <v-row>
                <LabeledTextarea
                  v-model="subsidyProgram.child_award_formula_description"
                  @input="changed = true"
                  class="mb-4"
                  message="Award calculation description"
                />
                <LabeledSimpleSelect
                  v-model="subsidyProgram.child_award_payment_period"
                  :items="['Monthly']"
                  message="Payment period"
                />
                <LabeledFormulaField
                  v-model="subsidyProgram.tuition_payment_formula"
                  @input="changed = true"
                  message="Tuition payment formula"
                />
                <LabeledFormulaField
                  v-model="subsidyProgram.start_date_formula"
                  @input="changed = true"
                  message="Award start date formula"
                />
                <LabeledFormulaField
                  v-model="subsidyProgram.finish_date_formula"
                  @input="changed = true"
                  message="Award finish date formula"
                />
                <LabeledFormulaField
                  v-model="subsidyProgram.child_award_payment_formula"
                  @input="changed = true"
                  message="Award payment formula"
                />
                <LabeledFormulaField
                  v-model="subsidyProgram.child_award_text_formula"
                  @input="changed = true"
                  message="Award payment description formula"
                />
              </v-row>

              <v-divider class="my-6" />

              <v-row>
                <LabeledSwitch
                  v-model="subsidyProgram.enable_copay"
                  @input="changed = true"
                  subtitle="Enable calculation of parent co-payment."
                  title="Copay"
                />
                <LabeledFormulaField
                  v-if="subsidyProgram.enable_copay"
                  v-model="subsidyProgram.child_copay_payment_formula"
                  @input="changed = true"
                />
              </v-row>

              <v-divider class="my-6" />

              <v-row>
                <LabeledSwitch
                  v-model="subsidyProgram.enable_max_group_award"
                  @input="changed = true"
                  subtitle="Enable max family total award."
                  title="Max family award"
                />
                <LabeledFormulaField
                  v-if="subsidyProgram.enable_max_group_award"
                  v-model="subsidyProgram.max_group_award_formula"
                  @input="changed = true"
                />
              </v-row>

              <v-divider class="my-6" />

              <v-row>
                <LabeledSwitch
                  v-model="subsidyProgram.enable_max_child_award"
                  @input="changed = true"
                  subtitle="Enable max child total award."
                  title="Max child award"
                />
                <LabeledFormulaField
                  v-if="subsidyProgram.enable_max_child_award"
                  v-model="subsidyProgram.max_child_award_formula"
                  @input="changed = true"
                />
              </v-row>
            </template>
          </v-card-text>
        </v-card>
      </v-window-item>
    </v-window>

    <ResourceDialog
      @save="createFundingSource"
      :fields="fundingSourceFields"
      :processing="processing"
      action="new"
      save-button-text="Create"
      title="Create funding source"
    />

    <SaveBar
      @cancel="cancelChange"
      @save="save"
      :model-value="changed"
      :processing="processing"
    />
  </div>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import BreadcrumbTitle from '@/specialist/components/navigation/BreadcrumbTitle.vue';
import FundingSourceCard from '@/admin/components/FundingSourceCard.vue';
import LabeledFormulaField from '@/shared/components/form/LabeledFormulaField.vue';
import LabeledSwitch from '@/shared/components/form/LabeledSwitch.vue';
import LabeledSimpleSelect from '@/shared/components/form/LabeledSimpleSelect.vue';
import LabeledTextarea from '@/shared/components/form/LabeledTextarea.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import useRouterHelper from '@/shared/composables/useRouterHelper';
import SaveBar from '@/shared/components/form/SaveBar.vue';
import useSubsidy from '@/shared/composables/useSubsidy';
import useEventBus from '@/shared/composables/useEventBus';
import { useRoute } from 'vue-router';

const eventBus = useEventBus();
const { updateQuery } = useRouterHelper();
const route = useRoute();

const changed = ref(false);
const funds = ref([]);
const fundingSources = ref([]);
const loaded = ref(false);
const processing = ref(false);
const subsidyProgram = ref(null);
const subsidyProgramId = ref(null);
const tab = ref(route.query.tab);

const { groupSchema, childSchema, providerSchema, loadSchemas } = useSubsidy({
  subsidyProgram: subsidyProgram,
});

const fundingSourceFields = computed(() => [
  { required: true, text: 'Name', value: 'name' },
  {
    required: true,
    text: 'Fund',
    value: 'fund_id',
    items: funds.value,
    itemText: 'name',
    itemValue: 'id',
  },
]);

const schemas = computed(() =>
  [groupSchema.value, childSchema.value, providerSchema.value].filter((schema) => !!schema),
);

onMounted(async () => {
  subsidyProgramId.value = route.params.subsidyProgramId;
  if (!tab.value) tab.value = 'funding-sources';

  await load();
});

async function cancelChange() {
  changed.value = false;
  await loadSubsidyProgram();
}

async function load() {
  loaded.value = false;

  await loadFundingSources();
  await loadSubsidyProgram();
  await loadFunds();

  loaded.value = true;
}

async function createFundingSource(newVal) {
  processing.value = true;
  const { data } = await Api.organization.funding_source.create({
    ...newVal,
    owner_id: route.params.subsidyProgramId,
    owner_type: 'SubsidyProgram',
  });
  processing.value = false;
  await updateQuery({ action: null });
  fundingSources.value.push(data);
}

async function draftFundingSource() {
  await updateQuery({ action: 'new' });
}

function getDisplayPositionIndex(source) {
  if (source.display_order !== null) return source.display_order - 1;

  return fundingSources.value.indexOf(source);
}

async function loadFunds() {
  const resp = await Api.organization.fund.index({});

  if (resp.data) {
    funds.value = resp.data;
  }
}

async function loadFundingSources() {
  const params = { owner_id: route.params.subsidyProgramId };
  const { data } = await Api.organization.funding_source.index(params);
  fundingSources.value = data;
}

async function loadSubsidyProgram() {
  const resp = await Api.organization.subsidy_program.get(subsidyProgramId.value);

  if (resp.data) {
    subsidyProgram.value = resp.data;
    loadSchemas();
  }
}

async function reorder(source, displayOrderChange) {
  const params = {
    display_order_was: source.display_order,
    display_order: getDisplayPositionIndex(source) + 1 + displayOrderChange,
  };

  await Api.organization.funding_source.reorder(source.id, params);
  await loadFundingSources();
}

function save() {
  processing.value = true;
  Api.organization.subsidy_program.update(
    route.params.subsidyProgramId,
    subsidyProgram.value,
    (response) => {
      processing.value = false;
      subsidyProgram.value = response.data;
      changed.value = false;
    },
    (error) => {
      processing.value = false;
      eventBus.chime(error.response.data.errors[0]);
    },
  );
}
</script>

<template>
  <template v-if="store.state.navigation">
    <v-list
      class="v-list--shaped"
      color="primary"
    >
      <NavSection
        v-for="(section, index) in store.state.navigation"
        :key="index"
        :section="section"
      />
    </v-list>
  </template>

  <template v-else>
    <template v-if="route.path.startsWith('/admin/')">
      <v-list
        key="adminNavigation"
        class="v-list--shaped"
        color="primary"
      >
        <NavItem
          icon="settings"
          route="AdminSettings"
          title="Organization"
        />

        <NavItemGroup
          :items="portalLinks"
          icon="web"
          title="Portals"
        />

        <NavItemGroup
          :items="programLinks"
          icon="workspaces"
          title="Programs"
        />

        <NavItem
          active-route-root="/admin/api_keys"
          icon="api"
          route="ApiKeyIndex"
          title="API Keys"
        />

        <NavItemGroup
          :items="dataLinks"
          group="/data"
          icon="table_chart"
          title="Schemas"
        />

        <NavItem
          active-route-root="/admin/message_templates"
          icon="email"
          route="MessageTemplateIndex"
          title="Messages"
        />

        <NavItem
          active-route-root="/admin/templates"
          icon="apps"
          route="SiteTemplateIndex"
          title="Templates"
        />

        <NavItem
          active-route-root="/admin/content_templates"
          icon="widgets"
          route="ContentTemplateIndex"
          title="Content"
        />

        <NavItem
          active-route-root="/admin/job_configs"
          icon="settings"
          route="JobConfigIndex"
          title="Jobs"
        />

        <NavItem
          active-route-root="admin/summary_configurations"
          icon="pie_chart"
          route="SummaryConfigurationIndex"
          title="Summaries"
        />

        <NavItem
          active-route-root="admin/workflow_events"
          icon="auto_mode"
          route="WorkflowEventIndex"
          title="Automations"
        />
      </v-list>
    </template>

    <template v-else>
      <v-list
        key="standardNavigation"
        class="v-list--shaped"
        color="primary"
        open-strategy="single"
      >
        <GlobalQuery />

        <v-divider class="my-3" />

        <template v-if="store.state.profile.org_tasks_access">
          <NavItem
            data-cy="tasks_tab"
            icon="playlist_add"
            route="TaskIndex"
            title="Tasks"
          />
        </template>

        <template v-if="anySubsidiesEnabled">
          <template v-if="allSubsidiesEnabled">
            <NavItemGroup
              :items="applicationLinks"
              group="/applications"
              icon="inbox"
              title="Applications"
            />
          </template>

          <template
            v-else-if="
              store.state.profile.org_subsidies_access || store.state.profile.org_subsidies_agent
            "
          >
            <NavItem
              :title="terms.subsidies"
              data-cy="subsidies_tab"
              icon="inbox"
              route="SubsidyIndex"
            />
          </template>

          <template v-else>
            <NavItem
              :title="terms.family_subsidies"
              data-cy="family_subsidies_tab"
              icon="inbox"
              route="FamilySubsidyIndex"
            />
          </template>
        </template>

        <template v-if="enrollmentsEnabled">
          <NavItem
            data-cy="enrollments_tab"
            icon="chair_alt"
            route="EnrollmentIndex"
            title="Enrollments"
          />
        </template>

        <template v-if="store.state.profile.org_learning_access">
          <NavItem
            data-cy="learning_tab"
            icon="menu_book"
            route="LearningIndex"
            title="Learning"
          />
        </template>

        <template
          v-if="store.state.profile.org_groups_access || store.state.profile.org_subsidies_agent"
        >
          <NavItem
            :title="terms.groups"
            data-cy="families_tab"
            icon="people_alt"
            route="GroupIndex"
          />
        </template>

        <template v-if="store.state.profile.org_providers_access">
          <NavItemGroup
            :items="providerLinks"
            data-cy="providers_tab"
            group="/providers"
            icon="storefront"
            title="Providers"
          />
        </template>

        <template
          v-if="store.state.profile.org_forms_access || store.state.profile.org_forms_admin"
        >
          <NavItem
            :title="terms.forms"
            data-cy="forms_tab"
            icon="feed"
            route="FormIndex"
          />
        </template>

        <template
          v-if="store.state.pages.Messaging.enabled && store.state.profile.org_messaging_access"
        >
          <NavItem
            data-cy="messaging_tab"
            icon="email"
            route="MessageIndex"
            title="Messaging"
          />
        </template>

        <template v-if="store.state.pages.Grants.enabled && store.state.profile.org_grants_access">
          <NavItem
            :title="store.state.pages.Grants.title || 'Grants'"
            data-cy="grants_tab"
            icon="account_balance"
            route="GrantIndex"
          />
        </template>

        <template v-if="paymentsEnabled">
          <NavItem
            data-cy="payments_tab"
            icon="receipt"
            route="PaymentIndex"
            title="Payments"
          />
        </template>

        <template v-if="assessmentsEnabled">
          <NavItem
            :title="store.state.pages.Assessments.title || 'Assessments'"
            data-cy="assessments_tab"
            icon="star_rate"
            route="AssessmentIndex"
          />
        </template>

        <template
          v-if="store.state.pages.Surveys.enabled && store.state.profile.org_surveys_access"
        >
          <NavItem
            data-cy="surveys_task"
            icon="poll"
            route="SurveyIndex"
            title="Surveys"
          />
        </template>

        <template v-if="activitiesEnabled">
          <NavItem
            :title="terms.revisions"
            data-cy="activities_tab"
            icon="history"
            route="RevisionIndex"
          />
        </template>

        <template
          v-if="store.state.pages.Analytics.enabled && store.state.profile.org_reporting_admin"
        >
          <NavItem
            data-cy="analytics_tab"
            icon="trending_up"
            route="AnalyticsIndex"
            title="Analytics"
          />
        </template>

        <!-- [CARE-1430] Only available through URL while in development  -->
        <template v-if="false">
          <NavItem
            data-cy="data_dashboard_tab"
            icon="dashboard"
            route="DataDashboard"
            title="Data Dashboard"
          />
        </template>

        <template v-if="reportsEnabled">
          <NavItem
            data-cy="reports_tab"
            icon="description"
            route="ReportIndex"
            title="Reports"
          />
        </template>

        <template v-if="store.state.profile.org_settings_access">
          <NavItemGroup
            :bottom-divider="false"
            :items="settingsLinks"
            data-cy="settings_tab"
            icon="settings"
            title="Settings"
          />
        </template>
      </v-list>
    </template>
  </template>
</template>

<script setup>
import GlobalQuery from '@/specialist/components/GlobalQuery.vue';
import NavItem from '@/specialist/components/navigation/NavItem.vue';
import NavItemGroup from '@/specialist/components/navigation/NavItemGroup.vue';
import NavSection from '@/specialist/components/navigation/NavSection.vue';
import { useRoute } from 'vue-router';
import store from '@/specialist/store';
import portalLinks from '@/specialist/services/portal_links';
import dataLinks from '@/specialist/services/data-links';
import programLinks from '@/specialist/services/program-links';
import applicationLinks from '@/specialist/services/application-links';
import useTerms from '@/shared/composables/useTerms';

const { terms } = useTerms();
const route = useRoute();

const activitiesEnabled = computed(() => {
  return (
    store.state.pages.Activities &&
    store.state.pages.Activities.enabled &&
    store.state.profile.org_settings_access
  );
});

const assessmentsEnabled = computed(() => {
  return store.state.pages.Assessments.enabled && store.state.profile.org_assessments_access;
});

const enrollmentsEnabled = computed(() => {
  return store.state.profile.org_enrollments_access || store.state.profile.org_enrollments_admin;
});

const reportsEnabled = computed(() => {
  return store.state.profile.org_reporting_access;
});

const paymentsEnabled = computed(() => {
  return (
    store.state.pages.Payments &&
    store.state.pages.Payments.enabled &&
    store.state.profile.org_payments_access
  );
});

const allSubsidiesEnabled = computed(() => {
  return (
    (store.state.profile.org_subsidies_access || store.state.profile.org_subsidies_agent) &&
    store.state.profile.org_family_subsidies_access
  );
});

const anySubsidiesEnabled = computed(() => {
  return (
    store.state.profile.org_subsidies_access ||
    store.state.profile.org_subsidies_agent ||
    store.state.profile.org_family_subsidies_access
  );
});

const providerLinks = computed(() => {
  let links = [
    {
      icon: 'search',
      title: 'Search',
      route: 'ProviderSearch',
    },
    {
      icon: 'place',
      title: 'Locations',
      route: 'ProviderIndex',
    },
  ];

  if (store.state.profile.org_business_access) {
    links = links.concat([
      {
        icon: 'work_outline',
        title: 'Businesses',
        route: 'BusinessIndex',
      },
      {
        icon: 'badge',
        title: 'Staff',
        route: 'StaffIndex',
      },
    ]);
  }

  return links;
});

const settingsLinks = computed(() => {
  const links = [
    {
      icon: 'group',
      title: 'Members',
      route: 'SpecialistIndex',
    },
    {
      icon: 'groups',
      title: 'Teams',
      route: 'TeamIndex',
    },
    {
      icon: 'task',
      title: 'Task types',
      route: 'TaskTypeIndex',
    },
    {
      icon: 'event_available',
      title: 'Activity types',
      route: 'ActivityTypeIndex',
    },
  ];

  if (store.state.profile.org_billing_edit) {
    links.push({
      icon: 'payments',
      title: 'Funding',
      route: 'BankAccountShow',
    });
  }

  return links;
});
</script>

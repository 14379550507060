<template>
  <v-card
    id="forms"
    class="mb-4"
    data-cy="forms-card"
    border
    tile
  >
    <v-card-title class="tab-title">
      <v-row
        class="d-flex align-center"
        dense
      >
        <v-col
          class="d-flex align-center"
          cols="12"
          sm="6"
        >
          {{ $t('Forms') }}
        </v-col>

        <v-col
          class="d-flex align-center justify-end"
          cols="12"
          sm="6"
        >
          <v-text-field
            v-model="formQuery"
            @update:model-value="debouncedLoadForms()"
            class="mxw-250"
            density="compact"
            prepend-inner-icon="search"
            variant="solo-filled"
            flat
            hide-details
            rounded
          />

          <v-btn
            v-if="displayNewFormButton"
            @click="newFormDialog.open()"
            class="ms-2"
            color="primary"
            icon="add"
            variant="text"
          />
        </v-col>
      </v-row>
    </v-card-title>

    <v-divider />

    <v-card-text>
      <FormCard
        v-for="form in forms"
        @destroy="destroy(form)"
        @status="setStatus(form, $event)"
        :key="form.id"
        :form="form"
        :programs="programs"
        :provider="provider"
        class="mb-4"
      />

      <v-row v-if="forms.length === 0">
        <v-col
          class="fs-16"
          data-cy="forms-empty"
        >
          {{ $t('No forms found') }}
        </v-col>
      </v-row>

      <v-pagination
        v-show="pages > 1"
        v-model="page"
        @update:model-value="load"
        :length="pages"
        :total-visible="8"
        class="mt-4"
        rounded
      />
    </v-card-text>

    <ResourceDialog
      @save="createForm"
      ref="newFormDialog"
      :fields="[
        {
          text: 'Type',
          value: 'schemaId',
          itemText: 'name',
          itemValue: 'id',
          items: Object.values($store.state.schemas).filter((schema) => schema.data_type == 'Form'),
        },
      ]"
      :processing="processing"
      save-button-text="Create"
      title="Create new form"
    />
  </v-card>
</template>

<script setup>
import _ from 'lodash';
import Api from '@/specialist/services/bright_finder';
import FormCard from '@/specialist/components/forms/FormCard.vue';
import { useRoute, useRouter } from 'vue-router';
import { useStore } from 'vuex';

const route = useRoute();
const router = useRouter();
const store = useStore();

const props = defineProps({
  provider: {
    type: Object,
    default: null,
  },
});

const forms = ref([]);
const formQuery = ref(null);
const newFormDialog = ref(null);
const page = ref(1);
const pages = ref(null);
const processing = ref(false);
const programs = ref([]);

watch(
  () => route.params.providerId,
  () => load(),
  { immediate: true },
);

const debouncedLoadForms = _.debounce(() => {
  void loadForms();
}, 500);

const displayNewFormButton = computed(() => {
  return store.state.profile.org_forms_admin || store.state.profile.org_forms_create;
});

async function createForm({ schemaId = null }) {
  processing.value = true;

  const params = {
    provider_id: props.provider.id,
    schema_id: schemaId,
  };

  const response = await Api.organization.form.create(params);

  processing.value = false;
  if (response?.status != 201) return;

  router.push({
    name: 'FormShow',
    params: {
      formId: response?.data.id,
    },
    query: {
      back: 'provider',
    },
  });
}

async function destroy(form) {
  if (!confirm('Are you sure you want to destroy this form?')) return;

  await Api.organization.form.destroy(form.id);
  load();
}

function load() {
  void loadForms();
  void loadPrograms();
}

async function loadForms() {
  const params = {
    page: page.value,
    provider_id: props.provider.id,
  };
  if (formQuery.value) params.query = formQuery.value;
  const response = await Api.organization.form.index(params);
  pages.value = parseInt(response.headers['x-page-count'] || 0, 10);
  forms.value = response.data;
}

async function loadPrograms() {
  const params = { provider_id: props.provider.id };
  const { data } = await Api.public_api.organization.program.index(params);
  programs.value = data;
}

function setStatus(form, newValue) {
  let status;

  if (newValue.title === 'Approve') status = 'Approved';
  if (newValue.title === 'Deny') status = 'Denied';

  void updateForm(form.id, { status });
}

async function updateForm(formId, newValue) {
  await Api.organization.form.update(formId, newValue);
  void load();
}
</script>

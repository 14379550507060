const ALERTABLE_SCHEMAS = ['Assessment', 'Form', 'Group'];
const QUESTIONABLE_SCHEMAS = [
  'Agreement',
  'AttendanceLog',
  'Assessment',
  'BackgroundCheck',
  'Claim',
  'Complaint',
  'Form',
  'License',
  'OperatingLog',
  'Quiz',
  'Review',
  'Survey',
  'Worksheet',
];
const UPLOADABLE_SCHEMAS = [
  'Assessment',
  'Business',
  'Child',
  'Enrollment',
  'Form',
  'FamilySubsidy',
  'Grant',
  'Group',
  'HouseholdMember',
  'Payment',
  'Program',
  'Provider',
  'Review',
  'Worksheet',
];
const SHAREABLE_SCHEMAS = ['Provider'];
const STAGEABLE_SCHEMAS = ['Assessment', 'Form'];

const BASE_ITEMS = [
  {
    title: 'Settings',
    route: 'SchemaShow',
  },
  {
    title: 'Definition',
    route: 'SchemaDefinition',
  },
  {
    title: 'Assignment',
    route: 'SchemaAssignmentConfigurations',
  },
  {
    title: 'Labels',
    route: 'SchemaLabels',
  },
  {
    title: 'Functions',
    route: 'SchemaFunctionIndex',
  },
];

const UPLOADABLE_ITEMS = [
  {
    title: 'Syncs',
    route: 'SchemaUploads',
  },
];

export function getSchemaNavigation(dataType, schemaId) {
  const baseNav = [
    {
      route: 'SchemaIndex',
      icon: 'chevron_left',
      type: 'back',
      title: 'Back',
      query: { data_type: dataType },
    },
  ];
  const extraItems = [];

  if (ALERTABLE_SCHEMAS.includes(dataType)) {
    extraItems.push({
      title: 'Event alerts',
      route: 'SchemaAlerts',
    });
  }

  if (QUESTIONABLE_SCHEMAS.includes(dataType)) {
    extraItems.push({
      title: 'Questions',
      route: 'SchemaQuestions',
    });
  }

  if (STAGEABLE_SCHEMAS.includes(dataType)) {
    extraItems.push({
      title: 'Stages',
      route: 'SchemaStages',
    });
  }

  if (UPLOADABLE_SCHEMAS.includes(dataType)) {
    extraItems.push(...UPLOADABLE_ITEMS);
  }

  if (SHAREABLE_SCHEMAS.includes(dataType)) {
    extraItems.push({
      title: 'Shares',
      route: 'SchemaShares',
    });
  }

  return baseNav.concat({
    type: 'section',
    items: BASE_ITEMS.concat(extraItems).map((item) => {
      return { ...item, params: { schemaId } };
    }),
  });
}

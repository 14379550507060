<template>
  <v-expansion-panels
    v-if="property && itemProperties"
    v-model="open"
    class="bg-transparent"
    flat
    tile
  >
    <v-expansion-panel
      :class="expansionPanelClass"
      :ripple="false"
      expand
    >
      <v-expansion-panel-title disable-icon-rotate>
        <template v-if="props.isChild">
          <SchemaElementTitle
            :field="props.field"
            :property="property"
          />
        </template>

        <template v-else>
          <span class="fs-20 fw-500">{{ $t(title || property.alias) }}</span>
        </template>

        <template #actions>
          <SchemaElementTitleActions
            @change="handleChangeCore"
            :alias="property.alias"
            :field="props.field"
            :parent-field="props.parentField"
            :property="property"
            :removable="props.removable"
            :schema-id="props.schemaId"
          />
        </template>
      </v-expansion-panel-title>

      <v-expansion-panel-text :class="{ 'px-6': props.isChild, 'py-2': true }">
        <template v-if="props.isChild">
          <SchemaPropertyFormFields
            @change:core="handleChangeCore"
            :field="props.field"
            :parent-field="props.parentField"
            :property="property"
            :schema-functions="props.schemaFunctions"
            :schema-id="props.schemaId"
            :schemas="schemas"
          />

          <SchemaPropertyReportingSettingsEditor
            @change:core="handleChangeCore"
            :field="props.field"
            :parent-field="props.parentField"
            :property="property"
            :schema-id="props.schemaId"
          />

          <template v-if="property.type == 'array'">
            <template v-if="property.items.type == 'object'">
              <v-card
                class="mb-8"
                border
                flat
                tile
              >
                <SectionHeader :title="$t('Properties')" />
                <v-card-text>
                  <div class="d-flex align-center mb-4">
                    <v-spacer />
                    <v-btn
                      @click="draftNewSubProperty"
                      class="me-3"
                      color="primary"
                      size="small"
                    >
                      {{ $t('Add property') }}
                    </v-btn>
                  </div>

                  <div
                    v-if="itemPropertyEntries.length == 0"
                    class="mb-4 fs-16 c-red fw-500"
                  >
                    {{ $t('Element does not have any properties.') }}
                  </div>

                  <div
                    v-for="(itemProp, itemPropIndex) in itemPropertyEntries"
                    :key="props.field + ':' + itemProp[0]"
                  >
                    <SchemaElement
                      @change="emit('change')"
                      :definition="{ properties: itemProperties }"
                      :depth="depth + 1"
                      :field="itemProp[0]"
                      :index="itemPropIndex"
                      :parent-field="props.field"
                      :schema-functions="props.schemaFunctions"
                      :schema-id="schemaId"
                      :schemas="schemas"
                      removable
                    />
                  </div>
                </v-card-text>
              </v-card>
            </template>

            <StringEnumEditor
              v-if="property.items.type == 'string'"
              :enums="property.items.enum"
              :field="props.field"
              :parent-field="props.parentField"
              :schema-id="props.schemaId"
              is-item-enum
            />
          </template>

          <StringEnumEditor
            v-if="property.type == 'string'"
            :enums="property.enum"
            :field="props.field"
            :parent-field="props.parentField"
            :schema-id="props.schemaId"
          />
        </template>

        <template v-if="property.type == 'object'">
          <div class="mb-4">
            <v-row
              v-if="entries.length == 0"
              class="fs-16 c-red fw-500 mb-3"
            >
              <v-col class="d-flex justify-center">
                {{ $t('Element does not have any properties.') }}
              </v-col>
            </v-row>
            <div class="bg-white">
              <v-expansion-panels
                class="mb-3 b-1 bc-outlined-gray"
                flat
                tile
              >
                <SchemaElement
                  v-for="(subentry, subentryIndex) in entries"
                  @change="emit('change')"
                  :key="props.field + ':' + subentry[0]"
                  :definition="property"
                  :depth="depth + 1"
                  :field="subentry[0]"
                  :index="subentryIndex"
                  :parent-field="props.field"
                  :removable="childrenRemovable"
                  :schema-functions="props.schemaFunctions"
                  :schema-id="schemaId"
                  :schemas="schemas"
                />
              </v-expansion-panels>
            </div>
            <v-row v-if="property.type == 'object'">
              <v-col class="d-flex justify-center">
                <v-btn
                  @click="draftNewProperty"
                  color="primary"
                  size="small"
                >
                  {{ $t('Add Property') }}
                </v-btn>
              </v-col>
            </v-row>
          </div>
        </template>
      </v-expansion-panel-text>

      <SchemaPropertyCreator
        @create="emit('change')"
        ref="schemaPropertyCreator"
      />
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script setup>
import SchemaElementTitle from '@/admin/components/schemas/SchemaElementTitle.vue';
import SchemaElementTitleActions from '@/admin/components/schemas/SchemaElementTitleActions.vue';
import SchemaPropertyFormFields from '@/admin/components/schemas/SchemaPropertyFormFields.vue';
import SchemaPropertyCreator from '@/admin/components/schemas/SchemaPropertyCreator.vue';
import SchemaPropertyReportingSettingsEditor from '@/admin/components/schemas/SchemaPropertyReportingSettingsEditor.vue';
import StringEnumEditor from '@/admin/components/schemas/StringEnumEditor.vue';
import SectionHeader from '@/admin/components/schemas/SectionHeader.vue';
import _ from 'lodash';

const emit = defineEmits(['change']);

const props = defineProps({
  childrenRemovable: {
    default: false,
    type: Boolean,
  },
  definition: {
    type: Object,
    default: null,
  },
  depth: {
    type: Number,
    default: null,
  },
  field: {
    type: String,
    default: null,
  },
  index: {
    type: Number,
    default: null,
  },
  isChild: {
    default: true,
    type: Boolean,
  },
  lockFormat: Boolean,
  lockType: {
    default: true,
    type: Boolean,
  },
  openNow: Boolean,
  parentField: {
    type: String,
    default: null,
  },
  removable: {
    default: false,
    type: Boolean,
  },
  schemaId: {
    type: String,
    required: true,
  },
  schemas: {
    type: Array,
    default: null,
  },
  schemaFunctions: {
    type: Array,
    default: () => [],
  },
  title: {
    type: String,
    default: null,
  },
});

const open = ref(-1);
const entries = ref([]);
const itemProperties = ref(null);
const itemPropertyEntries = ref([]);
const property = ref(null);
const schemaPropertyCreator = ref(null);
const expansionPanelClass = computed(() => {
  return props.depth.value == 0 ? 'embedded bg-transparent' : 'bb-1 bc-outlined-gray';
});

function draftNewProperty() {
  schemaPropertyCreator.value.open({ parentField: props.field, schemaId: props.schemaId });
}

function draftNewSubProperty() {
  schemaPropertyCreator.value.open({
    isItemsProperty: true,
    parentField: [props.parentField, props.field],
    schemaId: props.schemaId,
  });
}

function draftPropertyFromField() {
  const draft = JSON.parse(JSON.stringify(props.definition.properties[props.field]));
  if (draft.type === 'string' && !draft.enum) draft.enum = [];
  return draft;
}

function getSubEntries() {
  if (
    props.definition.properties[props.field].type === 'object' &&
    props.definition.properties[props.field].properties
  ) {
    return _.sortBy(
      Object.entries(props.definition.properties[props.field].properties || {}),
      (object) => object[1].index,
    );
  }
  return [];
}

function handleChangeCore() {
  emit('change');
}

function load() {
  entries.value = getSubEntries();
  property.value = draftPropertyFromField();
  itemProperties.value = property.value.items?.properties || {};
  itemPropertyEntries.value = _.sortBy(
    Object.entries(itemProperties.value),
    (object) => object[1].index,
  );
}

onMounted(() => {
  load();
  open.value = props.openNow ? 0 : -1;
});
</script>

<style>
.embedded > .v-expansion-panel-content > .v-expansion-panel-content__wrap,
.embedded > .v-expansion-panel-header {
  padding: 0px !important;
}
</style>

<template>
  <v-card-title class="fs-20 fw-500">
    <v-row
      class="d-flex align-center"
      dense
    >
      <v-col
        cols="12"
        role="heading"
        sm="6"
      >
        {{ t(props.title) }}
      </v-col>
    </v-row>
  </v-card-title>

  <v-divider class="mb-3" />
</template>

<script setup>
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
});
</script>

<template>
  <div
    v-if="workflowEvent"
    class="fill-height"
  >
    <PageTitle
      :back-route="{ name: 'WorkflowEventIndex' }"
      :title="workflowEvent.name"
      class="mb-4"
    >
      <template #append>
        <v-tooltip location="bottom">
          <template #activator="tooltipOptions">
            <v-chip
              @click="toggleEnabled()"
              v-bind="tooltipOptions.props"
              :color="workflowEvent.enabled ? 'light-green-lighten-3' : 'grey-lighten-3'"
              class="ms-2"
            >
              {{ workflowEvent.enabled ? $t('Enabled') : $t('Disabled') }}
            </v-chip>
          </template>
          <span>
            {{
              $t(
                workflowEvent.enabled
                  ? 'This workflow is enabled and will run. Click to disable.'
                  : 'This workflow is disabled and will not run. Click to enable.',
              )
            }}
          </span>
        </v-tooltip>
      </template>

      <template #actions>
        <v-btn
          @click="deleteAutomation()"
          :aria-label="$t('Delete automation')"
          class="ms-3"
          icon="delete"
          variant="plain"
        />
      </template>

      <template #extension>
        <v-tabs v-model="tab">
          <v-tab
            :aria-label="$t('Workflow event details')"
            class="fs-16 ls-normal tt-none"
            value="details"
            exact
          >
            {{ $t('Details') }}
          </v-tab>

          <v-tab
            :aria-label="$t('Workflow event definition')"
            class="fs-16 ls-normal tt-none"
            value="definition"
            exact
          >
            {{ $t('Definition') }}
          </v-tab>
        </v-tabs>
      </template>
    </PageTitle>

    <div class="fill-height px-3">
      <v-window v-model="tab">
        <v-window-item value="details">
          <v-card tile>
            <v-card-text>
              <LabeledTextfield
                v-model="workflowEvent.name"
                @input="changed = true"
                message="Name"
              />

              <LabeledTextarea
                v-model="workflowEvent.description"
                @input="changed = true"
                message="Description"
              />
            </v-card-text>
          </v-card>
        </v-window-item>

        <v-window-item value="definition">
          <WorkflowEventDefinition
            v-model="workflowEvent"
            @updated="saveWorkflowEvent"
          />
        </v-window-item>
      </v-window>
    </div>

    <SaveBar
      @cancel="load"
      @save="saveWorkflowEvent"
      :model-value="changed"
      :processing="processing"
    />

    <ConfirmDialog ref="confirmDialog" />
  </div>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import ConfirmDialog from '@/shared/components/ConfirmDialog.vue';
import LabeledTextarea from '@/shared/components/form/LabeledTextarea.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import SaveBar from '@/shared/components/form/SaveBar.vue';
import PageTitle from '@/shared/components/PageTitle.vue';
import WorkflowEventDefinition from '@/admin/views/workflow-events/WorkflowEventDefinition.vue';
import useEventBus from '@/shared/composables/useEventBus';
import { useRoute, useRouter } from 'vue-router';

const eventBus = useEventBus();
const route = useRoute();
const router = useRouter();

const TABS = {
  DETAILS: 'details',
  DEFINITION: 'definition',
};

const changed = ref(false);
const confirmDialog = ref(null);
const processing = ref(false);
const tab = ref(TABS.DETAILS);
const workflowEvent = ref(null);

watch(
  () => tab.value,
  () => {
    router.push({ query: { tab: tab.value } });
  },
  { immediate: true },
);

onMounted(() => {
  if (route.query.tab) tab.value = route.query.tab;
  void load();
});

async function deleteAutomation() {
  const confirm = await confirmDialog.value.confirmWithText(
    'Are you sure you want to delete this workflow?',
  );
  if (!confirm) return;

  const resp = await Api.organization.workflow.event.destroy(workflowEvent.value.id);
  if (resp.status !== 202) return;

  eventBus.chime('Workflow deleted');
  router.push({ name: 'WorkflowEventIndex' });
}

async function load() {
  void loadWorkflowEvent();
}

async function loadWorkflowEvent() {
  const resp = await Api.organization.workflow.event.get(route.params.id);
  if (resp.status !== 200) return;

  workflowEvent.value = resp.data;
  changed.value = false;
}

async function saveWorkflowEvent() {
  // TODO: Determine whether necessary here - setting 'changed' on attribute value
  if (
    workflowEvent.value.eventable_attribute_name &&
    !(
      workflowEvent.value.eventable_attribute_value ||
      workflowEvent.value.eventable_attribute_previous_value
    )
  ) {
    workflowEvent.value.eventable_attribute_value = 'changed';
  }

  const resp = await Api.organization.workflow.event.update(
    workflowEvent.value.id,
    workflowEvent.value,
  );
  if (resp.status !== 200) return;

  eventBus.chime('Workflow saved');
  workflowEvent.value = resp.data;
  changed.value = false;
}

async function toggleEnabled() {
  const action = workflowEvent.value.enabled ? 'disable' : 'enable';

  const confirm = await confirmDialog.value.confirmWithText(
    `Are you sure you want to ${action} this workflow?`,
  );
  if (!confirm) return;

  const params = {
    enabled: !workflowEvent.value.enabled,
  };
  const resp = await Api.organization.workflow.event.update(workflowEvent.value.id, params);

  eventBus.chime(`Workflow ${action}d`);
  workflowEvent.value.enabled = resp.data.enabled;
}
</script>

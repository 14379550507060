<template>
  <v-card
    v-if="results.length > 0"
    class="mb-8"
    border
    flat
    tile
  >
    <v-card-title class="fs-22 fw-600">
      <v-row
        class="d-flex align-center"
        dense
      >
        <v-col
          class="d-flex justify-space-between"
          cols="12"
        >
          <div class="d-flex align-center">
            {{ t('Checklist') }}
            <v-chip
              :color="statusColor"
              class="ms-3 badge-style justify-center"
              variant="flat"
              rounded
            >
              <v-icon
                color="white"
                data-testid="all-results-passing-icon"
                role="presentation"
                aria-hidden
              >
                {{ statusIcon }}
              </v-icon>
            </v-chip>
          </div>
          <div>
            <v-btn
              @click="expanded = !expanded"
              :aria-label="$t('Toggle visibility')"
              class="ms-0"
              color="primary"
              size="small"
              variant="text"
              icon
              rounded
            >
              <v-icon
                class="material-icons-outlined"
                color="navy-blue"
              >
                {{ expanded ? 'visibility_off' : 'visibility' }}
              </v-icon>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-card-title>
    <v-divider
      v-if="expanded"
      class="mb-3"
    />
    <v-card-text v-if="expanded">
      <v-row>
        <v-col>
          <CheckResult
            v-for="result in results"
            :key="result.id"
            :message="result.message"
            :passing="result.passing"
            :schema-function-id="result.schema_function_id"
          />
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import CheckResult from '@/specialist/components/schema-functions/CheckResult.vue';

const { t } = useI18n();

const props = defineProps({
  results: {
    type: Array,
    default: () => [],
  },
  allResultsPassing: {
    type: Boolean,
    default: false,
  },
  startVisible: {
    type: Boolean,
    default: true,
  },
});

const expanded = ref(props.startVisible);

const statusIcon = computed(() => {
  return props.allResultsPassing ? 'check_circle' : 'cancel';
});

const statusColor = computed(() => {
  return props.allResultsPassing ? 'green' : 'red';
});
</script>

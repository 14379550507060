<template>
  <v-container v-if="summaryConfiguration">
    <TitleBar
      :back-route="{ name: 'SummaryConfigurationIndex' }"
      :name="summaryConfiguration.name"
      back-name="Summaries"
      flat
    />

    <StandardCard
      @click:action:save="saveFormula"
      @click:action:save-preview="saveFormula(true)"
      :actions="[
        { event: 'save', outlined: true, title: 'Save draft' },
        { event: 'save-preview', title: 'Save and preview' },
      ]"
      :processing="processing"
      title="Formula"
    >
      <v-row>
        <LabeledFormulaField v-model="draftFormula" />
      </v-row>
    </StandardCard>

    <StandardCard
      @click:action:preview="getPreview"
      :actions="[{ event: 'preview', title: 'Refresh preview' }]"
      :processing="processing"
      class="mt-4"
      title="Preview"
    >
      <v-row>
        <v-col>
          <template v-if="preview">
            <v-data-table
              :headers="headers"
              :items="preview"
              item-key="itemKey"
            />
          </template>

          <div
            v-else-if="error"
            class="px-4 py-4 bg-red c-white fs-16"
          >
            {{ $t(error) }}
          </div>
        </v-col>
      </v-row>
    </StandardCard>

    <DangerBar
      @destroy="destroy"
      @run="run"
      :processing="processing"
      class="mt-4"
      runnable
    />

    <ConfirmDialog ref="confirmDialog" />
  </v-container>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import ConfirmDialog from '@/shared/components/ConfirmDialog.vue';
import DangerBar from '@/shared/components/DangerBar.vue';
import LabeledFormulaField from '@/shared/components/form/LabeledFormulaField.vue';
import StandardCard from '@/shared/components/StandardCard.vue';
import TitleBar from '@/shared/components/TitleBar.vue';
import useEventBus from '@/shared/composables/useEventBus';
import { useRoute, useRouter } from 'vue-router';

const eventBus = useEventBus();
const route = useRoute();
const router = useRouter();

const confirmDialog = ref(null);

const draftFormula = ref(null);
const error = ref(null);
const headers = ref([]);
const preview = ref(null);
const processing = ref(false);
const summaryConfiguration = ref(null);

onMounted(load);

async function destroy() {
  const confirm = await confirmDialog.value.confirmWithText(
    `Are you sure you want to destroy "${summaryConfiguration.value.name}"?`,
  );
  if (!confirm) return;

  await Api.organization.summary_configuration.destroy(summaryConfiguration.value.id);
  router.replace({ name: 'SummaryConfigurationIndex' });
}

async function getPreview() {
  preview.value = null;
  error.value = null;
  const resp = await Api.organization.summary_configuration
    .preview(summaryConfiguration.value.id)
    .catch((errorResp) => {
      error.value = errorResp.response.data.errors[0];
    });
  if (resp?.status !== 200) return;

  if (!resp?.data) return;

  headers.value = [
    {
      text: 'Row',
      value: 'itemKey',
      title: 'Key',
      align: 'start',
      key: 'itemKey',
    },
  ];
  headers.value = headers.value.concat(
    Object.keys(resp.data[0]).map((header) => ({
      text: header,
      title: header,
      align: 'start',
      sortable: true,
      key: header,
      value: header,
    })),
  );
  preview.value = resp.data.map((item, index) => ({ ...item, itemKey: index }));
}

async function load() {
  summaryConfiguration.value = (
    await Api.organization.summary_configuration.get(route.params.id)
  ).data;
  draftFormula.value = summaryConfiguration.value.formula;
  void getPreview();
}

async function run() {
  const resp = await Api.organization.summary_configuration.run(summaryConfiguration.value.id);
  if (resp.status === 200) eventBus.chime('Run started');
}

async function saveFormula(refreshPreview) {
  processing.value = true;
  const resp = await Api.organization.summary_configuration.update({
    id: summaryConfiguration.value.id,
    formula: draftFormula.value,
  });
  if (resp.status === 200) eventBus.chime('Saved');
  if (refreshPreview) await getPreview();
  processing.value = false;
}
</script>

<template>
  <v-card
    class="mb-8"
    border
    flat
    tile
  >
    <SectionHeader title="Options" />

    <v-card-text>
      <div class="d-flex align-center mb-4">
        <v-spacer />
        <v-btn
          @click="draftSingle"
          class="me-3"
          color="primary"
          size="small"
        >
          <v-icon
            icon="add"
            start
          />
          <span>{{ t('Add') }}</span>
        </v-btn>
        <v-btn
          @click="draftMultiple"
          color="primary"
          size="small"
        >
          <v-icon
            icon="queue"
            start
          />
          <span>{{ t('Add multiple') }}</span>
        </v-btn>
      </div>
      <div
        v-if="enums.length == 0"
        class="mb-4 fs-16 c-red fw-500"
      >
        {{ t('Element does not have any options.') }}
      </div>
      <v-row
        v-for="(option, optionIndex) in enums"
        :key="optionIndex"
        dense
      >
        <v-col cols="12">
          <v-text-field
            v-model="enums[optionIndex]"
            @click:append="remove(option)"
            @update:modelValue="handleChange"
            append-icon="close"
            variant="filled"
            hide-details
          />
        </v-col>
      </v-row>

      <ResourceDialog
        @save="addSingle"
        ref="addSingleDialog"
        :fields="[
          {
            value: 'value',
            text: 'Text',
            type: 'string',
            required: true,
          },
        ]"
        save-button-text="Done"
        title="Add options"
      />

      <ResourceDialog
        @save="addMultiple"
        ref="addMultipleDialog"
        :fields="[
          {
            value: 'value',
            text: 'Options',
            type: 'string',
            rows: 4,
            required: true,
          },
        ]"
        save-button-text="Done"
        title="Add options"
      />
    </v-card-text>

    <SectionActions
      @save="save"
      :changed="changed"
    />
  </v-card>
</template>

<script setup>
import useSchemas from '@/admin/composables/useSchemas';
import { useI18n } from 'vue-i18n';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import SectionHeader from '@/admin/components/schemas/SectionHeader.vue';
import SectionActions from '@/admin/components/schemas/SectionActions.vue';
const { removeEnum, updateProperty } = useSchemas();
const { t } = useI18n();
const emit = defineEmits(['update:enums', 'change']);
const props = defineProps({
  enums: {
    type: Array,
    required: true,
  },
  field: {
    type: String,
    required: true,
  },
  isItemEnum: {
    type: Boolean,
    default: false,
  },
  parentField: {
    type: String,
    required: true,
  },
  schemaId: {
    type: String,
    required: true,
  },
});

const enums = ref(props.enums);
const changed = ref(false);

const addMultipleDialog = ref(false);
const addSingleDialog = ref(false);

const draftMultiple = () => {
  addMultipleDialog.value.open();
};

const draftSingle = (optionIndex) => {
  addSingleDialog.value.open({ value: enums.value[optionIndex], index: optionIndex });
};

const addSingle = (value) => {
  enums.value.push(value.value);
  addSingleDialog.value.close();
  changed.value = true;
};

function addMultiple(value) {
  const options = value.value.split(/\n/).filter((option) => option.trim() !== '');
  enums.value = enums.value.concat(options);
  addMultipleDialog.value.close();
  changed.value = true;
}

function handleChange() {
  changed.value = true;
}

async function remove(option) {
  let params;
  if (props.isItemEnum) {
    params = {
      items: {
        enum: option,
      },
    };
  } else {
    params = {
      enum: option,
    };
  }
  const success = await removeEnum(props.schemaId, props.parentField, props.field, params);
  if (success) {
    enums.value = enums.value.filter((enumValue) => enumValue !== option);
    emit('change');
  }
}

async function save() {
  let params;
  if (props.isItemEnum) {
    params = {
      items: {
        enum: enums.value,
      },
    };
  } else {
    params = {
      enum: enums.value,
    };
  }

  const success = await updateProperty(props.schemaId, props.parentField, props.field, params);
  if (success) {
    emit('change');
    changed.value = false;
  }
}
</script>

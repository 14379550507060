<template>
  <v-container
    class="px-0 py-0 bg-super-light-blue"
    fluid
  >
    <PageTitle
      @create="$refs.newTeamDialog.open({})"
      create-text="New team"
      title="Teams"
      createable
    />

    <v-toolbar class="mb-3 px-3 bb-1">
      <v-row>
        <v-col class="mxw-340">
          <v-text-field
            v-model="filters.query"
            @update:model-value="load()"
            :aria-label="$t('Search teams')"
            :placeholder="$t('Search teams')"
            color="secondary"
            data-testid="team.search"
            density="compact"
            prepend-inner-icon="search"
            variant="filled"
            hide-details
            tracked
          />
        </v-col>
      </v-row>
    </v-toolbar>

    <v-data-table-server
      @click:row="handleRowClick"
      :headers="tableHeaders"
      :items="items"
      :loading="processing"
      class="mx-3 b-radius-0 b-1 bc-outlined-gray"
      no-data-text="No teams found"
      fixed-header
      hide-default-footer
      hover
    >
      <template #item.created="{ item }">
        <LongDateTime :date="item.created_at" />
      </template>

      <template #item.delete="{ item }">
        <v-btn
          @click.stop.prevent="destroy(item)"
          :aria-label="`${$t('Remove team')}: ${item.name}`"
          color="red"
          variant="text"
          icon
        >
          <v-icon icon="close" />
        </v-btn>
      </template>

      <template #item.actions="{ item }">
        <div class="d-flex justify-end align-center">
          <ActionMenu
            @click:action:delete="destroy(item)"
            :items="[{ title: 'Delete', event: 'delete' }]"
            button-icon="more_vert"
          />
        </div>
      </template>
    </v-data-table-server>

    <VerticalSpacer :min-height="14" />

    <ResourceDialog
      @save="create"
      ref="newTeamDialog"
      :fields="[{ text: 'Name', value: 'name' }]"
      :processing="processing"
      title="Create new team"
    />
  </v-container>
</template>

<script setup>
import ActionMenu from '@/shared/components/ActionMenu.vue';
import Api from '@/shared/services/all_bright_finder';
import LongDateTime from '@/shared/components/LongDateTime.vue';
import PageTitle from '@/shared/components/PageTitle.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import VerticalSpacer from '@/shared/components/VerticalSpacer.vue';
import useEventBus from '@/shared/composables/useEventBus';
import { useRouter } from 'vue-router';

const eventBus = useEventBus();
const router = useRouter();

const processing = ref(false);
const filters = ref({
  query: null,
});
const items = ref([]);
const searchCounter = ref(0);
const tableHeaders = ref([
  {
    title: 'Name',
    value: 'name',
  },
  {
    title: 'Members',
    value: 'members_count',
    align: 'center',
  },
  {
    title: 'Created',
    value: 'created',
  },
  {
    title: '',
    value: 'actions',
  },
]);

function handleRowClick(event, { item }) {
  router.push({
    name: 'TeamShow',
    params: { id: item.id },
  });
}

async function load() {
  processing.value = true;

  searchCounter.value += 1;
  const currentSearchCounter = searchCounter.value;

  const response = await Api.organization.team.index(filters.value);
  if (searchCounter.value !== currentSearchCounter) return;

  items.value = response.data;

  processing.value = false;
}

async function create(team) {
  processing.value = true;

  const response = await Api.organization.team.create(team);

  processing.value = false;
  if (response?.status !== 201) return;

  router.push({
    name: 'TeamShow',
    params: { id: response.data.id },
  });
}

function destroy(team) {
  // eslint-disable-next-line no-alert
  if (!confirm('Are you sure you want to delete this team?')) return;

  Api.organization.team.destroy(team.id, async () => {
    await load();
    eventBus.chime('Deleted');
  });
}

onMounted(load);
</script>

<template>
  <v-toolbar
    v-if="customView"
    class="mb-3 bb-1"
    extension-height="60"
    dense
    extended
  >
    <v-row class="d-flex align-center px-4">
      <v-col class="mxw-400">
        <v-select
          v-model="selectedSubsidyProgram"
          @update:model-value="handleSubsidyProgramChange"
          :aria-label="selectedSubsidyProgram?.name"
          :items="subsidyPrograms"
          density="compact"
          item-title="name"
          item-value="id"
          variant="filled"
          hide-details
          return-object
        />
      </v-col>
      <v-col class="mxw-400 d-flex align-center">
        <v-text-field
          v-model="search"
          @click:clear="clearSearch"
          @keyup.enter="setSearch"
          :aria-label="$t('Filter results by name')"
          :placeholder="$t('Filter results by name')"
          color="primary"
          density="compact"
          prepend-inner-icon="search"
          variant="filled"
          clearable
          hide-details
        />
        <v-btn
          @click="setSearch"
          color="primary"
          variant="flat"
        >
          <span v-t="'Search'" />
        </v-btn>
      </v-col>
    </v-row>

    <template
      v-if="selectedSubsidyProgram"
      #extension
    >
      <div class="d-flex flex-grow-1 px-4">
        <FilterMenu
          :active="selectedStatuses.length > 0"
          classes="d-md-inline-flex"
          test-id="status-filter-button"
          title="Status"
          paddingless
        >
          <template #card>
            <v-autocomplete
              v-model="selectedStatuses"
              @update:model-value="handleFilterChange"
              :aria-label="$t('Filter by status')"
              :disabled="processing"
              :item-title="(item) => $t(item)"
              :items="statuses"
              :menu="true"
              :placeholder="$t('Filter by status')"
              density="compact"
              prepend-inner-icon="search"
              variant="filled"
              autofocus
              chips
              clearable
              multiple
            />
          </template>
        </FilterMenu>

        <FilterMenu
          v-if="selectedSubsidyProgram.enable_team_review && teams.length > 0"
          :active="selectedTeams.length > 0"
          classes="d-md-inline-flex"
          test-id="team-filter-button"
          title="Team"
          paddingless
        >
          <template #card>
            <v-autocomplete
              v-model="selectedTeams"
              @update:model-value="handleFilterChange"
              :aria-label="$t('Filter by team name')"
              :disabled="processing"
              :item-title="(item) => $t(item.name)"
              :items="teams"
              :menu="true"
              :placeholder="$t('Filter by team name')"
              density="compact"
              item-value="id"
              prepend-inner-icon="search"
              variant="filled"
              autofocus
              chips
              clearable
              multiple
            />
          </template>
        </FilterMenu>

        <FilterMenu
          v-if="selectedSubsidyProgram.enable_individual_review && reviewers.length > 0"
          :active="selectedReviewers.length > 0"
          classes="d-md-inline-flex"
          title="Assignment"
          paddingless
        >
          <template #card>
            <v-autocomplete
              v-model="selectedReviewers"
              @update:model-value="handleFilterChange"
              :aria-label="$t('Filter by reviewer name')"
              :disabled="processing"
              :items="reviewers"
              :menu="true"
              :placeholder="$t('Filter by reviewer name')"
              density="compact"
              item-title="name"
              item-value="id"
              prepend-inner-icon="search"
              variant="filled"
              autofocus
              chips
              clearable
              multiple
            />
          </template>
        </FilterMenu>

        <FilterMenu
          v-if="displayStages"
          :active="selectedStages.length > 0"
          classes="d-md-inline-flex"
          title="Stage"
          paddingless
        >
          <template #card>
            <v-autocomplete
              v-model="selectedStages"
              @update:model-value="handleFilterChange"
              :aria-label="$t('Filter by stage title')"
              :item-title="(item) => $t(item.title)"
              :items="stages"
              :menu="true"
              :placeholder="$t('Filter by stage title')"
              density="compact"
              item-value="id"
              prepend-inner-icon="search"
              variant="filled"
              autofocus
              chips
              clearable
              multiple
            />
          </template>
        </FilterMenu>

        <FilterMenu
          v-if="displayLabels"
          :active="selectedLabels.length > 0"
          classes="d-md-inline-flex"
          title="Label"
          paddingless
        >
          <template #card>
            <v-autocomplete
              v-model="selectedLabels"
              @update:model-value="handleFilterChange"
              :aria-label="$t('Filter by label name')"
              :disabled="processing"
              :items="labels"
              :menu="true"
              :placeholder="$t('Filter by label name')"
              density="compact"
              item-title="name"
              item-value="id"
              prepend-inner-icon="search"
              variant="filled"
              autofocus
              chips
              clearable
              multiple
            >
              <template #chip="{ item, props }">
                <v-chip v-bind="props">
                  <template #prepend>
                    <v-avatar
                      :color="item.raw.color"
                      start
                    />
                  </template>
                  {{ item.raw.name }}
                </v-chip>
              </template>
              <template #item="{ item, props }">
                <v-list-item
                  v-bind="props"
                  :title="$t(item.raw.name)"
                >
                  <template #prepend="{ isSelected }">
                    <v-checkbox-btn
                      :key="item.value"
                      :model-value="isSelected"
                      :ripple="false"
                      tabindex="-1"
                    />
                  </template>
                  <template #append>
                    <v-avatar
                      :color="item.raw.color"
                      size="20"
                    />
                  </template>
                </v-list-item>
              </template>
            </v-autocomplete>
          </template>
        </FilterMenu>

        <template
          v-if="
            customView.attributes.columns.length > 0 || customView.attributes.filters.length > 0
          "
        >
          <v-btn
            @click="$emit('edit:custom-view:filters')"
            :class="filtersButtonClass"
            variant="outlined"
            rounded
          >
            <span v-t="'More filters'" />
            <v-icon
              class="ms-3"
              icon="filter_list"
              size="18"
            />
          </v-btn>
        </template>

        <v-spacer />

        <v-btn
          v-show="itemsSelected"
          @click="$emit('edit:team')"
          class="me-3"
          color="primary"
        >
          <span v-t="'Assign team'" />
        </v-btn>

        <v-btn
          v-show="itemsSelected"
          @click="$emit('edit:reviewer')"
          class="me-3"
          color="primary"
          variant="text"
          icon
        >
          <span v-t="'Assign member'" />
        </v-btn>

        <VerticalDivider />

        <v-btn
          @click="$emit('edit:custom-view:columns')"
          :class="columnsButtonClass"
          class="ms-2"
          prepend-icon="tune"
          variant="outlined"
        >
          <span v-t="'Manage columns'" />
          <v-avatar
            v-if="customView.attributes.columns.length > 0"
            class="ms-2"
            color="primary"
            size="24"
            rounded
          >
            <span
              v-text="customView.attributes.columns.length"
              class="c-white"
            />
          </v-avatar>
        </v-btn>

        <template v-if="itemsSelected">
          <ActionMenu
            @click:action:download="$emit('download')"
            @click:action:update:stage="$emit('edit:stage')"
            :items="[
              { title: 'Download', event: 'download' },
              { title: 'Update stage', event: 'update:stage' },
            ]"
            button-class="ms-3"
            button-icon="more_vert"
          />
        </template>
      </div>
    </template>
  </v-toolbar>
</template>

<script setup>
import ActionMenu from '@/shared/components/ActionMenu.vue';
import FilterMenu from '@/shared/components/form/FilterMenu.vue';
import propsToRefs from '@/shared/utils/propsToRefs';
import VerticalDivider from '@/shared/components/VerticalDivider.vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

const store = useStore();
const route = useRoute();

const props = defineProps({
  customView: {
    type: Object,
    default: null,
  },
  customViews: {
    default: () => [],
    type: Array,
  },
  itemsSelected: Boolean,
  labels: {
    default: () => [],
    type: Array,
  },
  processing: Boolean,
  reviewers: {
    default: () => [],
    type: Array,
  },
  subsidyPrograms: {
    default: () => [],
    type: Array,
  },
  teams: {
    default: () => [],
    type: Array,
  },
});

const propReferences = propsToRefs(props, ['customViews', 'subsidyPrograms']);
const [customViewsRef, subsidyProgramsRef] = propReferences;

const emit = defineEmits([
  'change:custom-view',
  'change:filters',
  'change:subsidy-program',
  'download',
  'edit:custom-view:columns',
  'edit:custom-view:filters',
  'edit:custom-view:sorts',
  'edit:reviewer',
  'edit:stage',
  'edit:team',
  'search',
  'update:page',
]);

const search = ref(null);
const selectedCustomView = ref(null);
const selectedLabels = ref([]);
const selectedReviewers = ref([]);
const selectedStages = ref([]);
const selectedStatuses = ref([]);
const selectedSubsidyProgram = ref(null);
const selectedTeams = ref([]);

const columnsButtonClass = computed(() => {
  return getHighlightedClass(props.customView.attributes.columns.length);
});

const displayLabels = computed(() => {
  return store.state.profile.org_subsidies_access && props.labels.length > 0;
});

const displayStages = computed(() => {
  return (
    store.state.profile.org_subsidies_access &&
    selectedSubsidyProgram.value.enable_stages &&
    selectedSubsidyProgram.value.stages.length > 0
  );
});

const filtersButtonClass = computed(() => {
  return getHighlightedClass(props.customView.attributes.filters.length);
});

const stages = computed(() => {
  return selectedSubsidyProgram.value?.stages || [];
});

const statuses = computed(() => {
  return selectedSubsidyProgram.value?.statuses || [];
});

watch(customViewsRef, (newVal) => {
  if (!newVal[0]) return;

  [selectedCustomView.value] = newVal;
  handleCustomViewChange(selectedCustomView.value);
});

watch(subsidyProgramsRef, (newVal) => {
  if (!newVal[0]) return;

  const subsidyProgramQueryId = route.query?.subsidyProgramId;

  if (store.state.lastViewedSubsidyProgramId) {
    selectedSubsidyProgram.value = newVal.find(
      (sp) => sp.id === store.state.lastViewedSubsidyProgramId,
    );
  }

  if (subsidyProgramQueryId) {
    selectedSubsidyProgram.value = newVal.find((sp) => sp.id === subsidyProgramQueryId);
  } else {
    [selectedSubsidyProgram.value] = newVal;
  }

  handleSubsidyProgramChange(selectedSubsidyProgram.value);
});

function clearSearch() {
  search.value = null;
  localStorage.removeItem('custom_subsidy_search');
  emit('search', null);
}

function setSearch() {
  localStorage.setItem('custom_subsidy_search', JSON.stringify(search.value));
  emit('update:page', 1);
  emit('search', search.value);
}

function getFiltersAsCustomViewParams() {
  return {
    label_ids: selectedLabels.value,
    reviewer_ids: selectedReviewers.value,
    stage_ids: selectedStages.value,
    statuses: selectedStatuses.value,
    team_ids: selectedTeams.value,
  };
}

function getHighlightedClass(length) {
  return length > 0 ? 'bc-primary-light bg-primary-extra-light' : 'bg-white bc-very-light-gray';
}

function handleCustomViewChange(newVal) {
  emit('change:custom-view', newVal);
  selectedLabels.value = newVal.attributes.label_ids;
  selectedReviewers.value = newVal.attributes.reviewer_ids;
  selectedStages.value = newVal.attributes.stage_ids;
  selectedStatuses.value = newVal.attributes.statuses;
  selectedTeams.value = newVal.attributes.team_ids;
}

function handleFilterChange() {
  emit('update:page', 1);
  emit('change:filters', getFiltersAsCustomViewParams());
}

function handleSubsidyProgramChange(newVal) {
  selectedCustomView.value = null;
  store.commit('setViewedSubsidyProgram', newVal.id);
  emit('change:subsidy-program', newVal);
}

onMounted(() => {
  if (localStorage.getItem('custom_subsidy_search')) {
    try {
      search.value = JSON.parse(localStorage.custom_subsidy_search);
      emit('search', search.value);
    } catch (_error) {
      localStorage.removeItem('custom_subsidy_search');
    }
  }
});

defineExpose({
  emit,
});
</script>

<template>
  <v-card
    class="mb-8"
    border
    flat
    tile
  >
    <SectionHeader title="Property settings" />
    <v-card-text>
      <v-row>
        <LabeledTextfield
          v-model="props.field"
          append-inner-icon="lock"
          cols="4"
          message="Name"
          hide-details
          readonly
        />

        <LabeledSimpleSelect
          v-model="propertyType"
          :items="TYPES_OTHER_THAN_OBJECT"
          append-inner-icon="lock"
          cols="4"
          message="Type"
          readonly
        />

        <LabeledSimpleSelect
          v-if="propertyType == 'string'"
          v-model="property.format"
          :append-inner-icon="lockFormat ? 'lock' : null"
          :items="FORMAT_OPTIONS"
          :readonly="lockFormat"
          cols="4"
          message="Format"
        />

        <LabeledTextfield
          v-model="property.alias"
          cols="12"
          message="Alias"
          hide-details
        />

        <LabeledSimpleSelect
          v-model="property.role"
          :items="propertyRoleOptions"
          cols="12"
          message="Role"
        />

        <template v-if="property.role == 'formula'">
          <LabeledSimpleSelect
            v-model="property.schemaFunctionId"
            :items="schemaFunctions"
            item-title="name"
            item-value="id"
            message="Function"
          />

          <LabeledControl
            v-if="property.expression"
            message="Formula"
          >
            <prism-editor
              v-model="property.expression"
              :highlight="highlighter"
              class="my-editor"
            />
          </LabeledControl>
        </template>
      </v-row>

      <v-row v-show="propertyType == 'number' || propertyType == 'integer'">
        <LabeledTextfield
          v-model.number="property.minimum"
          message="Minimum"
          type="number"
        />

        <LabeledTextfield
          v-model.number="property.maximum"
          message="Maximum"
          type="number"
        />
      </v-row>

      <v-row v-show="propertyType == 'string' && property.format == 'date'">
        <LabeledTextfield
          v-model="property.dateGreaterOrEqualToDate"
          message="Greater than or equal to date"
        />

        <LabeledTextfield
          v-model="property.dateLessOrEqualToDate"
          message="Less than or equal to date"
        />

        <LabeledTextfield
          v-model="property.dateGreaterOrEqualToDaysFromToday"
          message="Greater than or equal to days from today"
        />

        <LabeledTextfield
          v-model="property.dateLessOrEqualToDaysFromToday"
          message="Less than or equal to days from today"
        />

        <LabeledTextfield
          v-model="property.dateGreaterOrEqualToValue"
          message="Greater than or equal to value"
        />

        <LabeledTextfield
          v-model="property.dateLessOrEqualToValue"
          message="Less than or equal to value"
        />

        <LabeledDatePicker
          v-model="minimumDate"
          @update:model-value="updateMinimumDate"
          cols="12"
          md="6"
          message="Minimum"
          clearable
          hide-details
          landscape
        />

        <LabeledDatePicker
          v-model="maximumDate"
          @update:model-value="updateMaximumDate"
          cols="12"
          md="6"
          message="Maximum"
          clearable
          hide-details
          landscape
        />
      </v-row>

      <v-row v-show="propertyType == 'string' && !property.role">
        <LabeledTextfield
          v-model="property.mask"
          message="Mask"
          hide-details
        />
        <LabeledTextfield
          v-model="property.placeholder"
          message="Placeholder"
          hide-details
        />
        <LabeledTextfield
          v-model="property.default"
          message="Default value"
          hide-details
        />
      </v-row>

      <v-row>
        <LabeledSimpleSelect
          v-model="property.mappedModel"
          :items="['Child', 'Group', 'Provider', 'SubsidyAward']"
          class="mt-2"
          message="Mapped model"
          chips
          deletable-chips
        />

        <LabeledSimpleSelect
          v-if="property.mappedModel"
          v-model="property.mappedAttribute"
          :items="mappableFields"
          message="Mapped attribute"
        />

        <LabeledTextfield
          v-model="property.description"
          message="Description"
        />

        <LabeledTextfield
          v-model="property.tooltip"
          message="Tooltip"
          hide-details
        />

        <LabeledTextfield
          v-model="property.icon"
          message="Icon"
          hide-details
        />

        <LabeledSimpleSelect
          v-model="property.editRoles"
          :items="ROLE_OPTIONS"
          message="Editors"
          chips
          closable-chips
          hide-details
          multiple
        />

        <LabeledSimpleSelect
          v-model="property.viewRoles"
          :items="ROLE_OPTIONS"
          message="Viewers"
          chips
          closable-chips
          hide-details
          multiple
        />

        <LabeledSwitch
          v-model="property.enabled"
          subtitle="This field will appear in forms, if applicable."
          title="Enabled"
          divided-bottom
        />
        <LabeledSwitch
          v-model="property.readOnly"
          subtitle="This field cannot be edited"
          title="Readonly"
          divided-bottom
        />
        <LabeledSwitch
          v-model="property.filterable"
          subtitle="This field can be used as a filter option, if applicable."
          title="Filterable"
          divided-bottom
        />
        <LabeledSwitch
          v-model="property.identifier"
          subtitle="This field can be used as a link to other tables. It must have the same name as the foreign key field in the associated table."
          title="Identifier"
          divided-bottom
        />
        <LabeledSwitch
          v-model="property.foreignKey"
          :divided-bottom="!property.foreignKey"
          subtitle="This field can be used as a link to other tables. It must have the same name as the identifier field in the foreign table."
          title="Foreign key"
        />
        <template v-if="property.foreignKey">
          <LabeledSimpleSelect
            v-model="property.foreignKeyTable"
            :items="['Group']"
            class="mt-2"
            message="Foreign key table"
            chips
            deletable-chips
          />
        </template>
      </v-row>
    </v-card-text>

    <SectionActions
      @save="save"
      :changed="changed"
    />
  </v-card>
</template>

<script setup>
import SectionActions from '@/admin/components/schemas/SectionActions.vue';
import LabeledDatePicker from '@/shared/components/form/LabeledDatePicker.vue';
import LabeledSwitch from '@/shared/components/form/LabeledSwitch.vue';
import LabeledSimpleSelect from '@/shared/components/form/LabeledSimpleSelect.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import Schema from '@/shared/services/schema';
import SectionHeader from '@/admin/components/schemas/SectionHeader.vue';
import useSchemas from '@/admin/composables/useSchemas';
import { useDate } from 'vuetify';
import { highlight, languages } from 'prismjs/components/prism-core';
import { PrismEditor } from 'vue-prism-editor';
import 'vue-prism-editor/dist/prismeditor.min.css';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism-tomorrow.css';
import LabeledControl from '@/shared/components/form/LabeledControl.vue';

const date = useDate();
const { updateProperty } = useSchemas();
const emit = defineEmits(['change', 'change:core']);

const props = defineProps({
  lockFormat: Boolean,
  field: {
    type: String,
    required: true,
  },
  parentField: {
    type: String,
    required: true,
  },
  property: {
    type: Object,
    required: true,
  },
  schemaId: {
    type: String,
    required: true,
  },
  schemas: {
    type: Array,
    required: true,
  },
  schemaFunctions: {
    type: Array,
    required: true,
  },
});

const property = ref({
  alias: props.property.alias,
  dateLessOrEqualToDate: props.property.dateLessOrEqualToDate,
  dateLessOrEqualToDaysFromToday: props.property.dateLessOrEqualToDaysFromToday,
  dateLessOrEqualToValue: props.property.dateLessOrEqualToValue,
  dateGreaterOrEqualToDate: props.property.dateGreaterOrEqualToDate,
  dateGreaterOrEqualToDaysFromToday: props.property.dateGreaterOrEqualToDaysFromToday,
  dateGreaterOrEqualToValue: props.property.dateGreaterOrEqualToValue,
  description: props.property.description,
  editRoles: props.property.editRoles,
  enabled: props.property.enabled,
  expression: props.property.expression,
  filterable: props.property.filterable,
  foreignKey: props.property.foreignKey,
  foreignKeyTable: props.property.foreignKeyTable,
  format: props.property.format,
  icon: props.property.icon,
  identifier: props.property.identifier,
  mappedAttribute: props.property.mappedAttribute,
  mappedModel: props.property.mappedModel,
  maximum: props.property.maximum,
  minimum: props.property.minimum,
  readOnly: props.property.readOnly,
  role: props.property.role,
  schemaFunctionId: props.property.schemaFunctionId,
  tooltip: props.property.tooltip,
  viewRoles: props.property.viewRoles,
});
const propertyType = ref(props.property.type);
const maximumDate = ref(null);
const minimumDate = ref(null);
const changed = ref(false);
const { propertyRoleOptions, TYPES_OTHER_THAN_OBJECT, FORMAT_OPTIONS, ROLE_OPTIONS } =
  useSchemas(property);

if (propertyType.value == 'string' && property.value.format == 'date') {
  maximumDate.value = property.value.maximum ? date.parseISO(property.value.maximum) : null;
  minimumDate.value = property.value.minimum ? date.parseISO(property.value.minimum) : null;
}

function highlighter(code) {
  return highlight(code, languages.js, 'js');
}

function updateMaximumDate(newMaximum) {
  property.value.maximum = newMaximum;
}

function updateMinimumDate(newMinimum) {
  property.value.minimum = newMinimum;
}

const mappableFields = computed(() => {
  if (props.schemas && property.value.mappedModel) {
    const schema = props.schemas.find((schema) => schema.data_type === property.value.mappedModel);
    if (schema && schema.definition) return Schema.fields(schema.definition);
  }
  return [];
});

async function save() {
  const changedCoreFields =
    ['format', 'alias', 'role'].filter((key) => props.property[key] != property.value[key]).length >
    0;

  const success = await updateProperty(
    props.schemaId,
    props.parentField,
    props.field,
    property.value,
  );

  if (success) {
    emit('change');
    if (changedCoreFields) {
      emit('change:core');
    }
    changed.value = false;
  }
}

watch(
  () => property,
  () => {
    changed.value = true;
  },
  {
    deep: true,
  },
);
</script>

<style>
/* required class */
.my-editor {
  /* we dont use `language-` classes anymore so thats why we need to add background and text color manually */
  background: #fff;
  color: #38beb5;

  /* you must provide font-family font-size line-height. Example: */
  font-family:
    Fira code,
    Fira Mono,
    Consolas,
    Menlo,
    Courier,
    monospace;
  font-size: 16px;
  line-height: 1.5;
  padding: 10px;
  border: 1px solid #38beb5;
}

.token.punctuation {
  color: #38beb5;
}

/* optional class for removing the outline */
.prism-editor__textarea:focus {
  outline: none;
}
</style>

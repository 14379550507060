<template>
  <ResourceDialog
    @save="saveReportFolder"
    ref="dialog"
    :processing="processing"
    :title="$t('Move Report to Report Folder')"
  >
    <template #form>
      <LabeledAutocomplete
        v-model="currentFolderId"
        :items="reportFolderItems"
        :loading="processing"
        item-title="name"
        item-value="id"
        placeholder="Type the name of a folder here"
      />
    </template>
  </ResourceDialog>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import LabeledAutocomplete from '@/shared/components/form/LabeledAutocomplete.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import useEventBus from '@/shared/composables/useEventBus';

const eventBus = useEventBus();

const props = defineProps({
  afterSave: {
    type: Function,
    default: () => {},
  },
  processing: {
    type: Boolean,
    default: true,
  },
  reportFolderItems: {
    type: Array,
    default: () => [],
  },
  selectedReport: {
    type: Object,
    default: () => {},
  },
});

const currentFolderId = ref(null);
const currentReport = ref(null);
const dialog = ref(null);

async function saveReportFolder() {
  const resp = await Api.organization.report.update(currentReport.value.id, {
    report_folder_id: currentFolderId.value,
  });

  if (resp.status === 200) {
    eventBus.chime('Report was moved');
    props.afterSave();
    close();
  } else {
    close();
  }
}

async function open(report) {
  currentFolderId.value = null;
  currentReport.value = report;
  dialog.value.open(report);
}

function close() {
  dialog.value?.close();
}

function setFolder(folderId) {
  currentFolderId.value = folderId;
}

defineExpose({
  open,
  setFolder,
});
</script>

<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <v-progress-linear indeterminate />
</template>

<script setup>
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

const router = useRouter();
const store = useStore();

onMounted(() => {
  store.dispatch('identify', {
    success() {
      router.replace({ path: store.state.site.landing_page || '/tasks' });
    },
    failure() {
      router.replace({ path: '/' });
    },
  });
});
</script>

<template>
  <div>
    <v-card
      class="mb-4"
      border
      tile
    >
      <v-card-title class="fs-22 fw-600">
        <v-row
          class="d-flex align-center"
          dense
        >
          <v-col
            cols="12"
            sm="9"
          >
            {{ $t('Application') }}
          </v-col>
          <v-col
            class="d-flex justify-end"
            cols="3"
            sm="3"
          >
            <v-btn
              :disabled="!grant.submitted_at"
              :href="link"
              color="primary"
              variant="outlined"
            >
              {{ $t('Download') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider class="mb-3" />
      <v-card-text>
        <div v-if="loaded">
          <QuestionSet
            v-model="grant"
            @change="changed = true"
            :color="null"
            :elevation="0"
            :processing="processing || undefined"
            :questions="validEligibilityQuestions"
            :readonly="!$store.state.profile.org_grants_edit || undefined"
            :schema="grantSchema.definition"
            expanded
            hide-actions
            outlined
            tile
            very-dense
          />

          <QuestionSet
            v-model="grant"
            @change="changed = true"
            :color="null"
            :elevation="0"
            :processing="processing || undefined"
            :questions="validQuestions"
            :readonly="!$store.state.profile.org_grants_edit || undefined"
            :schema="grantSchema.definition"
            expanded
            hide-actions
            outlined
            tile
            very-dense
          />

          <div
            v-for="question in validVerificationQuestions"
            :key="question.id"
            class="mb-6"
          >
            <FormQuestion
              :color="null"
              :elevation="0"
              :subtitle="question.verification_subtitle"
              :title="question.verification_title"
              hide-actions
              outlined
              tile
              very-dense
            >
              <AttachmentUploader
                @uploaded="attachments.push($event)"
                :ref="['uploader', question.id].join('')"
                :owner="{
                  business_id: grant.business_id,
                  type: 'GrantProgram',
                  id: grantProgram.id,
                  tag: question.id,
                  tags: [question.id, grant.id],
                }"
                class="mb-4"
              />
              <AttachmentList
                @delete="loadAttachments"
                :attachments="attachments.filter((attachment) => attachment.tag == question.id)"
                class="mb-6"
              />
            </FormQuestion>
          </div>
        </div>
      </v-card-text>
    </v-card>

    <AttestationCard
      v-if="isEsignDisplayed"
      :attestation-revision="submissionRevision"
    />

    <SaveBar
      v-model="changed"
      @cancel="cancel"
      @save="save"
      :processing="processing || undefined"
    />
  </div>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import AttachmentList from '@/shared/components/attachments/AttachmentList.vue';
import AttachmentUploader from '@/shared/components/attachments/AttachmentUploader.vue';
import AttestationCard from '@/shared/components/AttestationCard.vue';
import FormQuestion from '@/shared/components/form/FormQuestion.vue';
import QuestionSet from '@/shared/components/form/QuestionSet.vue';
import SaveBar from '@/shared/components/form/SaveBar.vue';
import useEventBus from '@/shared/composables/useEventBus';
import useGrant from '@/shared/composables/useGrant';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

const grant = defineModel({ type: Object });

const props = defineProps({
  grantProgram: {
    type: Object,
    default: null,
  },
});

const emit = defineEmits(['change', 'reset']);
const eventBus = useEventBus();
const route = useRoute();
const store = useStore();

const attachments = ref([]);
const changed = ref(false);
const submissionRevision = ref(null);
const grantSchema = ref(null);
const processing = ref(false);
const revisionsLoaded = ref(false);

const grantProgramId = computed(() => props.grantProgram.id);
const displayAll = computed(() => props.grantProgram.enable_reviewer_show_all);

const {
  loadQuestions,
  questionsLoaded,
  validateAnswers,
  validEligibilityQuestions,
  validQuestions,
  validVerificationQuestions,
} = useGrant({
  grantRef: grant,
  grantProgramIdRef: grantProgramId,
  displayAll: displayAll.value,
});

const link = computed(() => {
  return Api.organization.grant.downloadUrl(route.params.id, 'Application');
});

const loaded = computed(() => grantSchema.value && revisionsLoaded.value && questionsLoaded.value);

const isEsignDisplayed = computed(() => {
  return props.grantProgram.enable_esign && submissionRevision.value?.author_signed_name;
});

watch(
  () => loaded.value,
  (newVal, oldVal) => {
    if (newVal && newVal !== oldVal) validateAnswers();
  },
  { immediate: true },
);

onMounted(() => {
  grantSchema.value = store.state.schemas[grant.value.schema_id];
  void loadAttachments();
  void loadQuestions();
  loadRevisions();
});

function cancel() {
  emit('reset');
  changed.value = false;
}

async function loadAttachments() {
  const params = {
    owner_type: 'Grant',
    owner_id: grant.value.id,
  };
  const resp = await Api.organization.attachment.index(params);
  if (resp?.status !== 200) return;

  attachments.value = resp.data;
}

function loadRevisions() {
  const params = {
    owner_id: grant.value.id,
    owner_type: 'Grant',
  };
  Api.organization.revision.index(params, (resp) => {
    revisionsLoaded.value = true;
    submissionRevision.value = resp.data[resp.data.length - 1];
    [submissionRevision.value] = resp.data.filter(
      (revision) => revision.data.submitted_at && !revision.data.submitted_at[0],
    );
  });
}

async function save() {
  processing.value = true;

  await Api.organization.grant.update(grant.value.id, grant.value);

  emit('change');
  validateAnswers();
  processing.value = false;
  changed.value = false;
  eventBus.chime('Saved');
}
</script>

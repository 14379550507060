<!-- eslint-disable vue/no-mutating-props -->
<template>
  <v-card
    id="hours"
    :border="role === 'specialist'"
    class="mb-4"
    flat
    tile
  >
    <v-card-title class="tab-title d-flex">
      <v-btn
        v-if="$vuetify.display.smAndDown"
        @click="$emit('back')"
        class="me-1 c-black"
        variant="text"
        icon
      >
        <v-icon
          icon="chevron_left"
          size="28"
        />
      </v-btn>
      <h2 class="v-card-title py-0 px-0">
        {{ $t('Hours of operation') }}
      </h2>
    </v-card-title>
    <v-divider />
    <v-card-text class="my-4">
      <div class="mb-4">
        <v-col class="fs-18 ma-0 pa-0">
          {{ $t('Confirm the days of the week and hours you are open.') }}
        </v-col>
        <v-row class="mt-2">
          <LabeledSelect
            v-model="provider.closed_status"
            :bg-color="provider.closed_status === 'Open' ? 'green' : 'red'"
            :schema-id="provider.schema_id"
            aria-label="Closed status"
            cols="12"
            data-cy="closed_status"
            field="closed_status"
            md="5"
            prepend-inner-icon="check_circle"
            variant="solo-filled"
            global
            rounded
          />
          <LabeledSelect
            v-show="provider.closed_status === 'Closed'"
            v-model="provider.closure_reason"
            :schema-id="provider.schema_id"
            aria-label="Closure reason"
            cols="12"
            field="closure_reason"
            md="4"
            message="Closure reason"
            global
          />
          <LabeledSelect
            v-show="provider.closed_status === 'Temporarily closed'"
            v-model="provider.temporary_closure_reason"
            :schema-id="provider.schema_id"
            aria-label="Temporary closure reason"
            cols="12"
            field="temporary_closure_reason"
            md="4"
            message="Temporary closure reason"
            global
          />
          <LabeledDatePicker
            v-if="provider.closed_status === 'Temporarily closed'"
            v-model="provider.open_date"
            @input="save()"
            :schema-id="provider.schema_id"
            aria-label="Choose date you will open"
            cols="12"
            field="open_date"
            md="3"
          />
        </v-row>
      </div>

      <v-row>
        <LabeledSelect
          v-model="provider.program_season"
          id="manager_programs_operate"
          :aria-label="`When do your ${terms.programs.toLowerCase()} operate?`"
          :message="
            getSchema().definition.properties.program_season?.alias ||
            `When do your ${terms.programs.toLowerCase()} operate?`
          "
          :schema-id="provider.schema_id"
          data-cy="program_season"
          field="program_season"
          global
          tracked
        />
        <LabeledSelect
          v-model="provider.program_types"
          id="manager_program_types"
          :aria-label="`What type of ${terms.program.toLowerCase()} is this?`"
          :message="
            getSchema().definition.properties.program_types?.alias ||
            `What type of ${terms.program.toLowerCase()} is this?`
          "
          :schema-id="provider.schema_id"
          data-cy="program_types"
          field="program_types"
          global
          tracked
        />
      </v-row>

      <v-row>
        <v-col class="fw-500">
          {{ $t('When are you open?') }}
        </v-col>
      </v-row>

      <v-row
        v-for="(day, index) in hours"
        :key="index"
        class="mb-2"
        dense
      >
        <v-col style="padding-top: 20px; max-width: 100px">
          <div class="c-black bc-body">
            {{ $t(getDayText(day)) }}
          </div>
        </v-col>
        <v-col style="max-width: 110px">
          <v-switch
            v-model="hours[index].included"
            @update:model-value="save"
            :disabled="locked"
            :label="hours[index].included ? $t('Open') : $t('Closed')"
            color="primary"
            hide-details
          />
        </v-col>
        <v-col>
          <LabeledOpenClose
            @change="updateDay(index, $event)"
            :hard-lock="locked"
            :model-value="[day.open, day.close]"
            :step-value="5"
            aria-label="Update the day"
          />
        </v-col>
      </v-row>

      <v-row>
        <v-col
          class="ma-0 pa-0"
          cols="12"
        >
          <LabeledTextarea
            v-model="provider.hours_other_description"
            :schema-id="provider.schema_id"
            aria-label="Add notes about hours"
            field="hours_other_description"
            global
          />
        </v-col>
      </v-row>

      <v-row
        v-for="field in getAppendedHours()"
        :key="field"
      >
        <CustomField
          :field="field"
          :model-value="provider"
          :schema-id="provider.schema_id"
          :show-title="true"
          class="fs-16 mb-2"
          mode="edit"
          global
        />
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script setup>
import assets from '@/shared/services/assets';
import CustomField from '@/shared/components/CustomField.vue';
import LabeledDatePicker from '@/shared/components/form/LabeledDatePicker.vue';
import LabeledSelect from '@/shared/components/form/LabeledSelect.vue';
import LabeledTextarea from '@/shared/components/form/LabeledTextarea.vue';
import LabeledOpenClose from '@/shared/components/form/LabeledOpenClose.vue';

import useEventBus from '@/shared/composables/useEventBus';
const eventBus = useEventBus();

import { useStore } from 'vuex';
const store = useStore();
const role = store.state.role;

import useTerms from '@/shared/composables/useTerms';
const { terms } = useTerms();

const provider = defineModel({ type: undefined });

const emit = defineEmits(['back', 'progress']);

const hours = ref([
  {
    day: 0,
    open: 700,
    close: 1800,
    included: false,
  },
  {
    day: 1,
    open: 700,
    close: 1800,
    included: false,
  },
  {
    day: 2,
    open: 700,
    close: 1800,
    included: false,
  },
  {
    day: 3,
    open: 700,
    close: 1800,
    included: false,
  },
  {
    day: 4,
    open: 700,
    close: 1800,
    included: false,
  },
  {
    day: 5,
    open: 700,
    close: 1800,
    included: false,
  },
  {
    day: 6,
    open: 700,
    close: 1800,
    included: false,
  },
]);

const locked = computed(() => !getSchema().definition.properties.hours.editRoles.includes(role));

function getAppendedHours() {
  return getSchema().meta.hours.append;
}

function getDayText(day) {
  return assets.assets.weekdays[day.day];
}

function getSchema() {
  return store.state.schemas[provider.value.schema_id];
}

function save() {
  provider.value.hours = hours.value
    .filter((day) => day.included)
    .map((day) => ({ day: day.day, open: day.open, close: day.close }));

  emit('progress', provider.value.hours ? 100 : 0);
  eventBus.blur();
}

function setHours() {
  if (provider.value.hours) {
    emit('progress', 100);
    hours.value = hours.value.map((hour) => {
      const oldDay = provider.value.hours.find((providerHour) => providerHour.day === hour.day);
      const newDay = hour;
      if (oldDay) {
        newDay.close = oldDay.close;
        newDay.included = true;
        newDay.open = oldDay.open;
        return newDay;
      }
      newDay.included = false;
      return newDay;
    });
  }
}

function updateDay(index, timeRange) {
  hours.value[index].open = timeRange[0];
  hours.value[index].close = timeRange[1];
  save();
}

watch(
  () => provider.value.hours,
  () => {
    setHours();
  },
  { deep: true, immediate: true },
);
</script>

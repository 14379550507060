<template>
  <div>
    <v-card
      class="pa-0"
      border
      flat
      tile
    >
      <v-card-text class="pa-3">
        <v-row dense>
          <SortableTableHeader
            @check="$emit('check:all')"
            ref="checkAll"
            :checkable="checkable"
            title="ID"
          />
          <SortableTableHeader
            cols="12"
            md="3"
            title="Application"
          />
          <SortableTableHeader
            cols="12"
            md="3"
            title="Business"
          />
          <SortableTableHeader
            @toggle="$emit('toggle:sort', $event)"
            :sort-dir="filters?.sort_dir"
            :sort-field="filters?.sort_field"
            field="status"
            title="Status"
          />
          <SortableTableHeader
            @toggle="$emit('toggle:sort', $event)"
            :sort-dir="filters?.sort_dir"
            :sort-field="filters?.sort_field"
            field="scheduled_date"
            title="Date"
          />
          <SortableTableHeader
            @toggle="$emit('toggle:sort', $event)"
            :sort-dir="filters?.sort_dir"
            :sort-field="filters?.sort_field"
            field="amount"
            title="Amount"
          />
          <SortableTableHeader
            @toggle="$emit('toggle:sort', $event)"
            :sort-dir="filters?.sort_dir"
            :sort-field="filters?.sort_field"
            field="amount_unpaid"
            title="Balance"
          />
        </v-row>
      </v-card-text>
    </v-card>

    <v-card
      v-for="(payment, index) in collection"
      :key="index"
      class="bt-0 pa-0"
      border
      flat
      tile
    >
      <v-card-text
        :class="payment.refund ? 'bg-accent' : ''"
        class="pa-3"
      >
        <v-row
          class="d-flex align-center"
          role="row"
          dense
        >
          <TableCell
            @check="$emit('check', payment.id)"
            @click="$emit('open', payment)"
            ref="paymentRow"
            :checkable="checkable"
            :content="payment.id.slice(0, 6).toUpperCase()"
            link
          />
          <TableCell
            :content="payment.application_name"
            :to="{
              name: payment.application_award_type == 'SubsidyAward' ? 'SubsidyShow' : 'GrantShow',
              params: { id: payment.application_id },
            }"
            cols="12"
            md="3"
          />
          <TableCell
            :content="payment.business_name"
            :to="{ name: 'BusinessShow', params: { businessId: payment.business_id } }"
            cols="12"
            md="3"
          />
          <TableCell :content="capitalize(payment.status)" />
          <TableCell
            :content="payment.scheduled_date"
            transform="date"
          />
          <TableCell
            :content="payment.adjusted_amount"
            transform="currency"
          />
          <TableCell
            :content="payment.amount_unpaid"
            transform="currency"
          />
        </v-row>
      </v-card-text>
    </v-card>

    <div
      v-if="processing"
      class="mxw-800 mx-auto py-12 ta-center"
    >
      <v-progress-circular
        color="primary"
        size="75"
        indeterminate
      />
    </div>

    <NullState
      v-if="!processing && collection.length == 0"
      title="No payments found"
    />
  </div>
</template>

<script setup>
import NullState from '@/shared/components/NullState.vue';
import SortableTableHeader from '@/shared/components/SortableTableHeader.vue';
import TableCell from '@/shared/components/TableCell.vue';
import { capitalize } from '@/plugins/filters';

const checkAll = ref(false);
const paymentRow = ref(null);

defineProps({
  checkable: {
    type: Boolean,
    default: true,
  },
  collection: {
    type: Array,
    default: null,
  },
  filters: {
    type: Object,
    default: null,
  },
  processing: Boolean,
});

defineEmits(['check', 'check:all', 'open', 'toggle:sort']);

defineExpose({
  checkAll,
  paymentRow,
});

onMounted(() => {
  return { capitalize };
});
</script>

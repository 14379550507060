<template>
  <v-card
    :href="asset.url"
    target="_blank"
    border
    flat
    tile
  >
    <v-card-text>
      <v-row>
        <v-col
          class="d-flex align-center fs-18 word-break-word"
          cols="10"
          data-cy="document_name"
        >
          <div
            :class="typeClass"
            class="d-inline-block fiv-sqo"
          />
          <div
            v-text="asset.name"
            class="d-inline-block"
            style="overflow: none"
          />
        </v-col>
        <v-col
          class="d-flex align-center justify-end ta-right"
          cols="2"
        >
          <v-btn
            @click="handleDestroy"
            :aria-label="$t('Delete asset')"
            :loading="processing"
            variant="text"
            icon
          >
            <v-icon>close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';

const emit = defineEmits(['destroy']);

const props = defineProps({
  asset: {
    type: Object,
    default: null,
  },
});

const processing = ref(false);
const typeClass = computed(() => {
  const classStr = [];

  classStr.push('fiv-size-md me-4');

  classStr.push(['fiv-icon', props.asset.file.metadata.mime_type.split('/')[1]].join('-'));

  return classStr.join(' ');
});

function handleDestroy(event) {
  event.preventDefault();
  processing.value = true;
  Api.organization.asset.destroy(props.asset.id, () => {
    emit('destroy');
  });
}
</script>

<template>
  <ResourceDialog
    @save="save"
    ref="dialog"
    :max-width="800"
    :processing="processing"
    :save-button-disabled="!newProperty.field || !newProperty.type"
    save-button-text="Add"
    title="Add property"
  >
    <template #form>
      <v-row>
        <LabeledTextfield
          v-model="newProperty.field"
          :hint="
            $t('Only alphanumeric characters allowed. Must start with a letter or underscore.')
          "
          cols="6"
          message="Name"
          persistent-hint
        />

        <LabeledTextfield
          v-model="newProperty.alias"
          :hint="$t('Short name recommended to appear concisely in reports')"
          cols="6"
          message="Alias"
          persistent-hint
        />

        <LabeledSimpleSelect
          v-model="newProperty.type"
          :items="TYPES_OTHER_THAN_OBJECT"
          cols="4"
          item-title="text"
          item-value="value"
          message="Type"
        />

        <LabeledSimpleSelect
          v-if="newProperty.type == 'string'"
          v-model="newProperty.format"
          :items="FORMAT_OPTIONS"
          cols="4"
          item-title="text"
          item-value="value"
          message="Format"
        />

        <LabeledSimpleSelect
          v-if="newProperty.type == 'array'"
          v-model="itemType"
          :items="itemOptions"
          cols="4"
          item-title="text"
          item-value="value"
          message="Item type"
        />

        <LabeledSimpleSelect
          v-if="newProperty.type"
          v-model="newProperty.role"
          :items="propertyRoleOptions"
          cols="4"
          item-title="text"
          item-value="value"
          message="Role"
        />

        <LabeledSimpleSelect
          v-model="newProperty.editRoles"
          :items="ROLE_OPTIONS"
          item-title="text"
          item-value="value"
          message="Edit Roles"
          multiple
        />
      </v-row>
    </template>
  </ResourceDialog>
</template>

<script setup>
import LabeledSimpleSelect from '@/shared/components/form/LabeledSimpleSelect.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import useSchemas from '@/admin/composables/useSchemas';

const emit = defineEmits(['create']);

const blankProperty = () => {
  return {
    alias: null,
    editRoles: [],
    viewRoles: [],
    format: null,
    field: '',
    role: null,
    type: null,
    enabled: true,
    reportable: true,
  };
};

const dialog = ref(null);
const itemType = ref(null);
const isItemsProperty = ref(false);
const newProperty = ref(blankProperty());
const parentField = ref(null);
const schemaId = ref(null);
const processing = ref(false);

const {
  createProperty,
  propertyRoleOptions,
  TYPES_OTHER_THAN_OBJECT,
  FORMAT_OPTIONS,
  ITEM_TYPE_OPTIONS,
  ROLE_OPTIONS,
} = useSchemas(newProperty);

const itemOptions = computed(() => {
  if (isItemsProperty.value) {
    return ITEM_TYPE_OPTIONS.filter((item) => item.value !== 'object');
  }
  return ITEM_TYPE_OPTIONS;
});

async function save() {
  processing.value = true;
  const success = createProperty(
    schemaId.value,
    parentField.value,
    newProperty.value,
    itemType.value,
  );
  processing.value = false;
  if (!success) return;

  close();
  emit('create');
}

function open(args) {
  newProperty.value = blankProperty();
  parentField.value = args.parentField;
  schemaId.value = args.schemaId;
  isItemsProperty.value = args.isItemsProperty;
  dialog.value.open();
}

function close() {
  dialog.value.close();
}

defineExpose({ open });

watch(
  () => newProperty.value.field,
  (newVal) => {
    if (newVal) {
      newProperty.value.field = newVal.toLowerCase().replace(' ', '_');
    }
  },
);
</script>

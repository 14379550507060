<template>
  <div v-if="schemas && schemaFunctions">
    <div v-if="schema.id">
      <div v-if="schema.definition">
        <template v-if="schema.definition.properties.custom">
          <v-card
            class="mb-4"
            color="transparent"
            dense
            flat
          >
            <SchemaElement
              @change="emit('change')"
              :key="customPropertiesKey"
              :definition="schema.definition"
              :depth="0"
              :details="false"
              :index="0"
              :is-child="false"
              :schema-functions="schemaFunctions"
              :schema-id="schema.id"
              :schemas="schemas"
              field="custom"
              title="Custom fields"
              children-removable
              open-now
            />
          </v-card>
        </template>

        <v-divider class="my-6" />

        <v-card
          v-if="schema.definition"
          class="mb-4"
          color="transparent"
          dense
          flat
        >
          <v-card-title class="fs-20 fw-500 px-0"> Standard fields </v-card-title>
          <v-card-text class="px-0 bg-white py-0">
            <v-expansion-panels
              class="bc-outlined-grey b-1"
              flat
              tile
            >
              <SchemaElement
                v-for="(entry, index) in standardEntries"
                @change="emit('change')"
                :key="entry[0]"
                :definition="schema.definition"
                :depth="1"
                :field="entry[0]"
                :index="index"
                :schema-functions="schemaFunctions"
                :schema-id="schema.id"
                :schemas="schemas"
                children-removable
                lock-format
                lock-type
              />
            </v-expansion-panels>
          </v-card-text>
        </v-card>
      </div>
    </div>
  </div>
</template>

<script setup>
import baseApi from '@/shared/services/bright_finder';
import organizationApi from '@/specialist/services/bright_finder';
import SchemaElement from '@/admin/components/schemas/SchemaElement.vue';

const emit = defineEmits(['change']);

const props = defineProps({
  schema: {
    type: Object,
    default: null,
  },
});

const schemas = ref(null);
const schemaFunctions = ref(null);

const standardEntries = computed(() => {
  return Object.entries(props.schema.definition.properties).filter(
    (entry) => !['custom', 'sources'].includes(entry[0]),
  );
});

const customPropertiesKey = computed(() => {
  return Object.keys(props.schema.definition.properties.custom.properties).join(',');
});

const loadSchemas = async () => {
  const resp = await baseApi.public_api.organization.schema.index();
  schemas.value = resp.data;
};

const loadSchemaFunctions = async () => {
  const resp = await organizationApi.organization.schema_function.index(props.schema.id);
  schemaFunctions.value = resp.data;
};

onMounted(async () => {
  await loadSchemas();
  await loadSchemaFunctions();
});
</script>

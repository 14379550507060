<template>
  <v-container
    v-if="schema"
    class="mxw-1200"
  >
    <TitleBar
      :back-name="getBackName()"
      :back-route="{ name: 'SchemaIndex', query: { data_type: schema.data_type } }"
      :name="schema.name"
    />

    <SectionHeader title="Overview" />

    <v-card
      class="mb-8"
      border
      flat
      tile
    >
      <v-card-text>
        <v-row>
          <LabeledTextfield
            v-model="schema.name"
            @input="changed = true"
            message="Name"
          />
          <LabeledTextarea
            v-model="schema.description"
            @input="changed = true"
            message="Description"
          />
          <LabeledTextfield
            v-model="schema.image_url"
            @input="changed = true"
            message="Image URL"
          />
          <LabeledSimpleSelect
            v-model="schema.associated_data_types"
            @input="changed = true"
            :items="schemaDataTypes"
            cols="6"
            item-title="name"
            item-value="id"
            message="Associated data types"
            chips
            deletable-chips
            multiple
          />
          <LabeledSimpleSelect
            v-model="schema.associated_schema_ids"
            @input="changed = true"
            :items="relevantSchemas"
            cols="6"
            item-title="name"
            item-value="id"
            message="Associated schemas"
            chips
            deletable-chips
            multiple
          />
        </v-row>
      </v-card-text>
    </v-card>

    <SchemaForm
      v-if="schema.schema_for_meta.properties"
      v-model="schema.meta"
      @update:model-value="changed = true"
      :schema="schema.schema_for_meta"
      title="Settings"
      flat
      outlined
    />

    <v-card
      border
      flat
      tile
    >
      <v-card-text>
        <v-row>
          <v-col>
            <v-btn
              @click="$refs.file_selector.click()"
              class="me-3"
              color="primary"
            >
              {{ $t('Import') }}
            </v-btn>
            <input
              @change="importJson"
              id="file_selector"
              ref="file_selector"
              accept=".json"
              class="hidden"
              type="file"
            />

            <v-menu>
              <template #activator="{ props }">
                <v-btn
                  v-bind="props"
                  color="primary"
                >
                  {{ $t('Export') }}
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="exportAs('text/csv')">
                  {{ $t('Export as CSV') }}
                </v-list-item>
                <v-list-item @click="exportAs('application/json')">
                  {{ $t('Export as JSON') }}
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-divider class="my-6" />

    <SectionHeader title="Teams" />

    <v-card
      v-for="team in teamAssignments"
      :key="team.id"
      class="mb-2 pa-3"
      border
      flat
      tile
    >
      <v-row class="d-flex align-center">
        <v-col>
          <span v-text="team.name" />
        </v-col>
        <v-col class="ta-right">
          <v-btn
            @click="removeTeam(team)"
            color="red"
            variant="text"
            icon
          >
            <v-icon icon="close" />
          </v-btn>
        </v-col>
      </v-row>
    </v-card>

    <p
      v-if="schema.team_assignments.length == 0"
      class="mb-0"
    >
      {{ $t('No assigned teams.') }}
    </p>

    <v-btn
      @click="$refs.newTeamAssignment.open()"
      class="mt-4"
      color="primary"
    >
      {{ $t('Add team') }}
    </v-btn>

    <v-divider class="my-6" />

    <ResourceDialog
      @save="addTeamAssignment"
      ref="newTeamAssignment"
      :fields="teamAssignmentValues"
      title="Add team"
    />

    <DangerBar
      @destroy="destroy"
      @duplicate="duplicate"
      :processing="processing"
    />

    <SaveBar
      @cancel="loadSchema"
      @save="save"
      :model-value="changed"
      :processing="processing"
    />
  </v-container>
</template>

<script setup>
import Api from '@/shared/services/all_bright_finder';
import DangerBar from '@/shared/components/DangerBar.vue';
import SchemaForm from '@/admin/components/schemas/SchemaForm.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import LabeledTextarea from '@/shared/components/form/LabeledTextarea.vue';
import LabeledSimpleSelect from '@/shared/components/form/LabeledSimpleSelect.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import SaveBar from '@/shared/components/form/SaveBar.vue';
import SectionHeader from '@/shared/components/content/SectionHeader.vue';
import TitleBar from '@/shared/components/TitleBar.vue';
import { toSentenceCase } from '@/shared/services/string-utils';
import { useRoute, useRouter } from 'vue-router';
import useEventBus from '@/shared/composables/useEventBus';

const eventBus = useEventBus();
const route = useRoute();
const router = useRouter();

const changed = ref(false);
const newTeamAssignment = ref(null);
const processing = ref(false);
const schema = ref(null);
const schemas = ref([]);
const teams = ref([]);

const schemaDataTypes = computed(() => {
  return new Set(schemas.value.map((schema) => schema.data_type));
});

const relevantSchemas = computed(() => {
  return schemas.value.filter((s) => schema.value.associated_data_types.includes(s.data_type));
});

const teamAssignments = computed(() =>
  teams.value.filter((team) =>
    schema.value.team_assignments.find((teamAssignment) => teamAssignment.team_id === team.id),
  ),
);

const teamAssignmentValues = computed(() => [
  {
    text: 'New team',
    value: 'team_id',
    items: unassignedTeams.value,
    itemText: 'name',
    itemValue: 'id',
  },
]);

const unassignedTeams = computed(() =>
  teams.value.filter(
    (team) =>
      !schema.value.team_assignments.find((teamAssignment) => teamAssignment.team_id === team.id),
  ),
);

function addTeamAssignment(team) {
  schema.value.team_assignments.push(team);
  newTeamAssignment.value.close();
  changed.value = true;
}

function destroy() {
  if (confirm('Are you sure you want to destroy this schema?')) {
    processing.value = true;

    Api.organization.schema.destroy(
      route.params.schemaId,
      () => {
        router.push({ name: 'SchemaIndex', query: { data_type: schema.value.data_type } });
      },
      (err) => {
        eventBus.$emit('chime', err.response.data.errors[0]);
      },
    );
  }
}

function duplicate() {
  if (confirm('Are you sure you want to duplicate this schema?')) {
    processing.value = true;

    Api.organization.schema.create(
      { copy_id: route.params.schemaId },
      (resp) => {
        router.push({ name: 'SchemaShow', params: { schemaId: resp.data.id } });
      },
      (err) => {
        eventBus.$emit('chime', err.response.data.errors[0]);
      },
    );
  }
}

async function exportAs(format) {
  const url = [Api.organization.schema.url(route.params.schemaId), format.split('/')[1]].join('.');

  window.open(url);
}

function getBackName() {
  const dataType = toSentenceCase(schema.value.data_type);
  return `${dataType} schemas`;
}

function importJson(selectEvent) {
  const reader = new FileReader();

  reader.addEventListener('load', (loadEvent) => {
    importMeta(loadEvent.target.result);
  });

  reader.readAsText(selectEvent.target.files[0]);
}

function importMeta(jsonString) {
  processing.value = true;

  Api.organization.schema.update(
    route.params.schemaId,
    { meta: JSON.parse(jsonString).meta },
    (resp) => {
      schema.value = resp.data;
      processing.value = false;
    },
    (err) => {
      eventBus.$emit('error', err);
    },
  );
}

async function loadSchema() {
  processing.value = true;
  const { data } = await Api.organization.schema.get(route.params.schemaId);
  schema.value = data;
  processing.value = false;
  changed.value = false;
}

async function loadSchemas() {
  const { data } = await Api.public_api.organization.schema.index();
  schemas.value = data.filter((iter) => iter.id && iter.id !== route.params.schemaId);
}

function loadTeams() {
  Api.organization.team.index({}, (resp) => {
    teams.value = resp.data;
  });
}

function removeTeam(team) {
  schema.value.team_assignments.splice(
    schema.value.team_assignments.findIndex((teamAssignment) => teamAssignment.team_id === team.id),
    1,
  );

  changed.value = true;
}

function save() {
  processing.value = true;

  Api.organization.schema.update(
    route.params.schemaId,
    schema.value,
    (resp) => {
      processing.value = false;
      schema.value = resp.data;
      changed.value = false;
      eventBus.chime('Schema saved.');
    },
    (err) => {
      processing.value = false;
      eventBus.$emit('chime', err.response.data.errors[0]);
    },
  );
}

watch(
  () => route.params.schemaId,
  async (newVal) => {
    if (newVal) {
      schema.value = null;
      void loadSchemas();
      void loadSchema();
      loadTeams();
    }
  },
  { immediate: true },
);

onMounted(() => {
  void loadSchema();
  void loadSchemas();
  loadTeams();
});
</script>

<template>
  <div>
    <v-container
      v-if="loaded"
      class="mxw-1200"
    >
      <BreadcrumbTitle
        :back-name="schema.name"
        name="Definition"
      />

      <SchemaEditor
        @change="handleChange"
        @save="$router.push({ name: 'SchemaIndex' })"
        :key="updateCounter"
        :schema="schema"
      />

      <v-divider class="my-6" />

      <SectionHeader title="Danger" />

      <v-row dense>
        <v-col
          class="mb-3"
          cols="12"
        >
          <div class="fs-16 fw-500 c-black">Be careful! This cannot be undone.</div>
        </v-col>
        <v-col cols="12">
          <v-menu>
            <template #activator="{ props }">
              <v-btn
                v-bind="props"
                :loading="processing"
                class="me-3"
                color="primary"
              >
                Import
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="jsonFileSelector.click()"> Import JSON </v-list-item>
              <v-list-item @click="csvFileSelector.click()"> Import CSV </v-list-item>
            </v-list>
          </v-menu>

          <input
            @change="importJson"
            id="file_selector"
            ref="jsonFileSelector"
            accept=".json"
            class="hidden"
            type="file"
          />

          <input
            @change="importCsv"
            id="file_selector"
            ref="csvFileSelector"
            accept=".csv"
            class="hidden"
            type="file"
          />

          <v-menu>
            <template #activator="{ props }">
              <v-btn
                v-bind="props"
                :loading="processing"
                color="primary"
              >
                Export
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="exportAs('text/csv')"> Export as CSV </v-list-item>
              <v-list-item @click="exportAs('application/json')"> Export as JSON </v-list-item>
            </v-list>
          </v-menu>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script setup>
import organizationApi from '@/specialist/services/bright_finder';
import BreadcrumbTitle from '@/specialist/components/navigation/BreadcrumbTitle.vue';
import SchemaEditor from '@/admin/components/schemas/SchemaEditor.vue';
import SectionHeader from '@/shared/components/content/SectionHeader.vue';
import { useRoute } from 'vue-router';

const route = useRoute();

const updateCounter = ref(0);
const schema = ref(null);
const csvFileSelector = ref(null);
const jsonFileSelector = ref(null);
const processing = ref(false);

const loaded = computed(() => schema.value !== null);

async function exportAs(format) {
  const url = [
    organizationApi.organization.schema.url(route.params.schemaId),
    format.split('/')[1],
  ].join('.');
  window.open(url);
}

async function handleChange() {
  await loadSchema();
  updateCounter.value++;
}

function importJson(selectEvent) {
  const reader = new FileReader();
  reader.addEventListener('load', async (loadEvent) => {
    await saveJson(loadEvent.target.result);
  });
  reader.readAsText(selectEvent.target.files[0]);
}

function importCsv(selectEvent) {
  const reader = new FileReader();
  reader.addEventListener('load', async (loadEvent) => {
    await saveCsv(loadEvent.target.result);
  });
  reader.readAsText(selectEvent.target.files[0]);
}

async function loadSchema() {
  const { data } = await organizationApi.organization.schema.get(route.params.schemaId);
  schema.value = data;
}

async function saveCsv(csvString) {
  processing.value = true;
  const resp = await organizationApi.organization.schema.schema_properties.upload(
    schema.value.id,
    csvString,
  );
  processing.value = false;
  if (resp?.status !== 201) return;

  window.location.reload();
}

async function saveJson(jsonString) {
  processing.value = true;
  const resp = await organizationApi.organization.schema.update(schema.value.id, {
    definition: { properties: JSON.parse(jsonString).definition.properties },
  });
  processing.value = false;
  if (resp?.status !== 200) return;

  window.location.reload();
}

onMounted(() => loadSchema());
</script>

<template>
  <v-container
    v-if="grantProgram"
    class="mxw-1200"
  >
    <TitleBar
      :back-route="{ name: 'GrantProgramIndex' }"
      :name="grantProgram.name"
      back-name="Grant programs"
    />

    <SectionHeader title="Overview" />
    <v-card
      class="mb-8"
      border
      flat
      tile
    >
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-row>
              <LabeledTextfield
                v-model="grantProgram.name"
                @input="changed = true"
                message="Name"
              />
              <LabeledTextarea
                v-model="grantProgram.description"
                @input="changed = true"
                class="mb-4"
                message="Description"
              />
              <LabeledTextfield
                v-model="grantProgram.image_url"
                @input="changed = true"
                message="Image URL"
              />
              <LabeledTextfield
                v-model="grantProgram.amount_min"
                @input="changed = true"
                cols="6"
                message="Amount minimum"
              />
              <LabeledTextfield
                v-model="grantProgram.amount_max"
                @input="changed = true"
                cols="6"
                message="Amount maximum"
              />
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-divider class="my-8" />

    <SectionHeader title="Features" />
    <v-card
      class="mb-8"
      border
      flat
      tile
    >
      <v-card-text>
        <v-row dense>
          <LabeledSwitch
            v-model="grantProgram.enable_priority"
            @input="changed = true"
            subtitle="Applications can be assigned a priority level that can be sorted and filtered on."
            title="Enable application priority assignment"
            divided-bottom
          />
          <LabeledSwitch
            v-model="grantProgram.enable_eligibility"
            @input="changed = true"
            subtitle="Providers will be shown a summary of their eligibility status and will only be able to complete applications when eligible."
            title="Display eligibility report"
            divided-bottom
          />
          <LabeledSwitch
            v-model="grantProgram.enable_esign"
            @input="changed = true"
            subtitle="Providers will be required to type or draw their signature before submitting."
            title="Enable E-Sign of application"
            divided-bottom
          />
          <LabeledSwitch
            v-model="grantProgram.enable_individual_review"
            @input="changed = true"
            subtitle="Applications can be assigned to individual team members."
            title="Enable individual review of application"
            divided-bottom
          />
          <LabeledSwitch
            v-model="grantProgram.enable_team_review"
            @input="changed = true"
            subtitle="Applications can be assigned to teams."
            title="Enable team review of application"
          />
        </v-row>
      </v-card-text>
    </v-card>

    <v-divider class="my-8" />

    <SectionHeader title="Content" />
    <v-card
      class="mb-8"
      border
      flat
      tile
    >
      <v-card-text>
        <v-row>
          <v-col>
            <v-select
              v-model="contentCategory"
              :items="contentCategories"
              variant="outlined"
              hide-details
            />
          </v-col>

          <template v-if="contentCategory == 'Eligibility'">
            <LabeledTextfield
              v-model="grantProgram.eligibility_title"
              @input="changed = true"
              message="Title"
            />
            <LabeledTextarea
              v-model="grantProgram.eligibility_subtitle"
              @input="changed = true"
              message="Subtitle"
            />
          </template>
        </v-row>
      </v-card-text>
    </v-card>

    <v-divider class="my-8" />

    <SectionHeader title="Access" />
    <v-card
      class="mb-8"
      border
      flat
      tile
    >
      <v-card-text>
        <v-row dense>
          <LabeledSwitch
            v-model="grantProgram.hidden"
            @input="changed = true"
            subtitle="Application will be accessible when hidden but won't be listed on grants page."
            title="Hide grant application"
            divided-bottom
          />
          <LabeledTextfield
            v-model="grantProgram.password"
            @input="changed = true"
            cols="12"
            message="Password protect grant"
          />
        </v-row>
      </v-card-text>
    </v-card>

    <v-divider class="my-8" />

    <SectionHeader title="Availability" />
    <v-card
      class="mb-8"
      border
      flat
      tile
    >
      <v-card-text>
        <v-row class="mb-3">
          <LabeledDateTimeZonePicker
            v-model="grantProgram.opens_at"
            @change="changed = true"
            cols="6"
            message="Open date"
          />
          <LabeledDateTimeZonePicker
            v-model="grantProgram.closes_at"
            @change="changed = true"
            cols="6"
            message="Close date"
          />
        </v-row>
        <div class="d-flex align-start">
          <v-switch
            v-model="grantProgram.enable_editing"
            @update:model-value="changed = true"
            class="mt-0 me-2"
            hide-details
            inset
          />
          <div>
            <div class="fs-16 mb-1">
              {{ $t("Providers can edit application after it's been submitted") }}
            </div>
            <div class="fs-16 c-light-black">
              {{
                $t(
                  'If this is enabled, providers will be able to edit their application until the close date.',
                )
              }}
            </div>
          </div>
        </div>
      </v-card-text>
    </v-card>

    <v-divider class="my-8" />

    <SectionHeader title="Data mappings" />
    <v-card
      class="mb-8"
      border
      flat
      tile
    >
      <v-card-text>
        <v-row>
          <LabeledSimpleSelect
            v-model="grantProgram.grant_schema_id"
            @change="changed = true"
            :items="grantSchemaItems"
            cols="4"
            item-title="name"
            item-value="id"
            message="Grant schema"
            chips
          />
          <LabeledSimpleSelect
            v-model="grantProgram.business_schema_id"
            @change="changed = true"
            :items="businessSchemaItems"
            cols="4"
            item-title="name"
            item-value="id"
            message="Business schema"
            chips
          />
          <LabeledSimpleSelect
            v-model="grantProgram.program_schema_id"
            @change="changed = true"
            :items="programSchemaItems"
            cols="4"
            item-title="name"
            item-value="id"
            message="Program schema"
            chips
          />
          <LabeledSimpleSelect
            v-model="grantProgram.provider_schema_id"
            @change="changed = true"
            :items="providerSchemaItems"
            cols="4"
            item-title="name"
            item-value="id"
            message="Provider schema"
            chips
          />
          <LabeledSimpleSelect
            v-model="grantProgram.claim_schema_id"
            @change="changed = true"
            :items="claimSchemaItems"
            cols="4"
            item-title="name"
            item-value="id"
            message="Claim schema"
            chips
          />
          <LabeledSimpleSelect
            v-model="grantProgram.associated_schema_ids"
            @change="changed = true"
            :items="otherSchemaItems"
            cols="12"
            item-title="name"
            item-value="id"
            message="Other associated schemas (reviews, agreements, etc)"
            chips
            deletable-chips
            multiple
          />
        </v-row>
      </v-card-text>
    </v-card>

    <v-divider class="my-8" />

    <SectionHeader title="Labels" />
    <v-card
      v-if="labels.length === 0"
      class="pa-4 mb-4"
      border
      flat
      tile
    >
      <div>
        {{ $t('You have not created any labels yet.') }}
      </div>
    </v-card>
    <v-card
      v-for="label in labels"
      :key="label.id"
      class="pa-4 mb-4"
      border
      flat
      tile
    >
      <v-row>
        <v-col>
          <v-menu
            location="left"
            max-height="300"
          >
            <template #activator="{ props }">
              <v-btn
                v-bind="props"
                class="me-3"
                size="small"
                variant="text"
                icon
              >
                <v-avatar
                  :color="label.color"
                  size="20"
                />
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                v-for="color in colorOptions"
                @click="changeLabelColor(label, color)"
                :key="color"
              >
                <template #prepend>
                  <v-avatar
                    :color="color"
                    size="20"
                  />
                </template>
              </v-list-item>
            </v-list>
          </v-menu>
          <span>{{ $t(label.name) }}</span>
        </v-col>

        <v-col class="ta-right">
          <ActionMenu
            @click:action:delete="deleteLabel(label)"
            @click:action:edit="$refs.addEditLabelDialog.open(label)"
            :items="[
              { avatar: 'edit', title: 'Edit', event: 'edit' },
              { avatar: 'delete', title: 'Delete', event: 'delete' },
            ]"
            button-icon="more_vert"
          />
        </v-col>
      </v-row>
    </v-card>

    <v-btn
      @click="$refs.addEditLabelDialog.open({})"
      :disabled="processing"
      color="primary"
    >
      {{ $t('Add label') }}
    </v-btn>

    <v-divider class="my-8" />

    <SectionHeader title="Attestation" />
    <v-card
      class="mb-8"
      border
      flat
      tile
    >
      <v-card-text>
        <v-row>
          <v-col>
            <v-select
              v-model="selectedLanguage"
              :items="organization.languages"
              variant="outlined"
              hide-details
            />
          </v-col>

          <LabeledTextarea
            v-model="grantProgram.attestation_text[selectedLanguageShort]"
            @change="changed = true"
            class="mb-4"
            message="Attestation"
          />
        </v-row>
      </v-card-text>
    </v-card>

    <v-divider class="my-8" />

    <DangerBar
      @destroy="destroy"
      @duplicate="duplicate"
      @publish="publish"
      @unpublish="unpublish"
      :processing="processing"
      :published="!!grantProgram.published_at"
      publishable
    />

    <SaveBar
      @cancel="load"
      @save="save"
      :model-value="changed"
      :processing="processing"
    />

    <ResourceDialog
      @save="addEditLabel"
      ref="addEditLabelDialog"
      :fields="[
        { text: 'Name', value: 'name', required: true },
        { text: 'Color', value: 'color', required: true, type: 'color-hex' },
      ]"
      :processing="processing"
      title="Add label"
    />

    <ConfirmDialog ref="confirmDialog" />
  </v-container>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import colors from 'vuetify/util/colors';
import ConfirmDialog from '@/shared/components/ConfirmDialog.vue';
import DangerBar from '@/shared/components/DangerBar.vue';
import LabeledDateTimeZonePicker from '@/shared/components/form/LabeledDateTimeZonePicker.vue';
import LabeledSimpleSelect from '@/shared/components/form/LabeledSimpleSelect.vue';
import LabeledSwitch from '@/shared/components/form/LabeledSwitch.vue';
import LabeledTextarea from '@/shared/components/form/LabeledTextarea.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import SaveBar from '@/shared/components/form/SaveBar.vue';
import SectionHeader from '@/shared/components/content/SectionHeader.vue';
import TitleBar from '@/shared/components/TitleBar.vue';
import useEventBus from '@/shared/composables/useEventBus';
import { useRoute, useRouter } from 'vue-router';

const eventBus = useEventBus();
const route = useRoute();
const router = useRouter();

const colorOptions = Object.values(colors).map((value) => value.lighten2);
const contentCategories = ['Eligibility'];
const contentCategory = 'Eligibility';

const addEditLabelDialog = ref(null);
const businessSchemaItems = ref([]);
const changed = ref(false);
const claimSchemaItems = ref([]);
const confirmDialog = ref(null);
const grantProgram = ref(null);
const grantSchemaItems = ref([]);
const labels = ref([]);
const organization = ref({});
const programSchemaItems = ref([]);
const providerSchemaItems = ref([]);
const processing = ref(false);
const otherSchemaItems = ref([]);
const selectedLanguage = ref(null);

const grantProgramId = computed(() => route.params.grantProgramId);

const selectedLanguageShort = computed(() => {
  return selectedLanguage.value ? selectedLanguage.value.split('-')[0] : null;
});

onMounted(() => load());

function destroy() {
  processing.value = true;
  Api.organization.grant_program.destroy(
    grantProgramId.value,
    () => {
      router.push({ name: 'GrantProgramIndex' });
    },
    (err) => {
      eventBus.chime(err.response.data.errors[0]);
    },
  );
}

async function duplicate() {
  const confirm = await confirmDialog.value.confirmWithText(
    'Are you sure you want to duplicate this program?',
  );
  if (!confirm) return;

  processing.value = true;
  Api.organization.grant_program.create(
    { copy_id: grantProgramId.value },
    () => {
      processing.value = false;
      eventBus.chime('Copied');
      router.push({ name: 'GrantProgramIndex' });
    },
    (err) => {
      eventBus.error(err);
    },
  );
}

function load() {
  void loadSchemas();
  loadGrantProgram();
  loadOrganization();
}

function loadGrantProgram() {
  Api.organization.grant_program.get(grantProgramId.value, (resp) => {
    grantProgram.value = resp.data;
    void loadLabels();
  });
}

function loadOrganization() {
  Api.organization.get((resp) => {
    organization.value = resp.data;
    selectedLanguage.value = organization.value.languages[0].value;
  });
}

async function loadSchemas() {
  const { data } = await Api.organization.schema.index();
  businessSchemaItems.value = data.filter((schema) => schema.data_type === 'Business');
  claimSchemaItems.value = data.filter((schema) => schema.data_type === 'Claim');
  grantSchemaItems.value = data.filter((schema) => schema.data_type === 'Grant');
  programSchemaItems.value = data.filter((schema) => schema.data_type === 'Program');
  providerSchemaItems.value = data.filter((schema) => schema.data_type === 'Provider');
  otherSchemaItems.value = data.filter((schema) =>
    ['Agreement', 'Form', 'Review'].includes(schema.data_type),
  );
}

function publish() {
  grantProgram.value.published_at = new Date().toISOString();
  save();
}

function save() {
  processing.value = true;
  Api.organization.grant_program.update(
    grantProgramId.value,
    grantProgram.value,
    (resp) => {
      processing.value = false;
      grantProgram.value = resp.data;
      changed.value = false;
    },
    (err) => {
      processing.value = false;
      eventBus.chime(err.response.data.errors[0]);
    },
  );
}

function unpublish() {
  grantProgram.value.published_at = null;
  save();
}

// LABELS
async function loadLabels() {
  const params = {
    grant_program_id: grantProgram.value.id,
  };
  const { data } = await Api.organization.label.index(params);
  labels.value = data;
  processing.value = false;
}

async function addEditLabel(label) {
  processing.value = true;
  if (label.id) {
    await Api.organization.label.update(label.id, label);
  } else {
    await Api.organization.label.create({ grant_program_id: grantProgramId.value, label });
  }
  await loadLabels();
  addEditLabelDialog.value.close();
}

function changeLabelColor(label, color) {
  label.color = color;
  void addEditLabel(label);
}

async function deleteLabel(label) {
  const confirm = await confirmDialog.value.confirmWithText(
    'Are you sure you want to remove this label? Applications that have been assigned this label will retain it until removed.',
  );
  if (!confirm) return;

  processing.value = true;
  await Api.organization.label.destroy(label.id);
  void loadLabels();
}
</script>

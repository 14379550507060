<template>
  <div v-if="report">
    <PageTitle
      flat
      outlined
    >
      <TitleBar
        :name="report.name"
        dense
      >
        <template
          v-if="$store.state.profile.org_reporting_edit"
          #inline-actions
        >
          <ActionMenu
            @click:action:delete="destroy"
            @click:action:duplicate="duplicateReport"
            @click:action:footer="$refs.editFooter.open(report)"
            @click:action:header="$refs.editHeader.open(report)"
            @click:action:lock="toggleLock"
            @click:action:rename="$refs.detailsDialog.open(report)"
            @click:action:schedule="$refs.scheduleDialog.open()"
            @click:action:share="displayShareToken"
            :items="actions"
            button-color="black"
            button-icon="more_vert"
          />

          <v-tooltip location="bottom">
            <template #activator="{ props }">
              <v-icon
                v-if="report.has_event_alert_configuration"
                v-bind="props"
                color="error"
                icon="error_outlined"
                size="24"
              />
            </template>
            <span>
              {{ $t(ASSOCIATED_TO_EVENT_ALERT_MESSAGE) }}
            </span>
          </v-tooltip>
        </template>

        <template #actions>
          <v-btn
            @click="createDownload"
            :disabled="processing"
            color="primary"
          >
            <span v-if="generating">{{ $t('Generating report...') }}</span>
            <span v-else>{{ $t('Download') }}</span>
          </v-btn>
        </template>
      </TitleBar>
    </PageTitle>

    <v-container fluid>
      <div
        v-if="$store.state.profile.org_reporting_edit"
        class="h-400 mb-2"
      >
        <v-row
          class="h-100pc"
          dense
        >
          <v-col
            :cols="report.joinable_data_types?.length > 0 ? 5 : 12"
            class="d-flex h-100pc"
          >
            <v-card
              class="w-100pc h-100pc oy-scroll"
              border
              flat
              tile
            >
              <v-card-title>
                <v-row
                  class="d-flex align-center ps-4 pe-2"
                  dense
                >
                  <v-col>
                    <span
                      v-text="report.data_type"
                      class="fs-18 fw-600"
                    />
                  </v-col>
                  <v-col class="d-flex align-center">
                    <v-text-field
                      v-model="mainSchemaQuery"
                      aria-label="Search field"
                      density="compact"
                      prepend-inner-icon="search"
                      variant="solo-filled"
                      flat
                      hide-details
                      rounded
                    />
                  </v-col>
                </v-row>
              </v-card-title>
              <v-divider />
              <v-card-text class="py-0 px-0">
                <v-list
                  class="pt-0 mt-0"
                  density="compact"
                >
                  <v-list-item
                    v-for="field in mainFilteredFields"
                    :key="field.key"
                    class="px-3"
                  >
                    <template #prepend>
                      <v-checkbox-btn
                        @update:model-value="updateColumn(field, report.data_type, $event)"
                        :aria-label="field.text"
                        :disabled="report.locked"
                        :model-value="
                          report.columns.find(
                            (column) =>
                              column.field == field.value && column.data_type == report.data_type,
                          )
                        "
                        :value="
                          report.columns.find(
                            (column) =>
                              column.field == field.value && column.data_type == report.data_type,
                          )
                        "
                        color="primary"
                      />
                    </template>
                    <v-list-item-title class="fs-15">
                      {{ field.text }}
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
          <template v-if="report.joinable_data_types?.length > 0">
            <v-col
              class="d-flex h-100pc"
              cols="7"
            >
              <v-card
                class="w-100pc h-100pc oy-scroll"
                border
                flat
                tile
              >
                <v-card-title
                  class="px-4 py-1 fs-12 fw-600 focus-visible"
                  tabindex="0"
                >
                  {{ $t('JOIN TABLES') }}
                </v-card-title>
                <v-divider />
                <v-card-text class="px-0 py-0">
                  <v-expansion-panels
                    variant="accordion"
                    flat
                  >
                    <JoinCard
                      v-for="dataType in report.joinable_data_types"
                      @add="addJoin(dataType, $event)"
                      @change:optional="requiredJoin(dataType, false)"
                      @change:required="requiredJoin(dataType, true)"
                      @column="
                        updateColumn($event.field, $event.dataType, $event.newVal, $event.list)
                      "
                      @remove="removeJoin(dataType)"
                      :key="dataType"
                      :data-type="dataType"
                      :display-name="report.joinable_data_type_friendly_names[dataType]"
                      :locked="report.locked"
                      :processing="processing"
                      :report="report"
                      :report-types="reportTypes"
                      :schemas="schemas"
                    />
                  </v-expansion-panels>
                </v-card-text>
              </v-card>
            </v-col>
          </template>
        </v-row>
      </div>

      <CountColumnEditor
        @change="saveCount"
        ref="countColumnEditor"
        :data-types="tables"
        :fields="report.valid_fields"
      />

      <ListColumnEditor
        @column="updateColumn($event.field, $event.dataType, $event.newVal, $event.list)"
        ref="listColumnEditor"
        :columns="report.columns"
        :data-types="tables"
        :fields="report.valid_fields"
      />

      <v-card
        border
        flat
        tile
      >
        <v-card-title class="fs-12 fw-600 px-4 py-1 d-flex">
          <span
            class="focus-visible"
            tabindex="0"
          >
            {{ $t('COLUMNS') }}
          </span>
          <v-spacer />
          <span
            class="fs-12 c-light-black me-2 focus-visible fw-500"
            tabindex="0"
          >
            Previewing first {{ preview.length > 10 ? 10 : preview.length }}
            <span v-if="!report.columns.some((column) => column.count_min || column.count_max)">
              of {{ report.result_count }}
            </span>
            rows
          </span>
          <v-divider
            class="mx-3"
            vertical
          />
          <template v-if="$store.state.profile.org_reporting_edit">
            <v-btn
              @click="$refs.listColumnEditor.open()"
              :loading="processing"
              class="focus-visible"
              color="primary"
              icon="list"
              size="small"
              tabindex="0"
              variant="text"
            />
            <v-divider
              class="mx-3"
              vertical
            />
          </template>
          <template v-if="$store.state.profile.org_reporting_edit">
            <v-btn
              @click="$refs.countColumnEditor.open()"
              :loading="processing"
              class="focus-visible"
              color="primary"
              icon="numbers"
              size="small"
              tabindex="0"
              variant="text"
            />
            <v-divider
              class="mx-3"
              vertical
            />
          </template>
          <v-btn
            @click="load()"
            :loading="processing"
            class="focus-visible"
            color="primary"
            icon="refresh"
            size="small"
            tabindex="0"
            variant="text"
          />
          <v-divider
            class="mx-3"
            vertical
          />
          <v-btn
            @click="scrollColumns(-250)"
            class="focus-visible me-2"
            color="primary"
            icon="chevron_left"
            size="small"
            tabindex="0"
            variant="text"
          />
          <v-btn
            @click="scrollColumns(250)"
            class="focus-visible"
            color="primary"
            icon="chevron_right"
            size="small"
            tabindex="0"
            variant="text"
          />
        </v-card-title>
        <v-divider />
        <v-card-text
          id="results_table"
          class="mb-4 ox-scroll"
        >
          <table>
            <thead>
              <tr>
                <th
                  v-for="(column, index) in report.columns"
                  :key="index"
                  class="fs-14 mnw-200 fw-600 bb-1 bc-extra-light-grey px-1 ta-left br-1 focus-visible"
                >
                  <v-row v-if="$store.state.profile.org_reporting_edit">
                    <v-col>
                      <template v-if="column.count">
                        <v-badge
                          :content="column.filters.length"
                          class="me-3"
                          color="primary"
                        >
                          <v-btn
                            @click="$refs.countColumnEditor.open(column)"
                            :disabled="report.locked"
                            class="focus-visible"
                            color="primary"
                            size="x-small"
                            tabindex="0"
                            variant="text"
                            icon
                          >
                            <v-icon
                              class="material-icons-outlined"
                              icon="numbers"
                            />
                          </v-btn>
                        </v-badge>
                      </template>
                      <template v-else>
                        <template v-if="column.filters.length > 0">
                          <v-badge
                            :content="column.filters.length"
                            class="me-3"
                            color="primary"
                          >
                            <v-btn
                              @click="draftFilters(column)"
                              :disabled="report.locked"
                              class="focus-visible"
                              color="primary"
                              size="x-small"
                              tabindex="0"
                              variant="text"
                              icon
                            >
                              <v-icon
                                class="material-icons-outlined"
                                icon="filter_list"
                              />
                            </v-btn>
                          </v-badge>
                        </template>
                        <template v-if="column.transforms && column.transforms.length > 0">
                          <v-badge
                            :content="column.transforms.length"
                            :offset-x="6"
                            :offset-y="6"
                            class="me-3"
                            color="primary"
                          >
                            <v-btn
                              @click="modifyTransforms(column)"
                              :disabled="report.locked"
                              color="primary"
                              size="x-small"
                              variant="text"
                              icon
                            >
                              <v-icon
                                class="material-icons-outlined"
                                icon="transform"
                              />
                            </v-btn>
                          </v-badge>
                        </template>
                      </template>
                    </v-col>
                    <v-col
                      class="d-flex align-center justify-end"
                      cols="4"
                    >
                      <template v-if="column.hidden">
                        <v-btn
                          @click="toggleVisibility(column)"
                          :disabled="report.locked"
                          class="me-2"
                          color="primary"
                          size="x-small"
                          variant="text"
                          icon
                        >
                          <v-icon
                            class="material-icons-outlined"
                            icon="visibility_off"
                          />
                        </v-btn>
                      </template>
                      <v-btn
                        @click="moveColumn(index, -1)"
                        :disabled="report.locked"
                        class="focus-visible"
                        color="black"
                        size="x-small"
                        tabindex="0"
                        variant="text"
                        icon
                      >
                        <v-icon>chevron_left</v-icon>
                      </v-btn>
                      <v-btn
                        @click="moveColumn(index, 1)"
                        :disabled="report.locked"
                        class="focus-visible"
                        color="black"
                        size="x-small"
                        variant="text"
                        icon
                      >
                        <v-icon>chevron_right</v-icon>
                      </v-btn>
                      <v-menu
                        location="bottom left"
                        dense
                      >
                        <template #activator="{ props }">
                          <v-btn
                            v-show="!report.locked"
                            v-bind="props"
                            color="black"
                            icon="more_vert"
                            size="x-small"
                            variant="text"
                          />
                        </template>
                        <v-list
                          class="py-0"
                          density="compact"
                          border
                          tile
                        >
                          <template v-if="!column.count">
                            <v-list-item @click="draftFilters(column)">
                              <template #prepend>
                                <v-icon
                                  class="material-icons-outlined"
                                  color="black"
                                >
                                  filter_list
                                </v-icon>
                              </template>
                              <v-list-item-title class="fs-14">
                                {{ $t('Add filter') }}
                              </v-list-item-title>
                            </v-list-item>
                            <v-divider />
                            <v-list-item @click="modifyTransforms(column)">
                              <template #prepend>
                                <v-icon
                                  class="material-icons-outlined"
                                  color="black"
                                >
                                  transform
                                </v-icon>
                              </template>
                              <v-list-item-title class="fs-14">
                                {{ $t('Find & replace') }}
                              </v-list-item-title>
                            </v-list-item>
                            <v-divider />
                            <v-list-item @click="toggleVisibility(column)">
                              <template #prepend>
                                <v-icon
                                  class="material-icons-outlined"
                                  color="black"
                                >
                                  {{ column.hidden ? 'visibility' : 'visibility_off' }}
                                </v-icon>
                              </template>
                              <v-list-item-title class="fs-14">
                                {{ column.hidden ? $t('Show column') : $t('Hide column') }}
                              </v-list-item-title>
                            </v-list-item>
                            <v-divider />
                          </template>
                          <v-list-item @click="removeColumn(column)">
                            <template #prepend>
                              <v-icon
                                color="black"
                                icon="delete"
                              />
                            </template>
                            <v-list-item-title class="fs-14">
                              {{ $t('Remove column') }}
                            </v-list-item-title>
                          </v-list-item>
                          <v-divider />
                          <v-list-item @click="$refs.renameColumnDialog.open(column)">
                            <template #prepend>
                              <v-icon
                                color="black"
                                icon="edit"
                              />
                            </template>
                            <v-list-item-title class="fs-14">
                              {{ $t('Rename column') }}
                            </v-list-item-title>
                          </v-list-item>
                          <v-divider />
                          <v-list-item @click="$refs.moveColumnDialog.open({ index: index })">
                            <template #prepend>
                              <v-icon
                                color="black"
                                icon="trending_flat"
                              />
                            </template>
                            <v-list-item-title class="fs-14">
                              {{ $t('Move column') }}
                            </v-list-item-title>
                          </v-list-item>
                          <v-divider />
                        </v-list>
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row
                    class="d-flex align-center py-1"
                    dense
                  >
                    <v-col cols="12">
                      <span
                        v-text="column.alias"
                        :title="'Column ' + index + 1"
                      />
                    </v-col>
                  </v-row>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(row, rowIndex) in preview.slice(0, 10)"
                :key="rowIndex"
              >
                <ReportTableData
                  v-for="(column, index) in report.columns"
                  :key="index"
                  :column="column"
                  :row="row"
                />
              </tr>
            </tbody>
          </table>
          <p
            v-if="preview.length == 0"
            class="fs-14 my-8"
          >
            Your report returned no results.
          </p>
          <p
            v-if="report.columns.some((column) => column.count_min || column.count_max)"
            class="fs-14 fw-800 mx-8 my-8"
          >
            Please download report for full results.
          </p>
        </v-card-text>
      </v-card>

      <ContentDialog
        ref="shareTokenDialog"
        :max-width="800"
        title="Shared link"
      >
        <template #content="{ data: formData }">
          <template v-if="formData?.url">
            <v-row>
              <v-col>
                <v-text-field
                  @click:append-inner="copyToClipboard(formData.url)"
                  :model-value="formData.url"
                  append-inner-icon="content_copy"
                  variant="filled"
                  hide-details
                  readonly
                />
              </v-col>
            </v-row>

            <v-row>
              <v-col>
                <v-btn
                  @click="destroyShareToken"
                  color="primary"
                  variant="outlined"
                >
                  {{ $t('Delete report sharing link') }}
                </v-btn>
              </v-col>
            </v-row>
          </template>
        </template>
      </ContentDialog>

      <ResourceDialog
        @save="updateDetails"
        ref="detailsDialog"
        :fields="[{ text: 'Name', value: 'name' }]"
        :processing="processing"
        title="Rename report"
      />

      <ResourceDialog
        @save="updateDetails"
        ref="editHeader"
        :fields="[{ text: 'Header', value: 'header', type: 'margin' }]"
        :processing="processing"
        title="Edit header"
      />

      <ResourceDialog
        @save="updateDetails"
        ref="editFooter"
        :fields="[{ text: 'Footer', value: 'footer', type: 'margin' }]"
        :processing="processing"
        title="Edit footer"
      />

      <ResourceDialog
        @save="renameColumn"
        ref="renameColumnDialog"
        :fields="[{ text: 'Column name', value: 'alias' }]"
        :processing="processing"
        title="Rename column"
      />

      <ResourceDialog
        @save="setTransforms"
        ref="transformDialog"
        title="Find & replace"
      >
        <template #form="{ localValue }">
          <p v-if="localValue && localValue.transforms && localValue.transforms.length == 0">
            {{ $t('Nothing has been replaced for this column.') }}
          </p>
          <div
            v-for="(transform, index) in localValue.transforms"
            :key="index"
          >
            <v-row dense>
              <v-col class="d-flex align-center">
                <v-btn
                  @click="localValue.transforms.splice(index, 1)"
                  color="red"
                  variant="text"
                  icon
                >
                  <v-icon>close</v-icon>
                </v-btn>
                <v-text-field
                  v-model="transform.target"
                  label="Find"
                  variant="filled"
                  hide-details
                />
              </v-col>
              <v-col class="d-flex align-center">
                <v-text-field
                  v-model="transform.value"
                  label="Replace"
                  variant="filled"
                  hide-details
                />
              </v-col>
            </v-row>
            <v-divider
              v-show="index + 1 < localValue.transforms.length"
              class="my-3"
            />
          </div>

          <v-btn
            @click="localValue.transforms.push({ target: null, value: null })"
            class="mt-3"
            color="primary"
            size="small"
            variant="outlined"
          >
            {{ $t('Add') }}
          </v-btn>
        </template>
      </ResourceDialog>

      <ResourceDialog
        @close="loadReportSchedules"
        @save="$refs.scheduleDialog.close()"
        ref="scheduleDialog"
        :max-width="1000"
        save-button-text="Done"
        title="Scheduled exports"
      >
        <template #form>
          <v-card
            v-for="(rs, index) in reportSchedules"
            :key="index"
            class="mb-3"
            border
          >
            <v-card-text>
              <v-row>
                <LabeledTextfield
                  v-model="rs.file_name"
                  @change="rs.changed = true"
                  md="3"
                  message="File name"
                />
                <LabeledTextfield
                  v-model="rs.sftp_host"
                  @change="rs.changed = true"
                  md="3"
                  message="SFTP Host"
                />
                <LabeledTextfield
                  v-model="rs.sftp_username"
                  @change="rs.changed = true"
                  md="3"
                  message="SFTP Username"
                  placeholder="Enter to save"
                  type="password"
                />
                <LabeledTextfield
                  v-model="rs.sftp_password"
                  @change="rs.changed = true"
                  md="3"
                  message="SFTP Password"
                  placeholder="Enter to save"
                  type="password"
                />
                <LabeledSimpleSelect
                  v-model="rs.week_days"
                  @change="rs.changed = true"
                  :items="weekDayOptions"
                  md="6"
                  message="Week days"
                  chips
                  deletable-chips
                  multiple
                />
                <LabeledTextfield
                  v-model="rs.time"
                  @change="rs.changed = true"
                  md="6"
                  message="Time"
                />
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-btn
                @click="destroyReportSchedule(rs)"
                color="red"
                variant="text"
              >
                {{ $t('Delete') }}
              </v-btn>
              <v-spacer />
              <v-btn
                @click="createOrUpdateReportSchedule(rs)"
                :disabled="reportScheduleSaveDisabled(rs)"
                :loading="processing"
                color="primary"
              >
                {{ $t('Save') }}
              </v-btn>
            </v-card-actions>
          </v-card>

          <v-row>
            <v-col>
              <v-btn
                @click="draftReportSchedule"
                color="primary"
                prepend-icon="add"
                size="small"
              >
                {{ $t('Add') }}
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </ResourceDialog>

      <ResourceDialog
        @save="saveFilters"
        ref="filterDialog"
        :max-width="600"
        :title="titleForFilterDialog(selectedColumn)"
      >
        <template
          v-if="selectedColumn"
          #form
        >
          <p v-if="selectedColumn.filters.length == 0">
            {{ $t('No filters applied.') }}
          </p>

          <div
            v-for="(filter, index) in selectedColumn.filters"
            :key="index"
          >
            <v-row
              class="d-flex align-center"
              dense
            >
              <v-col class="d-flex flex-grow-0 flex-shrink-0">
                <v-btn
                  @click="selectedColumn.filters.splice(index, 1)"
                  color="primary"
                  variant="text"
                  icon
                >
                  <v-icon>close</v-icon>
                </v-btn>
              </v-col>

              <v-col class="d-flex flex-grow-1 flex-shrink-0">
                <v-row dense>
                  <template v-if="selectedColumn.list">
                    <v-col cols="12">
                      <v-autocomplete
                        v-model="selectedColumn.filters[index].field"
                        :items="columnsFor(selectedColumn)"
                        item-title="title"
                        item-value="value"
                        prefix="Column:"
                        variant="filled"
                        chips
                        hide-details
                      />
                    </v-col>
                  </template>

                  <v-col cols="12">
                    <v-autocomplete
                      v-model="selectedColumn.filters[index].comparator"
                      :items="Object.values(comparators)"
                      prefix="Condition:"
                      variant="filled"
                      chips
                      hide-details
                    />
                  </v-col>

                  <v-col
                    v-if="comparators[selectedColumn.filters[index].comparator]"
                    v-show="comparators[selectedColumn.filters[index].comparator].compareable"
                    class="d-flex align-center"
                    cols="12"
                  >
                    <template
                      v-if="comparators[selectedColumn.filters[index].comparator].type == 'number'"
                    >
                      <v-text-field
                        v-model="selectedColumn.filters[index].value"
                        prefix="Number:"
                        type="number"
                        variant="filled"
                        hide-details
                        persistent-prefix
                      />
                    </template>

                    <template
                      v-if="comparators[selectedColumn.filters[index].comparator].type == 'string'"
                    >
                      <v-row
                        v-if="
                          comparators[selectedColumn.filters[index].comparator].format == 'date'
                        "
                        dense
                      >
                        <LabeledDatePicker
                          v-if="selectedColumn.format == 'date'"
                          v-model="selectedColumn.filters[index].value"
                          filled
                        />

                        <LabeledDateTimeZonePicker
                          v-if="selectedColumn.format == 'date-time'"
                          v-model="selectedColumn.filters[index].value"
                          :cols="12"
                          filled
                        />
                      </v-row>
                      <v-textarea
                        v-if="
                          comparators[selectedColumn.filters[index].comparator].format != 'date'
                        "
                        v-model="selectedColumn.filters[index].value"
                        rows="1"
                        variant="filled"
                        auto-grow
                        hide-details
                      />
                    </template>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-divider
              v-show="index + 1 < selectedColumn.filters.length"
              class="my-3"
            />
          </div>

          <v-row>
            <v-col class="d-flex justify-start">
              <v-btn
                @click="selectedColumn.filters.push({})"
                class="mt-4"
                color="primary"
                size="small"
              >
                {{ $t('Add') }}
              </v-btn>
            </v-col>
          </v-row>
        </template>
      </ResourceDialog>

      <ResourceDialog
        @save="moveColumnTo"
        ref="moveColumnDialog"
        :fields="[{ text: 'New column number', value: 'destination', required: true }]"
        title="Move column"
        close-on-save
      />
    </v-container>
  </div>
</template>

<script>
import API from '@/shared/mixins/api';
import CountColumnEditor from '@/specialist/components/report/CountColumnEditor.vue';
import Copy from '@/shared/mixins/copy';
import JoinCard from '@/specialist/components/JoinCard.vue';
import ListColumnEditor from '@/specialist/components/report/ListColumnEditor.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import reportComparators from '@/specialist/services/report-comparators';
import reportWeekdayOptions from '@/specialist/services/report-weekday-options';
import ReportTableData from '../components/report/ReportTableData.vue';
import ActionMenu from '@/shared/components/ActionMenu.vue';
import TitleBar from '@/shared/components/TitleBar.vue';
import PageTitle from '@/shared/components/PageTitle.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import LabeledSimpleSelect from '@/shared/components/form/LabeledSimpleSelect.vue';
import LabeledDatePicker from '@/shared/components/form/LabeledDatePicker.vue';
import ContentDialog from '@/shared/components/ContentDialog.vue';

const ASSOCIATED_TO_EVENT_ALERT_MESSAGE = 'This report is associated to an event alert.';

export default {
  compatConfig: { MODE: 3 },

  components: {
    CountColumnEditor,
    ReportTableData,
    JoinCard,
    ListColumnEditor,
    ResourceDialog,
    ActionMenu,
    TitleBar,
    PageTitle,
    LabeledTextfield,
    LabeledSimpleSelect,
    LabeledDatePicker,
    ContentDialog,
  },

  mixins: [API, Copy],

  data() {
    return {
      ASSOCIATED_TO_EVENT_ALERT_MESSAGE,
      comparators: reportComparators,
      data: [],
      generating: false,
      joins: [],
      mainSchemaQuery: null,
      preview: null,
      processing: false,
      report: null,
      actions: [],
      reportSchedules: [],
      schemas: [],
      selectedColumn: null,
      weekDayOptions: reportWeekdayOptions,
    };
  },

  computed: {
    tables() {
      if (!this.report) return [];

      return [
        ...[{ data_type: this.report.data_type, schema_id: this.report.schema_id }],
        ...this.report.joins,
      ];
    },

    mainFilteredFields() {
      if (this.mainSchemaQuery) {
        return this.report.valid_fields[this.report.data_type].filter((field) =>
          field.text.toLowerCase().includes(this.mainSchemaQuery.toLowerCase()),
        );
      }
      return this.report.valid_fields[this.report.data_type];
    },

    scrollNeeded() {
      return this.report ? window.innerWidth < this.report.columns.length * 250 + 60 : false;
    },
  },

  watch: {
    '$route.params.reportId': {
      immediate: true,
      handler() {
        this.load();
        this.loadReportSchedules();
      },
    },
  },

  methods: {
    addJoin(dataType, schemaId) {
      this.joins.push({ schema_id: schemaId, data_type: dataType });
      this.save();
    },

    blankReportSchedule() {
      return {
        report_id: this.$route.params.reportId,
        file_name: null,
        sftp_host: null,
        sftp_password: null,
        sftp_username: null,
        time: null,
      };
    },

    checkDownload(id, attempts) {
      this.api.organization.report.report_download.get(this.report.id, id, (resp) => {
        if (resp.data.ready_at) {
          const filename = [
            this.report.name.replace(/\s/, '_'),
            new Date().toLocaleDateString(),
          ].join('_');

          const safeFileName = encodeURIComponent(filename);
          window.open(
            this.api.organization.report.report_download.downloadUrl(
              this.report.id,
              safeFileName,
              resp.data,
            ),
          );
          this.generating = false;
          this.processing = false;
        } else if (attempts < 120) {
          setTimeout(() => {
            this.checkDownload(id, attempts + 1);
          }, 2000);
        } else {
          this.generating = false;
          this.processing = false;
          this.$eventBus.$emit(
            'chime',
            'Something went wrong with you download. Please contact support@getbridgecare.com for assistance.',
          );
        }
      });
    },

    columnsFor(column) {
      return this.report.valid_fields[column.data_type].map((field) => ({
        title: field.text,
        value: field.value,
      }));
    },

    createDownload() {
      this.generating = true;
      this.processing = true;
      this.api.organization.report.report_download.create(
        this.report.id,
        { format: 'text/csv' },
        (resp) => {
          this.checkDownload(resp.data.id, 0);
        },
        (err) => {
          this.generating = false;
          this.processing = false;
          this.$eventBus.$emit('chime', err.response.data.errors.join('. '));
        },
      );
    },

    createOrUpdateReportSchedule(rs) {
      this.processing = true;
      if (rs.id) {
        this.api.organization.report.report_schedule.update(
          this.$route.params.reportId,
          rs.id,
          { ...rs, delivery_method: 'sftp' },
          () => {
            this.processing = false;
            this.loadReportSchedules();
            this.$eventBus.$emit('chime', 'Saved');
          },
          (err) => {
            this.processing = false;
            this.$eventBus.$emit('chime', err.response.data.errors[0]);
          },
        );
      } else {
        this.api.organization.report.report_schedule.create(
          this.$route.params.reportId,
          { ...rs, delivery_method: 'sftp' },
          () => {
            this.processing = false;
            this.loadReportSchedules();
            this.$eventBus.$emit('chime', 'Saved');
          },
          (err) => {
            this.processing = false;
            this.$eventBus.$emit('chime', err.response.data.errors[0]);
          },
        );
      }
    },

    async destroyShareToken() {
      // eslint-disable-next-line no-alert
      if (!confirm('Are you sure you want to delete this report sharing link?')) return;

      await this.api.organization.report.share_token.destroy(this.report.id);
      this.$refs.shareTokenDialog.close();
    },

    async displayShareToken() {
      const resp = await this.api.organization.report.share_token.get(this.report.id);
      let token = resp.data.share_token;

      if (!token) {
        // eslint-disable-next-line no-alert
        if (!confirm('Are you sure you want to share this report?')) return;

        const shareResp = await this.api.organization.report.share_token.create(this.report.id);
        token = shareResp.data.share_token;
      }

      const apiOrigin = new URL(window.api_url).origin;
      const url = `${apiOrigin}/api/shared/reports/${this.report.id}?share_token=${token}`;

      this.$refs.shareTokenDialog.open({ url });
    },

    duplicateReport() {
      this.processing = true;
      const dup = JSON.parse(JSON.stringify(this.report));
      dup.name = ['Copy of ', dup.name].join(' ');
      dup.locked = false;
      this.api.organization.report.create(
        dup,
        (resp) => {
          this.$eventBus.$emit('chime', 'Report duplicated');
          this.$router.push({ params: { reportId: resp.data.id } });
          this.processing = false;
        },
        (err) => {
          this.$eventBus.$emit('chime', err.response.data.errors[0]);
        },
      );
    },

    destroy() {
      // eslint-disable-next-line no-alert
      if (window.confirm(`Are you sure you want to delete the report "${this.report.name}"?`)) {
        this.api.organization.report.destroy(this.report.id, () => {
          this.$router.replace({ name: 'ReportIndex' });
        });
      }
    },

    destroyReportSchedule(rs) {
      // eslint-disable-next-line no-alert
      if (window.confirm('Are you sure you want to delete this scheduled export?')) {
        this.api.organization.report.report_schedule.destroy(this.report.id, rs.id, () => {
          this.loadReportSchedules();
        });
      }
    },

    draftFilters(column) {
      this.selectedColumn = JSON.parse(JSON.stringify(column));
      this.$refs.filterDialog.open();
    },

    draftReportSchedule() {
      this.reportSchedules.push(this.blankReportSchedule());
    },

    getActions() {
      const actions = [
        { event: 'duplicate', title: 'Duplicate report', avatar: 'file_copy' },
        { event: 'schedule', title: 'Schedule export', avatar: 'ios_share' },
      ];
      if (!this.report.locked) {
        actions.push({ event: 'delete', title: 'Delete report', avatar: 'delete' });
        actions.push({ event: 'rename', title: 'Rename report', avatar: 'edit' });
        actions.push({ event: 'header', title: 'Edit header', avatar: 'edit' });
        actions.push({ event: 'footer', title: 'Edit footer', avatar: 'edit' });
      }
      if (
        this.$store.state.config.features.enable_report_sharing &&
        this.$store.state.profile.org_reporting_admin
      ) {
        actions.push({
          event: 'share',
          title: 'Share report',
          avatar: 'link',
        });
      }
      if (
        this.$store.state.profile.org_reporting_admin ||
        this.report.owner_id === this.$store.state.profile.id
      ) {
        actions.push({
          event: 'lock',
          title: this.report.locked ? 'Unlock report' : 'Lock report',
          avatar: this.report.locked ? 'lock_open' : 'lock',
        });
      }
      return actions;
    },

    load() {
      this.api.organization.report.get(this.$route.params.reportId, (resp) => {
        this.report = resp.data;
        if (this.report.errors.length > 0) {
          this.$eventBus.$emit('longChime', this.report.errors[0]);
        }
        this.preview = resp.data.preview;
        this.joins = resp.data.joins.map((join) => {
          let required = join.required;
          if (required === undefined) required = true;
          return { ...join, required };
        });
        this.actions = this.getActions();
      });

      this.loadReportTypes();
      this.loadSchemas();
    },

    loadReportSchedules() {
      this.api.organization.report.report_schedule.index(this.$route.params.reportId, (resp) => {
        this.reportSchedules = resp.data.map((rs) => ({ ...rs, changed: false }));
      });
    },

    async loadReportTypes() {
      this.reportTypes = (await this.api.organization.reportDataType.index()).data;
    },

    loadSchemas() {
      this.api.public_api.organization.schema.index((resp) => {
        this.schemas = resp.data;
      });
    },

    modifyTransforms(column) {
      const newColumn = JSON.parse(JSON.stringify(column));
      if (!newColumn.transforms) {
        newColumn.transforms = [];
      }
      this.$refs.transformDialog.open(newColumn);
    },

    moveColumn(index, dir) {
      const column = this.report.columns[index];
      this.report.columns.splice(index, 1);
      this.report.columns.splice(index + dir, 0, column);
      this.save();
    },

    moveColumnTo(newVal) {
      const destination = parseInt(newVal.destination, 10);
      if (destination >= 1 && destination <= this.report.columns.length) {
        const column = this.report.columns[newVal.index];
        this.report.columns.splice(newVal.index, 1);
        this.report.columns.splice(destination - 1, 0, column);
        this.save();
      }
    },

    removeColumn(column) {
      const index = this.report.columns.findIndex((col) => col.key === column.key);
      this.report.columns.splice(index, 1);
      this.save();
    },

    removeJoin(dataType) {
      this.joins.splice(
        this.joins.findIndex((join) => join.data_type === dataType),
        1,
      );
      this.report.columns = this.report.columns.filter((column) => column.data_type !== dataType);
      this.save();
    },

    renameColumn(newVal) {
      this.report.columns.find((column) => column.key === newVal.key).alias = newVal.alias;
      this.save();
      this.$refs.renameColumnDialog.close();
    },

    reportScheduleSaveDisabled(reportSchedule) {
      return (
        !reportSchedule.changed ||
        !reportSchedule.sftp_password ||
        !reportSchedule.sftp_username ||
        !reportSchedule.file_name ||
        !reportSchedule.sftp_host ||
        !reportSchedule.week_days ||
        reportSchedule.week_days.length == 0 ||
        !reportSchedule.time
      );
    },

    requiredJoin(dataType, value) {
      this.joins.find((join) => join.data_type === dataType).required = value;
      this.save();
    },

    save() {
      this.processing = true;

      const params = {
        columns: this.report.columns,
        fields: this.report.fields,
        header: this.report.header,
        footer: this.report.footer,
        filters: this.report.filters,
        joins: this.joins,
        name: this.report.name,
      };

      this.api.organization.report.update(
        this.report.id,
        params,
        (resp) => {
          this.report = resp.data;
          this.preview = resp.data.preview;
          this.$refs.detailsDialog.close();
          this.$refs.editHeader.close();
          this.$refs.editFooter.close();
          this.$refs.filterDialog.close();
          this.$refs.transformDialog.close();
          this.processing = false;
          if (this.report.errors.length > 0) {
            this.$eventBus.$emit('longChime', this.report.errors[0]);
          }
          this.$refs.countColumnEditor.close();
        },
        (err) => {
          this.$eventBus.$emit('error', err);
          this.processing = false;
        },
      );
    },

    saveCount(newVal) {
      const columnParams = newVal;
      const existingColumnIndex = this.report.columns.findIndex(
        (column) => column.key === columnParams.key,
      );
      if (existingColumnIndex >= 0) {
        this.report.columns.splice(existingColumnIndex, 1, columnParams);
      } else {
        columnParams.data_type = this.report.data_type;
        this.report.columns.push(columnParams);
      }
      this.save();
    },

    saveFilters() {
      this.report.columns.find(
        (column) =>
          column.field === this.selectedColumn.field &&
          column.data_type === this.selectedColumn.data_type,
      ).filters = this.selectedColumn.filters;
      this.save();
    },

    scrollColumns(pixels) {
      document.getElementById('results_table').scrollLeft += pixels;
    },

    setTransforms(newVal) {
      this.report.columns.find(
        (column) => column.field === newVal.field && column.data_type === newVal.data_type,
      ).transforms = newVal.transforms;
      this.save();
    },

    titleForFilterDialog(selectedColumn) {
      if (!selectedColumn?.alias) {
        return 'Add filters';
      }

      return `Add filters to ${selectedColumn.alias}`;
    },

    async toggleLock() {
      await this.api.organization.report.update(this.report.id, { locked: !this.report.locked });
      this.load();
    },

    toggleVisibility(column) {
      // eslint-disable-next-line no-param-reassign
      column.hidden = !column.hidden;
      this.save();
    },

    updateColumn(field, fieldDataType, newVal, list) {
      const columnIndex = this.report.columns.findIndex(
        (column) => column.data_type === fieldDataType && column.field === field.value,
      );

      if (columnIndex >= 0) {
        this.report.columns.splice(columnIndex, 1);
      }

      if (newVal === true) {
        const key = [fieldDataType, field.value].join('.');

        let fieldSchema;

        if (fieldDataType === this.report.data_type) {
          if (this.report.schema_id) {
            fieldSchema = this.schemas.find((schema) => schema.id === this.report.schema_id);
          } else {
            fieldSchema = this.schemas.find((schema) => schema.data_type === fieldDataType);
          }
        } else {
          const joinTable = this.report.joins.find((join) => join.data_type === fieldDataType);
          if (!joinTable) {
            this.$eventBus.$emit(
              'chime',
              'Unknown error occurred - column data type cannot be found',
            );
            return;
          }

          if (joinTable.schema_id) {
            fieldSchema = this.schemas.find((schema) => schema.id === joinTable.schema_id);
          } else {
            fieldSchema = this.schemas.find((schema) => schema.data_type === fieldDataType);
          }

          if (!fieldSchema) {
            this.$eventBus.$emit(
              'chime',
              'Unknown error occurred - column data type cannot be found',
            );
            return;
          }
        }

        const keyParts = field.value.split('.');
        const propertyName = keyParts[keyParts.length - 1];
        let transforms;

        if (keyParts.includes('custom')) {
          transforms =
            fieldSchema.definition.properties.custom.properties[propertyName].reportingTransforms ||
            [];
        } else if (keyParts.includes('highlights')) {
          transforms =
            fieldSchema.definition.properties.highlights.properties[propertyName]
              .reportingTransforms || [];
        } else if (keyParts.includes('origin')) {
          transforms =
            fieldSchema.definition.properties.origin.properties[propertyName].reportingTransforms ||
            [];
        } else if (keyParts.includes('destination')) {
          transforms =
            fieldSchema.definition.properties.destination.properties[propertyName]
              .reportingTransforms || [];
        } else {
          transforms = fieldSchema.definition.properties[propertyName].reportingTransforms || [];
        }

        let alias;

        if (
          fieldDataType === this.report.data_type ||
          (field.text && field.text.includes(fieldDataType))
        ) {
          alias = field.text;
        } else {
          alias = [
            this.report.joinable_data_type_friendly_names[fieldDataType],
            field.text || field.value,
          ].join('.');
        }

        const params = {
          alias,
          field: field.value,
          key,
          data_type: fieldDataType,
          filters: [],
          group: false,
          sum: false,
          list,
          transforms,
        };

        this.report.columns.push(params);
      }
      this.save();
    },

    updateDetails(editedReport) {
      this.report.name = editedReport.name;
      this.report.header = editedReport.header;
      this.report.footer = editedReport.footer;
      this.save();
    },

    updateJoin(index, newVal) {
      if (newVal.data_type && newVal.fields) {
        this.joins[index] = newVal;
        this.save();
      }
    },
  },
};
</script>

<template>
  <CaseReview
    :back-to="backTo"
    :can-delete="$store.state.profile.org_forms_delete || $store.state.profile.org_forms_admin"
    :case-id="$route.params.formId"
    :case-repository="Api.organization.form"
    :labels-editable="!readonly"
    :readonly="readonly"
    case-type="Form"
  />
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import CaseReview from '@/specialist/components/CaseReview.vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';

const route = useRoute();
const store = useStore();

const backTo = computed(() => {
  if (route.query?.back === 'subsidy') return 'SubsidyShow';
  if (route.query?.back === 'provider') return 'ProviderShow';
  return 'FormIndex';
});

const readonly = computed(() => {
  return !(store.state?.profile?.org_forms_edit || store.state?.profile?.org_forms_admin);
});
</script>

export default function useCriteriaEditor() {
  const comparators = [
    { text: 'equals', value: 'equals', comparable: true },
    { text: 'not equals', value: 'not_equals', comparable: true },
    { text: 'is true', value: 'is_true', comparable: false },
    { text: 'is false', value: 'is_false', comparable: false },
    { text: 'contains', value: 'contains', comparable: true },
    { text: 'not_contains', value: 'not_contains', comparable: true },
    { text: 'blank', value: 'blank', comparable: false },
    { text: 'not blank', value: 'not_blank', comparable: false },
    { text: 'earlier than', value: 'earlier_than', comparable: true },
    { text: 'later than', value: 'later_than', comparable: true },
    { text: 'greater than', value: 'greater_than', comparable: true },
    { text: 'greater than or equal to', value: 'greater_than_or_equals', comparable: true },
    { text: 'less than', value: 'less_than', comparable: true },
    { text: 'less than or equal to', value: 'less_than_or_equals', comparable: true },
    { text: 'in', value: 'in', comparable: true },
    { text: 'not in', value: 'not_in', comparable: true },
  ];

  function isCriteriaEditorValid(criterion, schema) {
    return (
      criterion.field &&
      criterion.comparator &&
      (comparators.find((comp) => comp.value === criterion.comparator && comp.comparable)
        ? criterion.value
        : true) &&
      (criterion?.data_type || schema?.data_type)
    );
  }

  function validateCriteriaEditor(question, schema) {
    const validatedCriteria = question?.conditions?.filter((criterion) =>
      isCriteriaEditorValid(criterion, schema),
    );
    return validatedCriteria.length === question?.conditions?.length;
  }

  return { comparators, validateCriteriaEditor };
}

<template>
  <ResourceDialog
    @change="handleChange"
    @save="handleSave"
    @search="searchProviders"
    ref="dialog"
    :fields="createFields"
    :processing="processing"
    title="Link existing record"
  />
</template>

<script setup>
import api from '@/specialist/services/bright_finder';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import { useStore } from 'vuex';

const store = useStore();

const emit = defineEmits(['change']);

const props = defineProps({
  caseId: {
    type: String,
    required: true,
  },
  caseType: {
    type: String,
    required: true,
  },
  linkedRecords: {
    type: Array,
    required: true,
  },
  schema: {
    type: Object,
    required: true,
  },
});

const assessments = ref([]);
const backgroundChecks = ref([]);
const complaints = ref([]);
const dialog = ref(false);
const forms = ref([]);
const providerFilterItems = ref([]);
const processing = ref(false);
const selectedType = ref(null);
const licenses = ref([]);

const createFields = computed(() => {
  let recordTypes = getRecordTypes();

  const selectTypeField = {
    items: recordTypes,
    itemText: 'name',
    itemValue: 'value',
    text: 'Type',
    value: 'type',
    required: true,
    multiple: false,
  };

  const fields = [selectTypeField];

  if (selectedType.value === 'Assessment') {
    const selectAssessmentField = {
      items: assessments.value,
      itemText: 'name',
      itemValue: 'id',
      multiple: false,
      required: true,
      search: true,
      text: 'Assessment',
      value: 'assessment_id',
    };

    fields.push(selectAssessmentField);
  }

  if (selectedType.value === 'BackgroundCheck') {
    const selectBackgroundCheckField = {
      items: backgroundChecks.value,
      itemText: 'name',
      itemValue: 'id',
      multiple: false,
      required: true,
      search: true,
      text: 'Background check',
      value: 'background_check_id',
    };

    fields.push(selectBackgroundCheckField);
  }

  if (selectedType.value === 'Provider') {
    const selectProviderField = {
      items: providerFilterItems.value,
      itemText: 'name',
      itemValue: 'id',
      multiple: false,
      required: true,
      search: true,
      text: 'Provider',
      value: 'provider_id',
    };

    fields.push(selectProviderField);
  }

  if (selectedType.value === 'Complaint') {
    const selectComplaintField = {
      items: complaints.value,
      itemText: 'name',
      itemValue: 'id',
      multiple: false,
      required: true,
      text: 'Complaint',
      value: 'complaint_id',
    };

    fields.push(selectComplaintField);
  }

  if (selectedType.value === 'Form') {
    const selectFormField = {
      items: forms.value,
      itemText: 'name',
      itemValue: 'id',
      multiple: false,
      required: true,
      text: 'Form',
      value: 'form_id',
    };
    fields.push(selectFormField);
  }

  if (selectedType.value === 'License') {
    const selectLicenseField = {
      items: licenses.value,
      itemText: 'name',
      itemValue: 'id',
      multiple: false,
      required: true,
      text: 'License',
      value: 'license_id',
    };

    fields.push(selectLicenseField);
  }

  return fields;
});

const linkedProviderRecord = computed(() => {
  return props.linkedRecords.find((record) => record.type === 'Provider');
});

function getRecordTypes() {
  let recordTypes = [
    { name: 'Assessment', value: 'Assessment' },
    { name: 'Background check', value: 'BackgroundCheck' },
    { name: 'Complaint', value: 'Complaint' },
    { name: 'Form', value: 'Form' },
    { name: 'License', value: 'License' },
    { name: 'Provider', value: 'Provider' },
  ];

  if (linkedProviderRecord.value) {
    recordTypes = recordTypes.filter((option) => option.value !== 'Provider');
  }

  if (store.state.profile.enable_labs_feature_complaints_and_licenses !== true) {
    recordTypes = recordTypes.filter((option) => option.value !== 'BackgroundCheck');
    recordTypes = recordTypes.filter((option) => option.value !== 'Complaint');
    recordTypes = recordTypes.filter((option) => option.value !== 'License');
  }

  return recordTypes;
}

function handleChange(value) {
  if (value[0] === 'type') {
    selectedType.value = value[1];
  }
}

async function handleSave(saveFields) {
  processing.value = true;
  let childRecordId;
  if (saveFields.type === 'Assessment') {
    childRecordId = saveFields.assessment_id;
  }
  if (saveFields.type === 'BackgroundCheck') {
    childRecordId = saveFields.background_check_id;
  }
  if (saveFields.type === 'Provider') {
    childRecordId = saveFields.provider_id;
  }
  if (saveFields.type === 'Complaint') {
    childRecordId = saveFields.complaint_id;
  }
  if (saveFields.type === 'License') {
    childRecordId = saveFields.license_id;
  }
  if (saveFields.type === 'Form') {
    childRecordId = saveFields.form_id;
  }

  const resp = await api.organization.linkedRecord.create({
    record_id: props.caseId,
    record_type: props.caseType,
    child_record_id: childRecordId,
    child_record_type: saveFields.type,
  });
  processing.value = false;
  if (resp?.status !== 201) return;

  emit('change');
  dialog.value?.close();
}

function searchProviders(query) {
  if (query[0] !== 'provider_id') return;

  api.organization.provider.index({ query: query[1], page_size: 200 }, (response) => {
    providerFilterItems.value = response?.data;
  });
}

async function loadAssessments() {
  if (!linkedProviderRecord.value) return;

  const { data } = await api.organization.assessment.index({
    provider_id: linkedProviderRecord.value.id,
  });
  assessments.value = data;
}

async function loadBackgroundChecks() {
  const { data } = await api.organization.backgroundCheck.index({});
  backgroundChecks.value = data;
}

async function loadComplaints() {
  if (!linkedProviderRecord.value) return;

  const { data } = await api.organization.complaint.index({
    provider_id: linkedProviderRecord.value.id,
  });
  complaints.value = data;
}

async function loadForms() {
  if (!linkedProviderRecord.value) return;

  const { data } = await api.organization.form.index({
    provider_id: linkedProviderRecord.value.id,
  });
  forms.value = data;
}

async function loadLicenses() {
  if (!linkedProviderRecord.value) return;

  const { data } = await api.organization.license.index({
    provider_id: linkedProviderRecord.value.id,
  });
  licenses.value = data;
}

async function open() {
  dialog.value.open();
  await loadAssessments();
  await loadBackgroundChecks();
  await loadLicenses();
  await loadComplaints();
  await loadForms();
}

defineExpose({ open });
</script>

<template>
  <v-card
    class="pa-0"
    elevation="0"
  >
    <v-card-text>
      <v-row>
        <v-col class="fs-16 c-black fw-600"> Who are you searching for? </v-col>
      </v-row>

      <v-row>
        <v-col class="pb-2">
          <v-autocomplete
            v-model="model"
            v-model:search="query"
            :items="groups"
            item-title="name"
            item-value="id"
            label="Client"
            prepend-inner-icon="people_alt"
            variant="outlined"
            hide-details
            hide-no-data
            return-object
          />
          <div class="pt-2 ta-right">
            <v-btn
              @click="model = query = null"
              variant="text"
            >
              {{ $t('Clear filter') }}
            </v-btn>
          </div>
        </v-col>
      </v-row>

      <div v-if="model && model.id">
        <v-row class="bt-1 bc-very-light-gray mt-3">
          <v-col>
            <div class="c-black fs-16 fw-600 mb-1">Contact Information</div>
            <div
              v-text="model.primary_phone || 'No phone listed.'"
              class="bc-text-body c-black mb-1"
            />
            <div
              v-text="model.primary_email || 'No email listed.'"
              class="bc-text-body c-black"
            />
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <div class="c-black fs-16 fw-600 mb-1">Hours of care needed</div>
            <div v-if="model.meta && model.meta.hours_summary">
              <div
                v-for="(summary, index) in model.meta.hours_summary"
                v-text="summary"
                :key="index"
                class="bc-text-body c-black mb-1"
              />
            </div>
            <div v-else>
              <div class="bc-text-body c-black mb-1">No care schedule specified.</div>
            </div>
          </v-col>
        </v-row>

        <v-row dense>
          <v-col>
            <div class="c-black fs-16 fw-600 mb-1">Additional information</div>
            <div
              v-text="model.notes || 'No additional information provided.'"
              class="c-black bc-text-body"
            />
          </v-col>
        </v-row>
      </div>
    </v-card-text>
  </v-card>
</template>
<script setup>
import Api from '@/specialist/services/bright_finder';
const emit = defineEmits(['change']);

const model = defineModel({
  type: Object,
  default: () => ({}),
});
const query = ref(null);
const groups = ref([]);

watch(model, () => {
  emit('change', model.value);
});

watch(query, () => {
  loadGroups();
});

onMounted(() => {
  loadGroups();
});

function loadGroups() {
  const params = { order: 'updated' };
  if (query.value) {
    params.query = query.value;
  }
  Api.organization.group.index(params, (resp) => {
    groups.value = resp.data;
  });
}
</script>

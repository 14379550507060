<template>
  <v-card-actions>
    <v-row>
      <v-col>
        <v-btn
          @click="emit('save')"
          :disabled="!props.changed"
          color="primary"
          size="x-large"
        >
          <span>{{ t('Save') }}</span>
        </v-btn>
      </v-col>
    </v-row>
  </v-card-actions>
</template>

<script setup>
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const emit = defineEmits(['save']);
const props = defineProps({
  changed: {
    type: Boolean,
    required: true,
  },
});
</script>

<template>
  <v-container
    class="px-0 py-0"
    fluid
  >
    <BillingPastDueAlert
      v-if="pastDue"
      class="ma-3 mb-6"
    />

    <div class="px-3 d-flex flex-column">
      <div class="d-flex align-center mb-5">
        <v-btn
          @click="emit('statement:hide')"
          :aria-label="$t('Back to all statements')"
          class="mr-3"
          icon="west"
          variant="text"
        />

        <h3>{{ $t('Statement ID:') }} {{ statement?.id }}</h3>
      </div>

      <v-row>
        <v-col cols="3">
          <v-card
            :class="summaryCardClass"
            border
            tile
          >
            <v-card-title class="fs-16">
              {{ t('Summary') }}
            </v-card-title>

            <v-card-text>
              <template
                v-for="row in summaryRows"
                :key="row"
              >
                <v-divider
                  v-if="row.divider"
                  class="mt-4 mb-2"
                  color="grey"
                  opacity="0.75"
                />

                <v-row
                  v-if="row.title"
                  class="fs-14 mt-0 align-center"
                >
                  <v-col
                    class="py-1"
                    cols="8"
                  >
                    <span>
                      {{ $t(row.title) }}
                    </span>
                  </v-col>

                  <v-col
                    class="d-flex justify-end py-1"
                    cols="4"
                  >
                    <v-chip
                      :color="row.valueColor"
                      class="pa-0"
                      variant="text"
                    >
                      {{ row.value }}
                    </v-chip>
                  </v-col>
                </v-row>
              </template>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="9">
          <BillingPaymentIndex
            :default-providers="defaultProviders"
            :statement="statement"
          />
        </v-col>
      </v-row>
    </div>

    <VerticalSpacer :min-height="14" />
  </v-container>
</template>

<script setup>
import BillingPaymentIndex from '@/shared/components/billing/BillingPaymentIndex.vue';
import BillingPastDueAlert from '@/shared/components/billing/BillingPastDueAlert.vue';
import VerticalSpacer from '@/shared/components/VerticalSpacer.vue';
import { currency } from '@/plugins/filters';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps({
  defaultProviders: {
    type: Array,
    default: () => null,
  },
  statement: {
    type: String,
    default: null,
  },
  summaryCardClass: {
    type: String,
    default: null,
  },
});

const emit = defineEmits(['statement:hide']);

const pastDue = ref(null);
const summaryRows = ref([]);

function currencyWithSign(value) {
  const plainCurrency = currency(value);

  if (value > 0) return `+ ${plainCurrency}`;
  if (value < 0) return `- ${plainCurrency.substring(1)}`;

  return plainCurrency;
}

async function load() {
  await loadStatement();
}

async function loadStatement() {
  const credits = props.statement.payments.reduce((accumulator, payment) => {
    if (payment.amount > 0) return accumulator + payment.amount;
    return accumulator;
  }, 0);

  const debits = props.statement.payments.reduce((accumulator, payment) => {
    if (payment.amount < 0) return accumulator + payment.amount;
    return accumulator;
  }, 0);

  summaryRows.value = [
    {
      title: 'Credits subtotal',
      value: currencyWithSign(credits),
    },
    { divider: true },
    {
      title: 'Debits subtotal',
      value: currencyWithSign(debits),
    },
    { divider: true },
    {
      title: 'Statement balance',
      value: currency(credits + debits),
    },
  ];

  pastDue.value = true;
}

onMounted(load);
</script>

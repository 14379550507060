<template>
  <v-container class="pa-0 mt-2">
    <v-progress-linear
      v-if="processing"
      indeterminate
    />
    <v-stepper-vertical
      v-else
      flat
      hide-actions
      multiple
      non-linear
    >
      <v-stepper-vertical-item
        v-for="(step, index) in workflowSteps"
        :key="index"
        :value="index"
        class="mb-4"
        color="primary"
        icon="add_task"
        eager
        editable
      >
        <template #title>
          <div class="ms-2">
            {{ `${$t('Action')} ${index + 1}` }}
            <span v-if="getActionDescription(step)">
              {{ ` - ${getActionDescription(step)}` }}
            </span>
          </div>
        </template>

        <template #subtitle>
          <div class="pt-2">
            <v-chip
              color="light-green-lighten-3"
              size="x-small"
            >
              <span v-if="getContextualStep(step)">
                {{ toSentenceCase(getContextualStep(step).action_arguments.data_type) }}
              </span>
              <span v-else>
                {{ toSentenceCase(workflowEvent.eventable_type) }}
              </span>
            </v-chip>

            <v-tooltip location="bottom">
              <template #activator="tooltipOptions">
                <v-chip
                  v-bind="tooltipOptions.props"
                  :color="step.enabled ? 'light-green-lighten-3' : 'grey-lighten-3'"
                  class="ms-2"
                  size="x-small"
                >
                  {{ step.enabled ? $t('Enabled') : $t('Disabled') }}
                </v-chip>
              </template>
              <span>
                {{
                  $t(
                    step.enabled
                      ? 'This action is enabled and will run when triggered.'
                      : 'This action is disabled and will not run when triggered.',
                  )
                }}
              </span>
            </v-tooltip>
          </div>
        </template>

        <WorkflowEventStep
          v-model="workflowSteps[index]"
          @deleted="loadWorkflowSteps()"
          :contextual-record-schema-id="getContextualSchemaId(step)"
          :disabled="disabled"
          :reviewer-options="reviewerOptions"
          :schema-types="schemaTypes"
          :schemas="schemas"
          :team-options="teamOptions"
        />
      </v-stepper-vertical-item>
    </v-stepper-vertical>

    <v-btn
      @click="addNewStep()"
      class="mt-3"
      color="primary"
      prepend-icon="add"
      size="small"
      block
    >
      {{ $t('Add an action') }}
    </v-btn>
  </v-container>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import { toSentenceCase } from '@/shared/services/string-utils';
import { VStepperVertical, VStepperVerticalItem } from 'vuetify/labs/VStepperVertical';
import WorkflowEventStep from '@/admin/views/workflow-events/WorkflowEventStep.vue';
import { WORKFLOW_ACTION_NAMES } from '@/shared/assets/constants';

const props = defineProps({
  disabled: {
    type: Boolean,
    default: undefined,
  },
  schemas: {
    type: Array,
    default: () => [],
  },
  schemaTypes: {
    type: Array,
    default: () => [],
  },
  workflowEvent: {
    type: Object,
    required: true,
  },
});

const processing = ref(true);
const reviewerOptions = ref([]);
const teamOptions = ref([]);
const workflowSteps = ref([]);

onMounted(() => {
  void loadWorkflowSteps();
  void loadReviewers();
  void loadTeams();
});

async function addNewStep() {
  const draftStep = {
    event_id: props.workflowEvent.id,
  };

  const resp = await Api.organization.workflow.step.create(draftStep);
  if (resp.status !== 201) return;

  workflowSteps.value.push(resp.data);
}

function getActionDescription(step) {
  if (step.action_name === WORKFLOW_ACTION_NAMES.CREATE) {
    if (step.action_arguments.data_type) {
      return `Creates ${toSentenceCase(step.action_arguments.data_type).toLowerCase()}`;
    }
    return 'Creates a linked record';
  }
  if (step.action_name === WORKFLOW_ACTION_NAMES.NOTIFY) {
    return 'Sends notification';
  }
  if (step.action_name === WORKFLOW_ACTION_NAMES.ASSIGN) {
    return 'Assigns record';
  }
  if (step.action_name === WORKFLOW_ACTION_NAMES.UPDATE) {
    return 'Updates record';
  }
  return null;
}

function getContextualStep(step) {
  const stepIndex = workflowSteps.value.indexOf(step);

  let contextualStep = null;
  for (let i = 0; i < stepIndex; i++) {
    if (workflowSteps.value[i].enabled && workflowSteps.value[i].action_arguments.schema_id) {
      contextualStep = workflowSteps.value[i];
    }
  }

  return contextualStep;
}

function getContextualSchemaId(step) {
  const stepIndex = workflowSteps.value.indexOf(step);

  let schemaId = props.workflowEvent.schema_id;
  for (let i = 0; i < stepIndex; i++) {
    if (workflowSteps.value[i].action_arguments.schema_id) {
      schemaId = workflowSteps.value[i].action_arguments.schema_id;
    }
  }

  // TODO: Update to not only provide schemaId, but also step context for display
  // of nested step context
  return schemaId;
}

async function loadReviewers() {
  const resp = await Api.organization.member.index({ is_specialist: true });
  reviewerOptions.value = resp.data;
}

async function loadTeams() {
  const resp = await Api.organization.team.index({});
  teamOptions.value = resp.data;
}

async function loadWorkflowSteps() {
  processing.value = true;

  const resp = await Api.organization.workflow.step.index({ event_id: props.workflowEvent.id });
  workflowSteps.value = resp.data;

  processing.value = false;
}
</script>

<template>
  <v-alert
    :text="t('Your account is not in good standing.')"
    class="fs-14"
    icon="error"
    type="error"
    variant="tonal"
  />
</template>

<script setup>
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
</script>

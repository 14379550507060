import { createStore } from 'vuex';
import actions from '@/shared/store/actions';
import mutations from '@/shared/store/mutations';
import defaultState from '@/shared/store/state';
import Api from '@/specialist/services/bright_finder';
import _ from 'lodash';
import SegmentService from '@/shared/services/analytics/SegmentService';

export default createStore({
  actions: {
    ...actions,

    identify(context, args) {
      Api.member.session.get('specialist', (resp) => {
        const authed =
          resp.data?.status === 'authenticated' ||
          resp.data?.status === 'mfa_register' ||
          resp.data?.status === 'password_reset';
        if (!authed) return args.failure();

        return Api.organization.profile.get(
          (profileResp) => {
            if (profileResp?.data.organization_id === window.organization_id) {
              context.dispatch('setProfileAndTimeout', profileResp.data);
              SegmentService.forSpecialist(window.analytics).identifyMember(profileResp.data);
              if (args.success) args.success(profileResp.data);
            } else if (args.failure) args.failure();
          },
          () => args.failure(),
        );
      });
    },
  },

  state: defaultState,

  mutations: {
    ...mutations,

    resetState(state) {
      if (import.meta.env.NODE_ENV !== 'test')
        throw new Error('resetState should not be called in production');

      Object.assign(state, defaultState);
    },

    /* eslint no-param-reassign: ["error", { "props": false }] */
    // resetTimeout: _.throttle((state) => {
    //   if (!!state.userSessionTimeout && !!state.profile) {
    //     Api.member.session.get('specialist', (resp) => {
    //       if (resp.data?.status === 'authenticated') state.userSessionActionTimestamp = new Date();
    //     });
    //   }
    // }, 15000),
    resetTimeout() {
      return false;
    },
  },
});

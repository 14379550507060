<template>
  <v-card
    class="mb-8"
    border
    flat
    tile
  >
    <SectionHeader title="Reporting" />

    <v-card-text>
      <v-row>
        <LabeledSwitch
          v-model="property.reportable"
          subtitle="This field will be selectable in reports."
          title="Reportable"
          divided-bottom
        >
          <v-row
            v-show="property.reportable"
            class="my-2"
          >
            <LabeledTextfield
              v-model="property.reportingAlias"
              description="This alias will be used in reports as the default column title. If not set, the alias or title will be used."
              message="Reporting alias"
            />
            <v-col>
              <v-btn
                @click="$refs.transformDialog.open(property.reportingTransforms || [])"
                color="primary"
                size="small"
                variant="outlined"
              >
                <span>{{ t('Transforms') }} ({{ reportingTransformsLength }})</span>
              </v-btn>
            </v-col>
          </v-row>
        </LabeledSwitch>

        <ResourceDialog
          @save="setTransforms"
          ref="transformDialog"
          save-button-text="Done"
          title="Find & replace"
        >
          <template #form="{ localValue }">
            <p v-if="localValue.length == 0">
              {{ $t('Nothing has been replaced for this column.') }}
            </p>
            <div
              v-for="(transform, transformIndex) in localValue"
              :key="transformIndex"
            >
              <v-row dense>
                <v-col class="d-flex align-center">
                  <v-btn
                    @click="localValue.splice(transformIndex, 1)"
                    color="red"
                    variant="text"
                    icon
                  >
                    <v-icon icon="close" />
                  </v-btn>
                  <v-text-field
                    v-model="transform.target"
                    label="Find"
                    variant="filled"
                    hide-details
                  />
                </v-col>
                <v-col class="d-flex align-center">
                  <v-text-field
                    v-model="transform.value"
                    label="Replace"
                    variant="filled"
                    hide-details
                  />
                </v-col>
              </v-row>
              <v-divider
                v-show="transformIndex + 1 < localValue.length"
                class="my-3"
              />
            </div>
            <v-row>
              <v-col>
                <v-btn
                  @click="localValue.push({ target: null, value: null })"
                  class="mt-3"
                  color="primary"
                >
                  <span>{{ t('Add Transform') }}</span>
                </v-btn>
              </v-col>
            </v-row>
          </template>
        </ResourceDialog>
      </v-row>
    </v-card-text>

    <SectionActions
      @save="save"
      :changed="changed"
    />
  </v-card>
</template>

<script setup>
import { useI18n } from 'vue-i18n';
import LabeledSwitch from '@/shared/components/form/LabeledSwitch.vue';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import SectionHeader from '@/admin/components/schemas/SectionHeader.vue';
import SectionActions from '@/admin/components/schemas/SectionActions.vue';
import useSchemas from '@/admin/composables/useSchemas';

const { t } = useI18n();
const { updateProperty } = useSchemas();

const emit = defineEmits(['change', 'change:core']);

const props = defineProps({
  field: {
    type: String,
    required: true,
  },
  parentField: {
    type: String,
    required: true,
  },
  property: {
    type: Object,
    required: true,
  },
  schemaId: {
    type: String,
    required: true,
  },
});

const property = ref({
  reportable: props.property.reportable,
  reportingAlias: props.property.reportingAlias,
  reportingTransforms: props.property.reportingTransforms,
});
const changed = ref(false);
const transformDialog = ref(null);
const reportingTransformsLength = computed(() => property.value.reportingTransforms?.length || 0);

function setTransforms(transforms) {
  property.value.reportingTransforms = transforms;
  transformDialog.value?.close();
}

async function save() {
  const coreChanged = props.property.reportingAlias != property.value.reportingAlias;
  const success = await updateProperty(
    props.schemaId,
    props.parentField,
    props.field,
    property.value,
  );

  if (success) {
    emit('change');
    changed.value = false;
    if (coreChanged) {
      emit('change:core');
    }
  }
}

watch(
  property,
  () => {
    changed.value = true;
  },
  {
    deep: true,
  },
);
</script>

<template>
  <v-container
    v-if="translations && organization"
    class="mxw-1200 pa-6"
  >
    <v-row>
      <v-col>
        <div class="fw-600 bc-h5">Translations</div>
      </v-col>
    </v-row>

    <v-row class="mb-3">
      <v-col>
        <div class="c-light-black fs-16 fw-500">
          This is a comprehensive list of all content shown on your sites. Updating content from
          this list, will update all sites. If translations are left blank, content will default to
          the primary language.
        </div>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-autocomplete
          v-model="organization.languages"
          @update:model-value="saveLanguages"
          :items="languages"
          item-title="text"
          variant="solo"
          chips
          deleteable-chips
          hide-details
          multiple
          return-object
        />
      </v-col>
    </v-row>

    <v-row class="d-flex align-center mb-3">
      <v-col>
        <v-text-field
          v-model="keyQuery"
          class="mxw-250"
          density="compact"
          prepend-inner-icon="search"
          variant="solo"
          hide-details
          rounded
        />
      </v-col>
      <v-col class="ta-right">
        <v-btn
          :href="downloadTranslations()"
          class="me-3"
          variant="text"
        >
          {{ $t('Download All Translations') }}
        </v-btn>
        <v-btn
          @click="uploadDialogIsVisible = true"
          class="me-3"
          variant="text"
        >
          {{ $t('Upload') }}
        </v-btn>
        <v-btn
          @click="translations.push({ language: selectedLanguage.split('-')[0] })"
          color="primary"
          size="large"
        >
          Add
        </v-btn>
      </v-col>
    </v-row>

    <v-row class="d-flex align-center">
      <v-col
        class="mx-0 fw-600"
        cols="6"
      >
        English
      </v-col>
      <v-col
        class="mx-0 fw-600"
        cols="6"
      >
        <v-select
          v-model="selectedLanguage"
          :items="organization.languages"
          variant="underlined"
        />
      </v-col>
    </v-row>

    <TranslationForm
      v-for="translation in translations"
      :key="translation.id"
      :translation="translation"
    />
    <v-pagination
      v-if="pages > 1"
      v-model="page"
      :disabled="processing"
      :length="pages"
      :total-visible="8"
      rounded
    />

    <v-btn
      v-show="translations.length > 0"
      @click="translations.push({ language: selectedLanguage.split('-')[0] })"
      class="mt-4"
      color="primary"
      size="large"
    >
      Add
    </v-btn>

    <v-dialog
      v-model="uploadDialogIsVisible"
      max-width="500"
    >
      <v-card>
        <v-card-title>Upload translations</v-card-title>
        <v-card-text>
          <p class="fs-16 c-black fw-400">
            Select a language below for your file. Your file should not contain any headers, and
            only two columns in CSV format. First column should be your English content, second
            should be your translated content.
          </p>

          <v-row>
            <v-col>
              <v-file-input
                v-model="file"
                accept=".csv"
                color="primary"
                label="Attach file"
                variant="filled"
                hide-details
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            @click="createUpload()"
            :disabled="!file"
            :loading="processing"
            color="primary"
            block
          >
            {{ $t('Upload') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script setup>
import _ from 'lodash';
import Api from '@/specialist/services/bright_finder';
import TranslationForm from '@/admin/components/TranslationForm.vue';
import useAssets from '@/shared/composables/useAssets';
import useEventBus from '@/shared/composables/useEventBus';

const $a = useAssets();
const eventBus = useEventBus();
const file = ref(null);
const keyQuery = ref(null);
const languages = $a.assets.locales.map((locale) => ({
  text: locale.text,
  value: locale.value,
}));
const organization = ref(null);
const page = ref(1);
const pages = ref(1);
const pageSize = ref(100);
const processing = ref(false);
const selectedLanguage = ref(null);
const translations = ref(null);
const total = ref(0);
const uploadDialogIsVisible = ref(false);

const selectedLanguageCode = computed(() => {
  return selectedLanguage.value ? selectedLanguage.value.split('-')[0] : null;
});

onMounted(() => {
  loadTranslations();
  loadOrganization();
});

watch(page, () => {
  loadTranslations();
});

watch(
  keyQuery,
  _.debounce(function () {
    page.value = 1;
    loadTranslations();
  }, 300),
);

watch(selectedLanguage, () => {
  page.value = 1;
  loadTranslations();
});

async function createUpload() {
  processing.value = true;
  const reader = new FileReader();
  reader.readAsText(file.value, 'UTF-8');
  // eslint-disable-next-line func-names
  reader.onload = async (evt) => {
    const response = await Api.organization.translation.upload.create({
      language: selectedLanguageCode.value,
      content: evt.target.result,
    });
    processing.value = false;
    uploadDialogIsVisible.value = false;
    loadTranslations();

    if (response?.status != 201) return;
    eventBus.chime('File upload successful.');
  };
}

function downloadTranslations() {
  return Api.organization.translation.downloadUrl('All Translations.csv');
}

function loadTranslations() {
  const params = {
    page: page.value,
    page_size: 100,
    language: selectedLanguage.value ? selectedLanguage.value.split('-')[0] : null,
    key: keyQuery.value === '' ? null : keyQuery.value, // case where you delete the "key" ends in empty String
  };

  processing.value = true;
  Api.organization.translation.index(params, (resp) => {
    translations.value = resp.data;
    pages.value = parseInt(resp.headers['x-page-count'] || 0, 10);
    total.value = parseInt(resp.headers['x-total-count'] || 0, 10);
    pageSize.value = parseInt(resp.headers['x-page-size'] || 0, 10);
    processing.value = false;
  });
}

function loadOrganization() {
  Api.organization.get((resp) => {
    organization.value = resp.data;
    selectedLanguage.value = organization.value.languages[1].value;
  });
}

function saveLanguages() {
  organization.value.languages = organization.value.languages.filter((ssl) =>
    languages.find((csl) => ssl.text === csl.text && ssl.value === csl.value),
  );
  Api.organization.update({ languages: organization.value.languages }, () => {
    eventBus.chime('Saved');
  });
}
</script>

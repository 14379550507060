import api from '@/specialist/services/bright_finder';
import useEventBus from '@/shared/composables/useEventBus';

export default function useSchemas(property) {
  const eventBus = useEventBus();

  const createProperty = async (schemaId, parentField, newProperty, itemType) => {
    if (!validField(newProperty.field)) {
      eventBus.error('Invalid field name');
      return;
    }

    if (newProperty.type === 'array') {
      if (itemType === 'object') {
        newProperty.items = { type: 'object', properties: {} };
      } else if (itemType === 'string') {
        newProperty.items = { type: 'string', enum: [] };
      } else {
        eventBus.error('Invalid item type');
        return;
      }
    }

    const newField = [parentField, newProperty.field].flat(Infinity).filter(Boolean).join(':');

    const resp = await api.organization.schema.schema_properties.create(schemaId, {
      ...newProperty,
      field: newField,
    });

    return resp?.status === 200;
  };

  const destroyProperty = async (schemaId, parentField, field) => {
    const keyChain = [parentField, field].filter(Boolean).join(':');
    const resp = await api.organization.schema.schema_properties.destroy(schemaId, keyChain);
    return resp?.status === 200;
  };

  const duplicateProperty = async (
    schemaId,
    parentField,
    newfieldName,
    newFieldAlias,
    targetProperty,
  ) => {
    if (!validField(newfieldName)) {
      eventBus.error('Invalid field name');
      return;
    }
    const newField = [parentField, newfieldName].flat(Infinity).filter(Boolean).join(':');

    const resp = await api.organization.schema.schema_properties.create(schemaId, {
      ...targetProperty,
      alias: newFieldAlias,
      field: newField,
    });

    return resp?.status === 201;
  };

  const removeEnum = async (schemaId, parentField, field, value) => {
    const keyChain = [parentField, field].filter(Boolean).join(':');
    const resp = await api.organization.schema.schema_properties.remove(schemaId, keyChain, value);
    return resp?.status === 200;
  };

  const updateProperty = async (schemaId, parentField, field, value) => {
    const keyChain = [parentField, field].filter(Boolean).join(':');

    const resp = await api.organization.schema.schema_properties.update(schemaId, keyChain, {
      ...value,
    });
    if (resp.status != 200) return;

    eventBus.chime('Saved');
    return true;
  };

  function validField(field) {
    return !!field && field.length > 0 && !field.match(/^[^a-z]/) && !field.match(/[^a-z_0-9]+/g);
  }

  const propertyRoleOptions = computed(() => {
    const roleAry = [
      { text: '-', value: null },
      { text: 'Formula', value: 'formula' },
    ];

    if (property.value.type === 'number') {
      roleAry.push({ text: 'Currency - US', value: 'currency-us' });
    }

    if (property.value.type === 'string') {
      roleAry.push({ text: 'Currency - US', value: 'currency-us' });
      roleAry.push({ text: 'Phone - US', value: 'phone-us' });
      roleAry.push({ text: 'Text - long', value: 'text-long' });
    }

    return roleAry;
  });

  const TYPES_OTHER_THAN_OBJECT = [
    { text: 'Array', value: 'array' },
    { text: 'Boolean', value: 'boolean' },
    { text: 'Integer', value: 'integer' },
    { text: 'Number', value: 'number' },
    { text: 'String', value: 'string' },
  ];

  const FORMAT_OPTIONS = [
    { text: 'No format', value: null },
    { text: 'Date-time', value: 'date-time' },
    { text: 'Date', value: 'date' },
    { text: 'Email', value: 'email' },
    { text: 'URI', value: 'uri' },
    { text: 'UUID', value: 'uuid' },
  ];

  const ITEM_PROPERTY_FIELDS = [
    { required: true, text: 'Name', value: 'name' },
    { required: true, text: 'Title', value: 'title' },
    {
      required: true,
      text: 'Type',
      value: 'type',
      items: TYPES_OTHER_THAN_OBJECT,
      itemText: 'text',
      itemValue: 'value',
    },
  ];

  const ITEM_TYPE_OPTIONS = [
    { text: 'String', value: 'string' },
    { text: 'Object', value: 'object' },
  ];

  const ROLE_OPTIONS = [
    { text: 'Manager', value: 'manager' },
    { text: 'Parent', value: 'parent' },
    { text: 'Specialist', value: 'specialist' },
  ];

  return {
    createProperty,
    destroyProperty,
    duplicateProperty,
    propertyRoleOptions,
    removeEnum,
    updateProperty,
    TYPES_OTHER_THAN_OBJECT,
    FORMAT_OPTIONS,
    ITEM_PROPERTY_FIELDS,
    ITEM_TYPE_OPTIONS,
    ROLE_OPTIONS,
  };
}

<template>
  <div>
    <PageTitle
      title="My profile"
      flat
      outlined
    />

    <v-container>
      <v-card
        class="mb-4"
        border
        flat
        tile
      >
        <v-card-title>{{ $t('Contact information') }}</v-card-title>
        <v-divider />
        <v-card-text>
          <v-row>
            <LabeledTextfield
              v-model="profile.first_name"
              cols="6"
              message="First name"
            />
            <LabeledTextfield
              v-model="profile.last_name"
              cols="6"
              message="Last name"
            />
            <LabeledTextfield
              v-model="profile.email"
              cols="6"
              message="Email"
            />
            <LabeledTextfield
              v-model="profile.phone"
              cols="6"
              message="Business phone"
            />
          </v-row>
        </v-card-text>
        <v-divider class="my-4" />
        <v-card-actions>
          <v-btn
            @click="updateProfile"
            :loading="processing"
            color="primary"
            size="x-large"
          >
            {{ $t('Update profile') }}
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-card
        v-show="showPasswordSettings"
        class="mb-4"
        border
        flat
        tile
      >
        <v-card-title>{{ $t('Password') }}</v-card-title>
        <v-divider />
        <v-card-text>
          <v-row>
            <LabeledTextfield
              v-model="newPassword"
              cols="6"
              message="New password"
              type="password"
            />
            <LabeledTextfield
              v-model="newPasswordConfirmation"
              cols="6"
              message="Confirm new password"
              type="password"
            />
          </v-row>
        </v-card-text>
        <v-divider class="my-4" />
        <v-card-actions>
          <v-btn
            @click="updatePassword"
            :disabled="!newPassword || newPassword != newPasswordConfirmation"
            :loading="processing"
            color="primary"
            size="x-large"
          >
            {{ $t('Update password') }}
          </v-btn>
        </v-card-actions>
      </v-card>

      <MFASettingsCard
        v-show="showMFASettings"
        @change="load"
        :profile="profile"
      />

      <VerticalSpacer :min-height="60" />
    </v-container>
  </div>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import LabeledTextfield from '@/shared/components/form/LabeledTextfield.vue';
import MFASettingsCard from '@/shared/components/MFASettingsCard.vue';
import PageTitle from '@/shared/components/PageTitle.vue';
import VerticalSpacer from '@/shared/components/VerticalSpacer.vue';
import useEventBus from '@/shared/composables/useEventBus';
import { useStore } from 'vuex';

const eventBus = useEventBus();
const store = useStore();

const newPassword = ref(null);
const newPasswordConfirmation = ref(null);
const processing = ref(false);
const profile = reactive({});

onMounted(load);

const showMFASettings = computed(() => store.state.config.enable_mfa_authentication);
const showPasswordSettings = computed(() => store.state.config.enable_password_authentication);

function load() {
  Api.organization.profile.get((resp) => {
    Object.assign(profile, resp?.data);
  });
}

function updatePassword() {
  processing.value = true;
  Api.organization.profile.update(
    { password: newPassword.value },
    (resp) => {
      Object.assign(profile, resp?.data);
      eventBus.chime('Password saved');
      processing.value = false;
    },
    (err) => {
      eventBus.error(err);
      processing.value = false;
    },
  );
}

function updateProfile() {
  processing.value = true;
  Api.organization.profile.update(
    profile,
    (resp) => {
      Object.assign(profile, resp?.data);
      eventBus.chime('Saved');
      processing.value = false;
    },
    (err) => {
      eventBus.error(err);
      processing.value = false;
    },
  );
}
</script>

<template>
  <v-container
    class="px-0 py-0"
    fluid
  >
    <v-card
      v-if="items"
      class="mt-3 mx-3 bt-1 br-1 bl-1 bc-outlined-gray"
      tile
    >
      <v-card-text class="fs-15 py-0">
        <v-row dense>
          <v-col class="d-flex align-center">
            <p class="mb-0 py-1">
              {{ $t('Displaying') }}
              <strong>{{ items?.length }}</strong> {{ $t('out of') }}
              <strong>{{ totalItemCount }}</strong> {{ $t('total results') }}.
            </p>
          </v-col>

          <v-col class="pa-0">
            <v-toolbar>
              <v-spacer />

              <FilterMenu
                v-if="providers"
                :active="selectedProviders.length > 0"
                classes="d-md-inline-flex"
                default-button-class="ms-2"
                test-id="status-filter-button"
                title="Provider"
              >
                <template #card>
                  <v-autocomplete
                    v-model="selectedProviders"
                    @update:model-value="handleFilterChange"
                    @update:search="searchProviders"
                    @vue:mounted="searchProviders('')"
                    :aria-label="$t('Filter by provider')"
                    :disabled="processing"
                    :item-title="(item) => $t(item.name)"
                    :items="providers"
                    :menu="true"
                    :placeholder="$t('Filter by provider')"
                    density="compact"
                    prepend-inner-icon="search"
                    variant="filled"
                    autofocus
                    chips
                    clearable
                    multiple
                  />
                </template>
              </FilterMenu>

              <FilterMenu
                :active="selectedStatuses.length > 0"
                classes="d-md-inline-flex"
                default-button-class="ms-2"
                test-id="status-filter-button"
                title="Status"
              >
                <template #card>
                  <v-autocomplete
                    v-model="selectedStatuses"
                    @update:model-value="handleFilterChange"
                    :aria-label="$t('Filter by status')"
                    :disabled="processing"
                    :item-title="(item) => $t(capitalize(item))"
                    :items="statuses"
                    :menu="true"
                    :placeholder="$t('Filter by status')"
                    density="compact"
                    prepend-inner-icon="search"
                    variant="filled"
                    autofocus
                    chips
                    clearable
                    multiple
                  />
                </template>
              </FilterMenu>

              <FilterMenu
                :active="selectedStatementDates.length > 0"
                classes="d-md-inline-flex"
                default-button-class="ms-2"
                test-id="status-filter-button"
                title="Statement date"
              >
                <template #card>
                  <v-autocomplete
                    v-model="selectedStatementDates"
                    @update:model-value="handleFilterChange"
                    :aria-label="$t('Filter by statement date')"
                    :disabled="processing"
                    :item-title="(item) => $t(item)"
                    :items="statementDates"
                    :menu="true"
                    :placeholder="$t('Filter by statement date')"
                    density="compact"
                    prepend-inner-icon="search"
                    variant="filled"
                    autofocus
                    chips
                    clearable
                    multiple
                  />
                </template>
              </FilterMenu>
            </v-toolbar>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-data-table-server
      v-if="items"
      :headers="tableHeaders"
      :hide-default-footer="!totalItemCount"
      :items="items"
      :items-length="totalItemCount"
      :items-per-page="100"
      :loading="processing"
      class="mx-3 b-radius-0 b-1 bc-outlined-gray"
      item-key="id"
      no-data-text="No statements found"
      fixed-header
      return-object
    >
      <template #item.id="{ item }">
        <a
          @click="emit('statement:show', item)"
          class="text-decoration-underline"
        >
          {{ item.id?.slice(0, 6)?.toUpperCase() }}
        </a>
      </template>

      <template #item.amount="{ item }">
        <span>
          {{ currency(item.amount) }}
        </span>
      </template>

      <template #item.status="{ item }">
        <span>
          {{ capitalize(item.status) }}
        </span>
      </template>
    </v-data-table-server>

    <VerticalSpacer :min-height="14" />
  </v-container>
</template>

<script setup>
import Api from '@/shared/services/bright_finder';
import VerticalSpacer from '@/shared/components/VerticalSpacer.vue';
import { capitalize, currency } from '@/plugins/filters';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps({
  statementsApi: {
    type: Object,
    default: () => ({}),
  },
});

const emit = defineEmits(['statement:show']);

const items = ref(null);
const processing = ref(null);
const providers = ref([]);
const queryCount = ref(0);
const selectedProviders = ref([]);
const selectedStatementDates = ref([]);
const selectedStatuses = ref([]);
const statementDates = ref([]);
const statuses = ref([]);
const totalItemCount = ref(null);

const tableHeaders = computed(() => {
  return [
    {
      title: t('Statement ID'),
      value: 'id',
    },
    {
      title: t('Statement Date'),
      value: 'due_date',
    },
    {
      title: t('Amount'),
      value: 'amount',
      align: 'end',
    },
    {
      title: t('Description'),
      value: 'description',
    },
    {
      title: t('Status'),
      value: 'status',
    },
  ];
});

async function handleFilterChange() {
  // Update filters ?
}

async function load() {
  await loadStatements();

  void loadStatementDates();
  void loadStatuses();
}

async function loadStatements() {
  const response = await props.statementsApi.index();

  items.value = response.data;
  totalItemCount.value = items.value.length;
}

async function loadStatementDates() {
  statementDates.value = items.value.map((item) => item.date);
}

async function loadStatuses() {
  statuses.value = ['pending', 'approved', 'unpaid', 'paid', 'canceled'];
}

function searchProviders(query) {
  queryCount.value += 1;
  const currentQueryCount = queryCount.value;

  Api.public_api.organization.provider.index({ query, page_size: 25 }, (response) => {
    if (queryCount.value !== currentQueryCount) return;

    providers.value = response.data;
  });
}

onMounted(load);
</script>

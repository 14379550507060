const FAMILY_SUBSIDY_PERMISSIONS = [
  {
    description: 'Access applications',
    subtitle:
      'Ability to view Applications tab on a family subsidy.\nIf they are not a family subsidy administrator, they will only see applications that they are assigned to.',
    field: 'org_family_subsidies_access',
  },
  {
    description: 'Administer applications',
    subtitle: 'Ability to view all family subsidy applications.',
    field: 'org_family_subsidies_admin',
  },
  {
    description: 'Create applications',
    subtitle: 'Ability to create family subsidy applications.',
    field: 'org_family_subsidies_create',
  },
  {
    description: 'Edit applications',
    subtitle: 'Ability to edit family subsidy applications.',
    field: 'org_family_subsidies_edit',
  },
  {
    description: 'Delete applications',
    subtitle: 'Ability to delete family subsidy applications.',
    field: 'org_family_subsidies_delete',
  },
];

const LAB_PERMISSIONS = [
  {
    description: 'Enable customizable view?',
    field: 'enable_labs_feature_custom_views',
  },
  {
    description: 'Enable complaints and licenses?',
    field: 'enable_labs_feature_complaints_and_licenses',
    subtitle: 'Ability to work with complaints and licenses.',
  },
];

const PERMISSION_SECTIONS = Object.freeze({
  ADMIN: [
    {
      description: 'Is this user an administrator?',
      subtitle: 'Ability to invite staff members to the admin portal and edit user permissions.',
      field: 'org_settings_access',
    },
  ],
  FORMS: [
    {
      description: 'Can this user administer forms?',
      subtitle: 'User can create, view, edit, and delete all forms, regardless of assignee.',
      field: 'org_forms_admin',
      dependent_fields: {
        org_forms_create: true,
        org_forms_access: true,
        org_forms_edit: true,
        org_forms_delete: true,
      },
    },
    {
      description: 'Can this user create forms?',
      subtitle: 'User can create forms.',
      field: 'org_forms_create',
      locked_by: { org_forms_admin: true },
      locked_tooltip: 'Locked by form administration permission',
    },
    {
      description: 'Can this user view forms?',
      subtitle:
        'User can only view forms that they created, are assigned to, or belongs to their team.',
      field: 'org_forms_access',
      locked_by: { org_forms_admin: true },
      locked_tooltip: 'Locked by form administration permission',
    },
    {
      description: 'Can this user edit forms?',
      subtitle: 'User can only edit forms they are able to view.',
      field: 'org_forms_edit',
      locked_by: { org_forms_admin: true },
      locked_tooltip: 'Locked by form administration permission',
    },
    {
      description: 'Can this user delete forms?',
      subtitle: 'User can only delete forms they are able to view.',
      field: 'org_forms_delete',
      locked_by: { org_forms_admin: true },
      locked_tooltip: 'Locked by form administration permission',
    },
    {
      description: 'Can this user review forms?',
      subtitle: 'User can review forms (sees the form review page) ',
      field: 'org_forms_review',
    },
  ],
  LEARNING: [
    {
      description: 'Can this user facilitate a cohort?',
      subtitle: 'User can facilitate course and can only see cohorts they are facilitating.',
      field: 'org_learning_access',
    },
    {
      description: 'Can this user create and manage courses and cohorts?',
      subtitle: 'User can facilitate and build courses and manage cohorts.',
      field: 'org_learning_admin',
      conditional_field: 'org_learning_access',
    },
  ],
  MESSAGING: [
    {
      description: 'Can this user View instant messages in subsidy context?',
      subtitle: 'Ability to view individual messages to families in subsidy context.',
      field: 'org_instant_messaging_access',
    },
    {
      description: 'Can this user Send instant messages in subsidy context?',
      subtitle: 'Ability to send individual messages to families in subsidy context.',
      field: 'org_instant_messaging_create',
      conditional_field: 'org_instant_messaging_access',
    },
    {
      description: 'Can this user access the Messaging and Announcements module?',
      subtitle:
        'Ability to send individual messages to families and providers, and send announcements via email or, if enabled, text messaging.',
      field: 'org_messaging_access',
    },
  ],
  GROUP: [
    {
      description: 'Can this user view family information?',
      subtitle: 'Ability to view all family profiles.',
      field: 'org_groups_access',
    },
    {
      description: 'Can this user create family information?',
      subtitle: 'Ability to create new family profiles.',
      field: 'org_groups_create',
    },
    {
      description: 'Can this user edit family information?',
      subtitle: 'Ability to edit family profiles.',
      field: 'org_groups_edit',
    },
    {
      description: 'Can this user delete family information?',
      subtitle: 'Ability to delete family profiles.',
      field: 'org_groups_delete',
    },
  ],
  SUBSIDIES: [
    {
      description: 'Can this user view child subsidy applications?',
      subtitle:
        'Ability to view Applications tab on a child subsidy.\nIf they are not a child subsidy administrator, they will only see applications that they are assigned to.',
      field: 'org_subsidies_access',
    },
    {
      description: 'Can this user administer child subsidy applications?',
      subtitle: 'Ability to view all child subsidy applications.',
      field: 'org_subsidies_admin',
    },
    {
      description: 'Can this user edit child subsidy applications?',
      subtitle:
        'Ability to edit child subsidy applications.\nIf they are not a child subsidy administrator, they will only be able to edit in progress, unlocked applications that they are assigned to.',
      field: 'org_subsidies_edit',
    },
    {
      description: 'Can this user delete child subsidy applications?',
      subtitle: 'Ability to delete child subsidy applications.',
      field: 'org_subsidies_delete',
    },
    {
      description: 'Can this user act as a child subsidy agent?',
      subtitle: 'Allows administration of subsidy applications to which the user is assigned.',
      field: 'org_subsidies_agent',
    },
    {
      description: 'Can this user administer IEP referrals?',
      subtitle: 'Allows user to upload IEP referrals for a subsidy program.',
      field: 'org_iep_referrals_admin',
    },
    {
      description: 'Can this user edit IEP referrals?',
      subtitle: 'Allows user to edit the IEP referral program of a subsidy they can access.',
      field: 'org_iep_referrals_edit',
    },
  ],
  ENROLLMENTS: [
    {
      description: 'Can this user view enrollments?',
      subtitle: 'Ability to view Enrollments tab.',
      field: 'org_enrollments_access',
    },
    {
      description: 'Can this user administer enrollments?',
      subtitle:
        'Ability to perform actions related to the Deferred Acceptance Algorithm, such as running DAA, resetting, and publishing results to providers and families.',
      field: 'org_enrollments_admin',
    },
    {
      description: 'Can this user create enrollments?',
      subtitle: 'Ability to create enrollments/provider selections for a subsidy application.',
      field: 'org_enrollments_create',
    },
    {
      description: 'Can this user edit enrollments?',
      subtitle:
        'Ability to edit provider selections that a family, FCO, or admin has made as part of a subsidy application.\nIf an enrollment administrator, the user will be able to edit enrollments of locked subsidy applications.',
      field: 'org_enrollments_edit',
    },
    {
      description: 'Can this user delete enrollments?',
      subtitle:
        'Ability to delete provider selections that a family, FCO, or admin has made as part of a subsidy application.',
      field: 'org_enrollments_delete',
    },
    {
      description: 'Can this user act as an enrollment agent?',
      subtitle:
        'Allows administration of enrollments of subsidy applications to which the user is assigned.\nMust be used with subsidy agent permission.',
      field: 'org_enrollments_agent',
    },
  ],
  PAYMENTS: [
    {
      description: 'Can this user view payments?',
      field: 'org_payments_access',
    },
    {
      description: 'Can this user administer payments?',
      field: 'org_payments_admin',
    },
    {
      description: 'Can this user edit payments?',
      field: 'org_payments_edit',
    },
  ],
  PROVIDERS: [
    {
      description: 'Can this user access providers?',
      subtitle: 'Ability to view Providers module in main navigation.',
      field: 'org_providers_access',
    },
    {
      description: 'Can this user administer providers?',
      subtitle: 'Ability to view all providers.',
      field: 'org_providers_admin',
    },
    {
      description: 'Can this user edit providers?',
      subtitle:
        'Ability to edit providers.\nIf a provider administrator, the user will be able to edit all providers.',
      field: 'org_providers_edit',
    },
    {
      description: 'Can this user delete providers?',
      subtitle: 'Ability to delete providers.',
      field: 'org_providers_delete',
    },
  ],
  BUSINESS: [
    {
      description: 'Can this user view provider business/staff information?',
      subtitle: 'Ability to view business, provider, and staff information.',
      field: 'org_business_access',
    },
    {
      description: 'Can this user edit provider business/staff information?',
      subtitle:
        'Ability to edit business, provider, and staff information. This includes the creation of new businesses, and giving staff members (provider) access to locations.\nWhen enabled, the user will receive an email each time a provider requests access to a location.',
      field: 'org_business_edit',
    },
  ],
  GRANTS: [
    {
      description: 'Can this user view grant applications?',
      subtitle:
        'Ability to see submitted grant applications (when grants management module is enabled).',
      field: 'org_grants_access',
    },
    {
      description: 'Can this user administer grant applications?',
      subtitle: 'Ability to see all grant applications (when grants management module is enabled).',
      field: 'org_grants_admin',
    },
    {
      description: 'Can this user edit grant applications?',
      subtitle: 'Ability to edit grant applications (when grants management module is enabled).',
      field: 'org_grants_edit',
    },
    {
      description: 'Can this user delete grant applications?',
      subtitle: 'Ability to delete a grant application (when grants management module is enabled).',
      field: 'org_grants_delete',
    },
  ],
  REPORTING: [
    {
      description: 'Can this user view reports?',
      subtitle: 'Ability to view reports and analytics.',
      field: 'org_reporting_access',
    },
    {
      description: 'Can this user edit reports?',
      subtitle: 'Ability to create and edit reports.',
      field: 'org_reporting_edit',
    },
    {
      description: 'Can this user administer reports?',
      subtitle: 'Ability to lock and unlock reports.',
      field: 'org_reporting_admin',
      conditional_field: 'org_reporting_edit',
    },
  ],
  ASSESSMENTS: [
    {
      description: 'Can this user conduct provider assessments?',
      subtitle: 'Ability to view assessments and complete provider assessments.',
      field: 'org_assessments_access',
    },
  ],
  SURVEYS: [
    {
      description: 'Can this user view surveys?',
      subtitle: 'Ability to view surveys.',
      field: 'org_surveys_access',
    },
    {
      description: 'Can this user delete surveys?',
      subtitle: 'Ability to delete surveys.',
      field: 'org_surveys_delete',
    },
  ],
  TASKS: [
    {
      description: 'Can this user view tasks?',
      field: 'org_tasks_access',
    },
    {
      description: 'Can this user view all tasks?',
      field: 'org_tasks_admin',
      conditional_field: 'org_tasks_access',
    },
  ],
  QUALITY: [
    {
      description: 'Can this user view & edit provider quality ratings?',
      subtitle:
        "Ability to edit a provider quality rating score when provider schema includes 'quality_rating_score.'",
      field: 'org_quality_rating_access',
    },
  ],
  BILLING: [
    {
      description: 'Can this user view & edit funding and billing settings?',
      subtitle:
        'Ability to edit funding and billing settings for each provider when the payments module is enabled.',
      field: 'org_billing_edit',
    },
  ],
});

export const specialistPermissions = {
  getChildSubsidyContents() {
    return PERMISSION_SECTIONS.SUBSIDIES;
  },

  getEnrollmentContents() {
    return PERMISSION_SECTIONS.ENROLLMENTS;
  },

  getFormContents() {
    return PERMISSION_SECTIONS.FORMS;
  },

  getLearningContents() {
    return PERMISSION_SECTIONS.LEARNING;
  },

  getMessagingContents() {
    return PERMISSION_SECTIONS.MESSAGING;
  },

  getGrantContents() {
    return PERMISSION_SECTIONS.GRANTS;
  },

  getGroupContents() {
    return PERMISSION_SECTIONS.GROUP;
  },

  getFamilySubsidyContents() {
    return FAMILY_SUBSIDY_PERMISSIONS;
  },

  getLabContents() {
    return LAB_PERMISSIONS;
  },

  getProviderContents() {
    return [...PERMISSION_SECTIONS.PROVIDERS, ...PERMISSION_SECTIONS.BUSINESS];
  },

  getReportingContents() {
    return PERMISSION_SECTIONS.REPORTING;
  },

  getOtherPermissionContents(pages) {
    let permissions = [...PERMISSION_SECTIONS.ADMIN];

    if (pages.Payments.enabled) permissions.push(...PERMISSION_SECTIONS.PAYMENTS);
    if (pages.Assessments.enabled) permissions.push(...PERMISSION_SECTIONS.ASSESSMENTS);
    if (pages.Surveys.enabled) permissions.push(...PERMISSION_SECTIONS.SURVEYS);

    permissions = [
      ...permissions,
      ...PERMISSION_SECTIONS.TASKS,
      ...PERMISSION_SECTIONS.QUALITY, // Note: there is a pages.Quality.enabled that appears to map to nothing
      ...PERMISSION_SECTIONS.BILLING,
    ];

    return permissions;
  },
};

<template>
  <div>
    <v-bottom-sheet
      v-model="isVisible"
      :scrim="false"
      persistent
    >
      <v-sheet
        class="text-center pa-2"
        height="60px"
      >
        <template v-if="showAttendanceLogChangeQueue">
          <span class="me-2">{{ attendanceLogMessage }}</span>
          <v-btn
            @click="emit('clear:attendance-log-changes')"
            class="underlined px-0 me-4"
            color="primary"
            variant="text"
          >
            <span>{{ t('Deselect') }}</span>
          </v-btn>
          <v-btn
            @click="emit('edit:attendance-log-status')"
            class="me-3"
            color="primary"
          >
            <span>{{ t(attendanceLogChangeCta) }}</span>
          </v-btn>
          <v-btn
            @click="emit('clear:attendance-log-status')"
            color="primary"
            variant="outlined"
          >
            <span>{{ t(attendanceLogClearCta) }}</span>
          </v-btn>
        </template>

        <template v-else-if="showOperatingLogChangeQueue">
          <span class="me-2">{{ operatingLogChangesMessage }}</span>
          <v-btn
            @click="emit('clear:operating-log-changes')"
            class="underlined px-0 me-4"
            color="primary"
            variant="text"
          >
            <span>{{ t('Deselect') }}</span>
          </v-btn>
          <v-btn
            @click="emit('edit:operating-log')"
            class="me-3"
            color="primary"
          >
            <span>{{ t('Change status') }}</span>
          </v-btn>
          <v-btn
            @click="emit('clear:operating-log-status')"
            color="primary"
            variant="outlined"
          >
            <span>{{ t('Clear status') }}</span>
          </v-btn>
        </template>

        <template v-else-if="showAttendanceLogQuestionQueue">
          <span class="me-2">{{ attendanceLogAnswersMessage }}</span>
          <v-btn
            @click="emit('clear:attendance-log-question')"
            class="underlined px-0 me-4"
            color="primary"
            variant="text"
          >
            <span>
              {{ t('Deselect') }}
            </span>
          </v-btn>
          <v-btn
            @click="emit('edit:attendance-log-question')"
            class="me-3"
            color="primary"
          >
            <span>
              {{ t('Update') }}
            </span>
          </v-btn>
        </template>
      </v-sheet>
    </v-bottom-sheet>
  </div>
</template>

<script setup>
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const props = defineProps({
  attendanceLogSchema: {
    type: Object,
    required: true,
  },
  queuedAttendanceLogChanges: {
    type: Array,
    default: null,
  },
  queuedAttendanceLogAnswers: {
    type: Array,
    default: null,
  },
  queuedOperatingLogChanges: {
    type: Array,
    default: null,
  },
  showAttendanceLogChangeQueue: {
    type: Boolean,
    default: false,
  },
  showAttendanceLogQuestionQueue: {
    type: Boolean,
    default: false,
  },
  showOperatingLogChangeQueue: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits([
  'clear:attendance-log-changes',
  'clear:attendance-log-question',
  'clear:attendance-log-status',
  'clear:operating-log-changes',
  'clear:operating-log-status',
  'edit:attendance-log-question',
  'edit:attendance-log-status',
  'edit:operating-log',
]);

import { computed } from 'vue';

const isVisible = computed(() => {
  return (
    props.showAttendanceLogChangeQueue ||
    props.showAttendanceLogQuestionQueue ||
    props.showOperatingLogChangeQueue
  );
});

const attendanceLogMessage = computed(() => {
  return `${props.queuedAttendanceLogChanges.length} ${
    props.queuedAttendanceLogChanges.length > 1 ? t('days selected') : t('day selected')
  }.`;
});

const operatingLogChangesMessage = computed(() => {
  return `${props.queuedOperatingLogChanges.length} ${
    props.queuedOperatingLogChanges.length > 1 ? t('days selected') : t('day selected')
  }.`;
});

const attendanceLogAnswersMessage = computed(() => {
  return `${props.queuedAttendanceLogAnswers.length} ${
    props.queuedAttendanceLogAnswers.length > 1 ? t('fields selected') : t('field selected')
  }.`;
});

const enableHourlyAttendance = computed(() => {
  return props.attendanceLogSchema?.meta?.enable_hourly_attendance;
});

const attendanceLogChangeCta = computed(() => {
  if (enableHourlyAttendance.value) {
    return 'Update hours';
  }

  return 'Change status';
});

const attendanceLogClearCta = computed(() => {
  if (enableHourlyAttendance.value) {
    return 'Clear hours';
  }

  return 'Clear status';
});
</script>

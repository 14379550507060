<template>
  <ActionMenu
    v-if="removable"
    @click:action:delete="handleRemove"
    @click:action:duplicate="handleDuplicate"
    @click:action:rename="handleRename"
    :items="actions()"
    button-icon="more_vert"
    left
  />

  <ResourceDialog
    @save="rename"
    ref="renameDialog"
    :fields="[{ text: 'Alias', value: 'alias' }]"
    :processing="processing"
    save-button-text="Save"
    title="Rename property"
    close-on-save
  />

  <ResourceDialog
    @save="duplicate"
    ref="duplicateDialog"
    :fields="DUPLICATE_FIELDS"
    :processing="processing"
    save-button-text="Save"
    title="Duplicate property"
  />
</template>

<script setup>
import ActionMenu from '@/shared/components/ActionMenu.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import useSchemas from '@/admin/composables/useSchemas';
import { useI18n } from 'vue-i18n';

const DUPLICATE_FIELDS = [
  { value: 'field', text: 'Field', required: true },
  { value: 'alias', text: 'Alias', required: true },
];

const { destroyProperty, duplicateProperty, updateProperty } = useSchemas();

const emit = defineEmits(['change']);

const { t } = useI18n();

const props = defineProps({
  field: {
    type: String,
    required: true,
  },
  parentField: {
    type: String,
    default: null,
  },
  property: {
    type: Object,
    required: true,
  },
  removable: {
    type: Boolean,
    default: false,
  },
  schemaId: {
    type: String,
    required: true,
  },
});

const processing = ref(false);
const duplicateDialog = ref(null);
const renameDialog = ref(null);

const actions = () => {
  return [
    { event: 'delete', title: t('Remove property'), avatar: 'delete' },
    { event: 'duplicate', title: t('Duplicate property'), avatar: 'content_copy' },
    { event: 'rename', title: t('Rename property'), avatar: 'edit' },
  ];
};

async function duplicate(newVal) {
  processing.value = true;
  const success = await duplicateProperty(
    props.schemaId,
    props.parentField,
    newVal.field,
    newVal.alias,
    props.property,
  );

  if (success) {
    emit('change');
  }
  processing.value = false;
}

async function handleDuplicate() {
  duplicateDialog.value.open({ field: props.field, alias: props.property.alias });
}

async function handleRename() {
  renameDialog.value.open({ alias: props.property.alias });
}

async function handleRemove() {
  processing.value = true;
  const success = await destroyProperty(props.schemaId, props.parentField, props.field);
  if (success) {
    emit('change');
  }
  processing.value = false;
}

async function rename(newVal) {
  processing.value = true;
  const success = await updateProperty(props.schemaId, props.parentField, props.field, {
    alias: newVal.alias,
  });
  if (success) {
    emit('change');
  }
  processing.value = false;
}
</script>

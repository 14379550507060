<template>
  <div>
    <v-container
      class="px-0 py-0 bg-super-light-blue"
      fluid
    >
      <PageTitle title="Payments" />

      <v-tabs
        v-model="tab"
        @update:model-value="tabChange"
        bg-color="surface"
        grow
      >
        <v-tab value="invoices">
          {{ $t('Invoices') }}
        </v-tab>

        <v-tab value="statements">
          {{ $t('Statements') }}
        </v-tab>
      </v-tabs>

      <v-window
        v-model="tab"
        class="border-t"
      >
        <v-window-item value="invoices">
          <BillingInvoiceIndex
            :filters="['location', 'invoiceNumber', 'invoiceDate', 'status']"
            :show-pay-buttons="false"
          />
        </v-window-item>

        <v-window-item value="statements">
          <template v-if="statement">
            <BillingStatementShow
              @statement:hide="statement = null"
              :statement="statement"
              summary-card-class="bg-white"
            />
          </template>

          <template v-else>
            <BillingStatementIndex
              @statement:show="statement = $event"
              :statements-api="Api.organization.billingStatements"
            />
          </template>
        </v-window-item>
      </v-window>
    </v-container>
  </div>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import BillingInvoiceIndex from '@/specialist/components/BillingInvoiceIndex.vue';
import BillingStatementIndex from '@/shared/components/billing/BillingStatementIndex.vue';
import BillingStatementShow from '@/shared/components/billing/BillingStatementShow.vue';
import { useRoute, useRouter } from 'vue-router';

const route = useRoute();
const router = useRouter();

const statement = ref(null);
const tab = ref('statements');

async function load() {
  if (route.query.tab === 'invoices') tabChange('invoices');
}

function tabChange(newValue) {
  tab.value = newValue;
  router.push({
    query: { tab: tab.value },
  });
}

onMounted(load);
</script>

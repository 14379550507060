<template>
  <div v-if="schema">
    <BreadcrumbTitle
      :back-name="schema.name"
      name="Stages"
    />

    <v-card
      class="mb-8"
      border
      flat
      tile
    >
      <v-card-text>
        <v-row>
          <LabeledSwitch
            v-model="schema.enable_stages"
            @input="changed = true"
            subtitle="You can customize your review process by creating stages for each step in your workflow."
            title="Enable stages"
          />
        </v-row>
      </v-card-text>
    </v-card>

    <v-divider class="my-8" />

    <NullState
      v-if="schema.stages.length == 0"
      @new="$refs.newStageDialog.open({})"
      :new-button-disabled="!schema.enable_stages"
      new-button-title="New stage"
    />

    <div
      v-for="(stage, index) in schema.stages"
      :key="index"
      class="mb-4"
    >
      <StageCard
        @change="changed = true"
        @move="reorder(index, index + $event)"
        @remove="remove(index)"
        :features="[]"
        :index="index"
        :length="schema.stages.length"
        :owners="ownerItems"
        :stage="stage"
        :teams="teams"
      />
    </div>

    <v-btn
      v-if="schema.stages.length > 0 && !changed && schema.enable_stages"
      @click="$refs.newStageDialog.open({})"
      class="mt-4"
      color="primary"
    >
      New
    </v-btn>

    <ResourceDialog
      @save="addStage"
      ref="newStageDialog"
      :fields="stageValues"
      :processing="processing"
      title="New stage"
    />

    <VerticalSpacer :min-height="24" />

    <SaveBar
      @cancel="load"
      @save="save"
      :model-value="changed"
      :processing="processing"
      flat
    />
  </div>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import StageCard from '@/specialist/components/StageCard.vue';
import { v4 as uuidv4 } from 'uuid';
import { useRoute } from 'vue-router';
import useEventBus from '@/shared/composables/useEventBus';

const eventBus = useEventBus();
const route = useRoute();

const changed = ref(true);
const newStageDialog = ref(null);
const ownerItems = ref([]);
const processing = ref(false);
const schema = ref(null);
const teams = ref([]);

const stageValues = computed(() => {
  return [
    { text: 'Title', value: 'title' },
    {
      text: 'Stage owner',
      value: 'owner_id',
      items: ownerItems.value,
      itemText: 'name',
      itemValue: 'id',
    },
  ];
});

onMounted(() => {
  processing.value = true;
  load();
});

function addStage(stage) {
  schema.value.stages.push({ ...stage, id: uuidv4() });
  newStageDialog.value.close();
  changed.value = true;
}

function load() {
  changed.value = false;
  void loadSchema();

  Api.organization.member.index({ is_specialist: true }, (resp) => {
    ownerItems.value = resp.data;
  });
  Api.organization.team.index({}, (resp) => {
    teams.value = resp.data;
  });
}

async function loadSchema() {
  processing.value = true;
  const { data } = await Api.organization.schema.get(route.params.schemaId);
  schema.value = data;
  processing.value = false;
  changed.value = false;
}

function remove(index) {
  // eslint-disable-next-line no-alert
  if (confirm('Are you sure you want to remove this stage?')) {
    schema.value.stages.splice(index, 1);
    changed.value = true;
    save();
  }
}

function reorder(fromIndex, toIndex) {
  const element = schema.value.stages[fromIndex];

  schema.value.stages.splice(fromIndex, 1);
  schema.value.stages.splice(toIndex, 0, element);
  changed.value = true;
}

function save() {
  processing.value = true;

  Api.organization.schema.update(
    route.params.schemaId,
    { enable_stages: schema.value.enable_stages, stages: schema.value.stages },
    (resp) => {
      processing.value = false;
      schema.value = resp.data;
      changed.value = false;
    },
    (err) => {
      load();
      processing.value = false;
      eventBus.chime(err.response.data.errors[0]);
    },
  );
}
</script>

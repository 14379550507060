<template>
  <div>
    <ResourceDialog
      @save="$emit('save', $event)"
      ref="editorDialog"
      :fields="named ? [{ text: 'Name', value: 'name', required: true }] : []"
      :max-width="600"
      :processing="processing"
      :save-button-text="saveButtonText"
      :title="title"
    >
      <template #append="{ localValue }">
        <v-row dense>
          <v-col
            class="labeled-input"
            cols="12"
          >
            {{ $t('Columns') }}
          </v-col>

          <v-col>
            <DataItem
              v-for="(column, index) in localValue.columns"
              @remove="localValue.columns.splice(index, 1)"
              :key="index"
              :header="column.data_type"
              :name="column.alias_name"
              block
              dense
            />

            <p
              v-if="localValue.columns.length == 0"
              v-t="'No columns have been added yet.'"
              class="fs-14"
            />
          </v-col>
        </v-row>

        <v-row dense>
          <v-col class="d-flex">
            <v-btn
              @click="$refs.addDialog.open()"
              color="primary"
              variant="flat"
            >
              <span v-t="'Add'" />
            </v-btn>
          </v-col>
        </v-row>
      </template>
    </ResourceDialog>

    <ResourceDialog
      @save="handleAdd"
      ref="addDialog"
      save-button-text="Add"
      title="Select column"
      close-on-save
    >
      <template #form="{ localValue }">
        <LabeledAutocomplete
          v-model="localValue.value"
          :item-title="(item) => `${item.data_type} - ${item.alias_name}`"
          :items="addableColumns"
          message="Column"
          clearable
          return-object
        />
      </template>
    </ResourceDialog>
  </div>
</template>

<script setup>
import DataItem from '@/shared/components/form/DataItem.vue';
import LabeledAutocomplete from '@/shared/components/form/LabeledAutocomplete.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import { v4 as uuid } from 'uuid';

defineEmits(['save']);

const props = defineProps({
  allowedProperties: {
    type: Array,
    default: () => [],
  },
  named: {
    type: Boolean,
    default: false,
  },
  saveButtonText: {
    type: String,
    default: 'Save',
  },
  title: {
    type: String,
    default: 'Edit custom view',
  },
  processing: Boolean,
});

const addDialog = ref(null);
const editorDialog = ref(null);

const addableColumns = computed(() => {
  const localValue = editorDialog.value.getLocalValue();
  const existingColumns = localValue.columns;

  if (Object.keys(existingColumns).length === 0) {
    return props.allowedProperties;
  }

  return props.allowedProperties.filter(
    (prop) =>
      existingColumns.findIndex(
        (col) =>
          `${prop.data_type}_${prop.property_name}` === `${col.data_type}_${col.property_name}`,
      ) === -1,
  );
});

function close() {
  editorDialog.value.close();
}

function handleAdd(newVal) {
  const localValue = editorDialog.value.getLocalValue();
  const existingColumns = localValue.columns;
  const key = uuid().replaceAll('-', '');
  const newColumn = { ...newVal.value, key };
  editorDialog.value.setLocalValue({
    ...localValue,
    columns: existingColumns.concat([newColumn]),
  });
}

function open(existingValues) {
  editorDialog.value.open(existingValues);
}

defineExpose({ open, close });
</script>

<template>
  <v-container
    v-if="contentTemplates"
    class="mxw-1200 pa-6"
  >
    <TitleBar
      @create="$refs.createDialog.open()"
      create-text="New content template"
      name="Content templates"
      createable
    />

    <NullState
      v-if="contentTemplates.length == 0"
      @new="$refs.createDialog.open()"
      new-button-title="New content template"
      title="You do not have any content templates."
    />

    <v-row
      v-for="(ct, index) in contentTemplates"
      :key="index"
    >
      <v-col>
        <v-card
          border
          flat
          tile
        >
          <v-card-text>
            <v-row
              @click="handleNavigationToLocalizedIndex(ct)"
              class="d-flex align-center"
            >
              <v-col>
                <span v-text="ct.attributes.name" />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <ResourceDialog
      @save="create"
      ref="createDialog"
      :fields="contentTemplateFields"
      title="New content template"
    />
  </v-container>
</template>

<script setup>
import Api from '@/specialist/services/bright_finder';
import NullState from '@/shared/components/NullState.vue';
import ResourceDialog from '@/shared/components/form/ResourceDialog.vue';
import TitleBar from '@/shared/components/TitleBar.vue';
import { useRouter } from 'vue-router';

const router = useRouter();

const contentTemplates = ref();
const contentTemplateFields = ref([
  { text: 'Name', value: 'name' },
  {
    text: 'Communication type',
    value: 'communication_type',
    items: ['email', 'text'],
    required: true,
  },
]);

// refs
const createDialog = ref(null);

onMounted(load);

function handleNavigationToLocalizedIndex(contentTemplate) {
  router.push({
    name: 'LocalizedContentTemplateIndex',
    params: { contentTemplateId: contentTemplate.id },
  });
}

async function create(attributes) {
  try {
    const res = await Api.organization.content_template.create(attributes);
    const { data } = res.data;

    contentTemplates.value.push(data);
  } finally {
    createDialog.value.close();
  }
}

async function load() {
  const res = await Api.organization.content_template.index();
  const { data } = res.data;

  contentTemplates.value = data || [];
}
</script>
